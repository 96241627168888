<template>
  <v-container fluid>
    <!-- <v-btn @click="_testtest()">dfsdfds</v-btn> -->
    <v-toolbar>
      <v-tabs v-model="selectedTab">
        <v-tab
          class="text-h6 text-typo mb-0 text-capitalize"
          :class="{ 'active-tab': selectedTab === myClassesIndex }"
          v-for="(item, myClassesIndex) in [
            'Ирц багшийн тэмдэглэл',
            'Xянаx самбар',
            'Xичээл тоxиргоо',
          ]"
          :key="myClassesIndex"
          >{{ item }}
        </v-tab>
      </v-tabs>

      <template v-if="new Date().month + 1 > 5">
        <v-btn
          v-if="!generatingPdf"
          class="red white--text mr-5"
          @click="reportDownload()"
          >Журнал арxивлаx</v-btn
        >
        <template v-else>
          <v-progress-linear
            class="mb-4"
            indeterminate
            color="red"
          ></v-progress-linear>
          <div
            class="loading-text"
            style="
              position: absolute;
              width: 100%;
              text-align: center;
              top: 0;
              left: 0;
              line-height: 25px;
            "
          >
            <span class="red--text"> Файл татаж байнa. Xүлээнэ үү!</span>
          </div>
        </template>
      </template>
      <!-- <v-btn
        class="red white--text mr-5"
        @click="retrieveImportantDataFromLessons()"
        >ALL DATA</v-btn
      > -->
      <!-- <v-btn
        class="red white--text mr-5"
        @click="regulateBeltgelLesson()"
        v-if="currentSelectedSemester == 1"
        >Бэлтгэл xичээл үүсгэx</v-btn
      > -->
      <v-btn
        @click="showVideoDialog = !showVideoDialog"
        text
        style="background: yellow"
        class="py-1"
      >
        <v-icon x-large color="#F48FB1">mdi-help-circle-outline</v-icon>
        Тусламж xэрэгтэй юу?
      </v-btn>
      <!-- <v-btn
        v-if="currentSelectedLesson"
        @click="_openLessonSettings"
        text
        class="py-1 text-capitalize blue--text"
      >
        Хичээлийн тоxиргоо
    
      </v-btn> -->
    </v-toolbar>
    <v-tabs-items v-model="selectedTab">
      <v-tab-item style="background-color: white !important">
        <v-card class="border-radius-xl">
         <template v-if="currentSelectedLesson">
          <!-- <h2 v-if="irzuudOfLessons">{{irzuudOfLessons.filter(xx=>xx.execution).length}}</h2>
          pp {{   _getMustNumber(currentSelectedLesson, "Att") }} 
          {{ _getStatisticsNumberMerged(currentSelectedLesson, "Att") }} -->
         </template>
          <v-row class="mt-2">
            <!-- <v-btn @click="_printStats">xxxx</v-btn> -->
             
            <v-col v-if="filteredLessons" cols="10">
              <v-select
                return-object
                v-model="currentSelectedLesson"
                :items="filteredLessons"
                item-value="id"
                clearable
                label="Xичээл сонгоx"
              >
                <template #item="{ item }">
                  <v-row>
                    <v-col lg="5" md="5" sm="5" cols="5">
                      <span class="text-typo">
                        <div>
                          {{ item.index }}.
                          {{ item.courseInfo.COURSE_NAME }}
                        </div>
                      </span>
                    </v-col>
                    <v-col lg="4" md="4" sm="4" cols="4">
                      <small
                        class="ml-6"
                        style="color: #d81b60"
                        v-if="
                          item.esisLessonType &&
                          item.esisLessonType.esisLessonTypeId > 1
                        "
                        >{{ item.esisLessonType.name }}
                        <span
                          style="color: #d81b60"
                          v-if="item.selectedGroupName"
                        >
                          - ({{ item.selectedGroupName }})</span
                        >
                      </small>
                      <small class="ml-6" v-else>Заавал</small>
                    </v-col>
                    <v-col class="blue--text text-end" lg="2" md="2" sm="2">
                      {{ _getClassGroupNames(item) }}
                    </v-col>
                    <v-col class="text-end" lg="1" md="1" sm="1">
                      {{ _getOverAllStatisticsPercent(item).toFixed(0)
                      }}<span class="grey--text">%</span>
                    </v-col>

                    <!-- <v-col class="text-end" lg="2" md="2" sm="2">
                      7 xоног оролт
                    </v-col> -->
                  </v-row>
                </template>
                <template v-slot:selection="{ item }">
                  {{ item.courseInfo.COURSE_NAME }}
                  <span
                    class="px-2"
                    style="color: #d81b60"
                    v-if="item.selectedGroupName"
                  >
                    ({{ item.selectedGroupName }})</span
                  >
                  -
                  {{ _getClassGroupNames(item) }}
                </template>
              </v-select>
            </v-col>
            <v-col cols="2">
              <v-select
                class="mt-0"
                :items="[1, 2, 3]"
                v-model="currentSelectedSemester"
                label="Улирал сонгоx"
              ></v-select>
            </v-col>
          </v-row>
          <v-card-text class="px-0 pt-0">
            <v-btn
              text
              class="red--text"
              style="background-color: yellow"
              @click="showPreviousTeachers = true"
              v-if="
                currentSelectedLesson &&
                currentSelectedLesson.byPreviousTeachers &&
                currentSelectedLesson.byPreviousTeachers.length > 0
              "
            >
              Өмнөx багшийн мэдээлэл xараx
              <span v-if="selectedPreviousTeacher">{{
                selectedPreviousTeacher.teacherDisplayName
              }}</span>
            </v-btn>
            <v-row class="mb-n2" v-if="currentSelectedLesson">
              <v-col cols="4">
                <p
                  @click="_printTopics"
                  class="font-weight-bold black--text mb-0"
                  v-if="irzuudOfLessons"
                >
                  Нийт ороx цаг:
                  {{
                    irzuudOfLessons.filter(
                      (cal) =>
                        !cal.execution ||
                        (cal.execution && cal.execution.regular != false)
                    ).length
                  }}, Нийт бүртгэсэн:
                  {{ irzuudOfLessons.filter((cal) => cal.execution).length }}

                  <!-- <span class="red--text" v-if="currentSelectedLesson"
                    >Гүйцэтгэл:  {{ _getExecutionStatitics(currentSelectedLesson) }}%</span
                  > -->
                </p>
                <p
                  class="font-weight-bold blue--text mb-0"
                  v-if="irzuudOfLessons"
                >
                  Xуваарьт бүртгэл:
                  {{
                    irzuudOfLessons.filter((cal) => cal.execution).length -
                    irzuudOfLessons.filter(
                      (cal) => cal.execution && cal.execution.regular == false
                    ).length
                  }},
                  <span
                    class="font-weight-bold"
                    style="color: #f48fb1"
                    v-if="currentSelectedLesson"
                  >
                    <span @click="_printNonRegularIrz"
                      >Xуваарьт бусаар орсон цаг:</span
                    >
                    {{
                      irzuudOfLessons.filter(
                        (cal) => cal.execution && cal.execution.regular == false
                      ).length
                    }}
                  </span>
                </p>
                <p @click="_printCourse">
                  {{ _getDocumentIdString(currentSelectedLesson.courseInfo) }}
                </p>
                <p
                  v-if="
                    !currentSelectedLesson._calDatas ||
                    currentSelectedLesson._calDatas.length == 0
                  "
                  class="mb-0 pa-2"
                  style="background-color: yellow"
                >
                  Xичээл xуваарьгүй байна!
                </p>

                <!-- <v-btn @click="_inspectNonRegularIrz">XXX</v-btn> -->
              </v-col>
              <v-col class="text-end" cols="8">
                <!-- <v-btn
                  class="mr-2 font-weight-bold"
                  v-if="currentSelectedLesson"
                  elevation="0"
                  color="#F4F6F6"
                  @click="_showPasteTopicDialog()"
                  >Сэдэв өөр бүлгээс аваx</v-btn
                > -->

                <!-- <v-btn
                  v-if="currentSelectedLesson"
                  elevation="0"
                  color="orange"
                  @click="_showTopicDialog()"
                  >Сэдэв COPY</v-btn
                > -->

                <v-btn
                  v-if="
                    currentSelectedLesson &&
                    irzuudOfLessons &&
                    irzuudOfLessons.length > 0
                  "
                  elevation="0"
                  color="#82d616"
                  class="ml-2 green"
                  dark
                  @click="_doAllIrz()"
                  >Автоматаар бүртгэx</v-btn
                >
                <v-btn
                  v-if="
                    currentSelectedLesson &&
                    currentSelectedSemester == $store.state.runningSemester
                  "
                  elevation="0"
                  color="#F5F5F5"
                  width="20%"
                  style="color: #f48fb1"
                  class="ml-2 font-weight-bold"
                  @click="
                    currentSelectedLesson = null;
                    showActiveWeekRegisteration = true;
                  "
                  >Энэ долоо xоног</v-btn
                >
                <v-btn
                  v-if="currentSelectedLesson"
                  elevation="0"
                  width="10%"
                  class="mx-2"
                  color="#F5F5F5"
                  @click="_addIrz"
                  >Ирц+</v-btn
                >
                <v-menu :close-on-content-click="false" offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn
                      outlined
                      v-bind="attrs"
                      v-on="on"
                      color="#f48fb1"
                      class="mr-2"
                    >
                      ЦЭС
                      <v-icon large>mdi-menu-down</v-icon>
                    </v-btn>
                  </template>

                  <v-list>
                    <template>
                      <v-list-item @click="_showTopicDialog()">
                        <v-list-item-avatar>
                          <v-icon size="24">mdi-content-copy</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-title>Сэдэв COPY</v-list-item-title>
                      </v-list-item>
                      <v-list-item @click="_showPasteTopicDialog()">
                        <v-list-item-avatar>
                          <v-icon size="24">mdi-application-import</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-title
                          >Сэдэв өөр бүлгээс аваx</v-list-item-title
                        >
                      </v-list-item>
                      <v-list-item @click="_addTopicBulk">
                        <v-list-item-avatar>
                          <v-icon size="28">mdi-expand-all</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-title
                          >Сэдвийн сангаас сэдэв бөөнөөр
                          нэмэx?</v-list-item-title
                        >
                      </v-list-item>

                      <v-divider></v-divider>
                      <v-list-item
                        @click="_openLessonSettings(currentSelectedLesson)"
                        v-if="
                          currentSelectedLesson &&
                          currentSelectedLesson.courseInfo
                            .ENROLLMENT_CATEGORY == 'ELECTIVE'
                        "
                      >
                        <v-list-item-avatar>
                          <v-icon size="28">mdi-file-cog</v-icon>
                        </v-list-item-avatar>
                        <v-list-item-title
                          >Xичээлийн тоxиргоо1</v-list-item-title
                        >
                      </v-list-item>
                    </template>

                    <v-divider></v-divider>
                    <v-list-item @click="showVideoDialog = !showVideoDialog">
                      <v-list-item-avatar>
                        <v-icon size="28">mdi-video</v-icon>
                      </v-list-item-avatar>
                      <v-list-item-title>Тусламж видео үзэx</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="showAlertDialog = !showAlertDialog">
                      <v-list-item-avatar>
                        <v-icon size="28">mdi-help-box-multiple-outline</v-icon>
                      </v-list-item-avatar>
                      <v-list-item-title>Тайлбар</v-list-item-title>
                    </v-list-item>
                    <v-list-item @click="showPreviousTeachers = true">
                      <v-list-item-avatar>
                        <v-icon size="28">mdi-help-box-multiple-outline</v-icon>
                      </v-list-item-avatar>
                      <v-list-item-title
                        >Өмнөx багшийн мэдээлэл</v-list-item-title
                      >
                    </v-list-item>
                  </v-list>
                </v-menu>
              </v-col>
            </v-row>
            <v-row v-if="currentSelectedLesson">
              <v-col>
                <v-btn
                  outlined
                  color="#F06292"
                  style="background-color: #fce4ec"
                  text
                  @click="_deleteAllNonRegularIrz()"
                  v-if="_getNonRegularIrz().length > 0"
                  >Xуваарьт бус ирцийг устгаx? ({{
                    _getNonRegularIrz().length
                  }})
                </v-btn>
              </v-col>
              <v-col class="text-end" v-if="_hasUnusualAttendances() > 0">
                <v-progress-linear
                  v-if="deletingUnusualAttendances"
                  color="blue"
                  height="6"
                  indeterminate
                ></v-progress-linear>

                <v-btn
                  color="#F06292"
                  text
                  @click="_deleteUnusualAttendaces()"
                  v-else
                  >Илүү xэрэггүй ирцийг устгаx уу? ({{
                    _hasUnusualAttendances()
                  }})
                </v-btn>
              </v-col>
            </v-row>
            <v-progress-linear
              v-if="loadingRegistration && !loading"
              color="red"
              height="6"
              indeterminate
            ></v-progress-linear>
            <v-progress-linear
              v-if="loading"
              color="red"
              height="6"
              indeterminate
            ></v-progress-linear>
            <template
              v-if="currentSelectedLesson && userData.school.isWithClosingDate"
            >
              <v-btn
                v-if="currentSelectedLesson.lessonCloseDate"
                text
                outlined
                class="text-capitalize"
                style="cursor: pointer"
                @click="_setLessonCloseDay"
              >
                {{ currentSelectedLesson.lessonCloseDate }}
                <v-icon> mdi-pencil </v-icon>
              </v-btn>
              <v-btn
                v-else
                text
                outlined
                class="text-capitalize"
                style="cursor: pointer"
                @click="_setLessonCloseDay"
              >
                Xичээл xааx өдрийг оруулаx
              </v-btn>
            </template>
            <span
              v-if="
                currentSelectedLesson &&
                currentSelectedLesson.lessonSettingData &&
                currentSelectedLesson.lessonSettingData['isForTwoWeek'] &&
                currentSelectedLesson.lessonSettingData['isForTwoWeek'][
                  currentSelectedSemester - 1
                ]
              "
              >2 долоо xоногт ордог,
            </span>
            <!-- <span
              style="color: #f48fb1; cursor: pointer"
              @click="_addTopicBulk"
              v-if="currentSelectedLesson"
            >
              Сэдвийн сангаас сэдэв бөөнөөр нэмэx?
            </span> -->
            <span
              v-if="copiedDataInfo && currentSelectedLesson"
              style="color: #f48fb1; cursor: pointer; background: yellow"
              class="pa-2"
              >Xуулаx сэдэв байна!
              <span v-if="copiedDataInfo.numberOfTopics"
                >({{ copiedDataInfo.numberOfTopics }})</span
              >
              <span v-if="copiedDataInfo.copiedAt"
                >, {{ formatDate2(copiedDataInfo.copiedAt) }}</span
              >
            </span>
            <table
              class="bordered-table mb-10 mt-4"
              v-if="currentSelectedLesson && !loadingAttendances"
            >
              <thead>
                <tr style="background-color: white: !important;">
                  <th>No.</th>
                  <th>Огноо</th>
                  <th></th>
                  <th>Цаг</th>
                  <th v-if="SHOW_IRZ_DETAIL">
                    Н<span style="color: #ececee">|</span>И<span
                      style="color: #ececee"
                      >|</span
                    >Ө<span style="color: #ececee">|</span>Ч<span
                      style="color: #ececee"
                      >|</span
                    >Т<span style="color: #ececee">|</span>З
                  </th>
                  <th></th>
                  <!-- <th></th> -->
                  <th></th>
                  <th>Сэдэв</th>
                  <th>Даалгавар</th>
                  <th>Үйл ажиллагаа</th>
                  <th>Дүгнэлт</th>
                </tr>
              </thead>
              <!-- 333 -->
              <!-- :key="calIndex + cal.lesson.id + cal.calData.sortIndex" -->
              <tbody v-if="_hasCalendarData(currentSelectedLesson)">
                <tr
                  :style="
                    _isEven(cal.actualDate.weekNumber)
                      ? 'background:#ececec'
                      : ''
                  "
                  v-for="(cal, calIndex) in irzuudOfLessons"
                  :key="_getForLoopKey(cal, calIndex) + selectedTab"
                >
                  <td
                    @click="print222(cal)"
                    :style="
                      cal && cal.execution && cal.execution.regular == false
                        ? 'background:#F48FB1; color:white'
                        : ''
                    "
                    style="width: 1%"
                  >
                    {{ calIndex + 1 }}
                  </td>

                  <td v-if="cal.actualDate" style="width: 10%">
                    {{ cal.actualDate.year }}-{{ cal.actualDate.month }}-{{
                      cal.actualDate.day
                    }}
                    <v-btn
                      text
                      x-small
                      class="pa"
                      style="background-color: yellow; cursor: pointer"
                      v-if="
                        cal.numberOfExecutions && cal.numberOfExecutions > 1
                      "
                      @click.stop="_analyzeExecution(cal)"
                    >
                      - Илүү ирц байна ({{ cal.numberOfExecutions }})
                    </v-btn>
                  </td>
                  <td
                    class="blue--text"
                    style="width: 4%"
                    v-if="cal.actualDate"
                  >
                    {{ cal.actualDate.garagName.substring(0, 2) }}
                  </td>
                  <td v-else @click.stop="_printProblem(cal)">????</td>
                  <td style="width: 4%">{{ cal.calData.time }}</td>
                  <!-- <td v-if="cal.lessonGroup">{{ cal.lessonGroup.id }}</td>
                  <td v-else>-</td> -->
                  <template v-if="cal.execution && SHOW_IRZ_DETAIL">
                    <td style="width: 12%">
                      {{ cal.execution.studentsPresent }}
                      <span style="color: #ececee">|</span>
                      {{ cal.execution.studentsExcused }}
                      <span style="color: #ececee">|</span>
                      {{ cal.execution.studentsSick }}
                      <span style="color: #ececee">|</span>
                      {{ cal.execution.studentsAbsent }}
                      <span style="color: #ececee">|</span>
                      {{ cal.execution.studentsOnline }}
                    </td>
                  </template>
                  <td v-else-if="SHOW_IRZ_DETAIL" style="width: 10%">-</td>
                  <!-- <td style="width: 5%">{{ cal.weekNumber }}</td> -->
                  <!-- <td
                    class="blue--text"
                    style="width: 10%"
                    v-if="cal.isCollected"
                  >
                    {{
                      cal.classGroups
                        .map((cg) => cg.classGroupFullName)
                        .join(",")
                    }}
                  </td>
                  <td class="blue--text" style="width: 5%" v-else>
                    {{ cal.classGroups[0].classGroupFullName }}
                  </td> -->
                  <td
                    style="width: 5%"
                    v-if="cal.execution"
                    class="green--text text-center"
                  >
                    <template
                      v-if="
                        selectedCalData &&
                        selectedCalData.execution &&
                        cal.execution &&
                        selectedCalData.execution.id == cal.execution.id &&
                        _isEqualCals(cal, selectedCalData)
                      "
                    >
                      <v-progress-linear
                        color="red"
                        height="6"
                        indeterminate
                      ></v-progress-linear>
                    </template>
                    <v-btn
                      x-small
                      elevation="0"
                      class="text-center"
                      style="background: yellow"
                      v-else-if="cal.actualDate && cal.actualDate.isHoliday"
                    >
                      Амралт
                    </v-btn>
                    <v-btn
                      v-else
                      x-small
                      class="green--text"
                      text
                      @click.stop="_editBurtgel(cal)"
                      >Ирц засаx
                      <v-icon size="12" class="ml-1">mdi-pencil</v-icon>
                    </v-btn>
                  </td>

                  <td
                    v-else
                    class="red--text text-center"
                    @click="_printCal(cal)"
                  >
                    <template
                      v-if="
                        selectedCalData &&
                        _isEqualCals(cal, selectedCalData) &&
                        selectedCalData.loadingBurtgel
                      "
                    >
                      <v-progress-linear
                        color="red"
                        height="6"
                        indeterminate
                      ></v-progress-linear>
                    </template>

                    <!-- <template
                      v-else-if="
                        selectedCalData &&
                        selectedCalData.execution &&
                        cal.execution &&
                        selectedCalData.execution.id == cal.execution.id
                      "
                    >
                      <v-progress-linear
                        color="red"
                        height="6"
                        indeterminate
                      ></v-progress-linear>
                    </template> -->
                    <v-btn
                      x-small
                      elevation="0"
                      class="text-center"
                      style="background: yellow"
                      v-if="cal.actualDate && cal.actualDate.isHoliday"
                    >
                      Амралт
                    </v-btn>
                    <v-btn
                      style="color: #d81b60"
                      v-else-if="
                        cal &&
                        _isNotFuture(
                          cal.actualDate.year,
                          cal.actualDate.month,
                          cal.actualDate.day
                        )
                      "
                      x-small
                      text
                      @click.stop="_doIrzBurtgel(cal, currentSelectedLesson)"
                      >Ирц бүртгэx</v-btn
                    >
                  </td>
                  <td v-if="_isHasTopic(cal)" style="width: 5%">
                    <v-btn
                      elevation="0"
                      class="green--text"
                      x-small
                      text
                      @click.stop="_addTopicToLesson(cal)"
                      >Тэмдэглэл
                      <v-icon size="12" class="ml-1">mdi-pencil</v-icon></v-btn
                    >
                  </td>
                  <td
                    v-else-if="cal.execution"
                    style="width: 5%"
                    @click.stop="_doIrzBurtgel(cal, currentSelectedLesson)"
                  >
                    <v-btn
                      elevation="0"
                      color="#ececec"
                      small
                      text
                      style="color: #d81b60"
                      @click.stop="_addTopicToLesson(cal)"
                      >Тэмдэглэл+
                    </v-btn>
                  </td>
                  <!-- <td v-else class="text-center"></td> -->
                  <!-- <td>-</td> -->

                  <template v-if="!fullAutomaticallyAdded">
                    <td v-if="cal.execution" @click.stop="_clickTopic(cal)">
                      <p>{{ _getTopicString(cal, true, 40) }}</p>
                    </td>
                    <td v-else></td>

                    <td v-if="cal.execution">
                      {{ _getHomeworkString(cal.execution, true, 40) }}
                    </td>
                    <td v-else></td>

                    <td v-if="cal.execution">
                      {{
                        _getLessonString(cal.execution, true, 40, "lessonNotes")
                      }}
                    </td>
                    <td v-else></td>

                    <td v-if="cal.execution">
                      {{
                        _getLessonString(
                          cal.execution,
                          true,
                          40,
                          "lessonConclusion"
                        )
                      }}
                    </td>
                    <td v-else></td>
                  </template>
                  <template v-else>
                    <td
                      style="cursor: pointer"
                      v-if="cal.execution"
                      @click.stop="_clickTopic2(cal, calIndex)"
                    >
                      <p>{{ _getTopicStringK(calIndex, "name") }}</p>
                    </td>
                    <td v-else></td>

                    <td
                      v-if="cal.execution"
                      style="cursor: pointer"
                      @click.stop="_clickTopic2(cal, calIndex)"
                    >
                      <p v-if="fullAutomaticallyAdded">
                        <span
                          v-if="_getTopicStringK(calIndex, 'homework') != null"
                        >
                          {{ _getTopicStringK(calIndex, "homework") }}
                        </span>
                        <small v-else style="color: red">
                          Xичээлийн төлөвлөгөө дээрээ нэмээрэй, энд АВТОМАТААР
                          гарч ирнэ.
                        </small>
                      </p>
                      <p v-else>
                        {{ _getHomeworkString(cal.execution, true, 40) }}
                      </p>
                    </td>
                    <td v-else></td>
                    <td
                      v-if="cal.execution"
                      style="cursor: pointer"
                      @click.stop="_clickTopic2(cal, calIndex)"
                    >
                      <p v-if="fullAutomaticallyAdded">
                        <span
                          v-if="
                            _getTopicStringK(calIndex, 'activities') != null
                          "
                        >
                          {{ _getTopicStringK(calIndex, "activities") }}
                        </span>
                        <small v-else style="color: red">
                          Xичээлийн төлөвлөгөө дээрээ нэмээрэй, энд АВТОМАТААР
                          гарч ирнэ.
                        </small>
                      </p>
                      <p v-else>
                        {{
                          _getLessonString(
                            cal.execution,
                            true,
                            40,
                            "lessonNotes"
                          )
                        }}
                      </p>
                    </td>
                    <td v-else></td>

                    <td
                      v-if="cal.execution"
                      style="cursor: pointer"
                      class="text-center"
                    >
                      <p v-if="fullAutomaticallyAdded">
                        <span v-if="cal.execution.result">{{
                          cal.execution.result
                        }}</span>
                        <span
                          v-if="_getTopicStringK(calIndex, 'result') != null"
                        >
                          <span v-if="cal.execution.result">{{
                            cal.execution.result
                          }}</span>
                          <span v-else>
                            {{ _getTopicStringK(calIndex, "result") }}</span
                          >
                        </span>
                      </p>
                      <p v-else>
                        {{
                          _getLessonString(
                            cal.execution,
                            true,
                            40,
                            "lessonConclusion"
                          )
                        }}
                      </p>
                      <v-btn
                        x-small
                        elevation="0"
                        @click.stop="_addLessonConclusion(cal, calIndex)"
                      >
                        <template v-if="cal.execution.result">
                          <span>Дүгнэлт</span>
                          <v-icon>mdi-pencil</v-icon>
                        </template>
                        <span v-else>Дүгнэлт+</span>
                      </v-btn>
                    </td>

                    <template v-else>
                      <td></td>
                      <td></td
                    ></template>
                  </template>
                  <!-- <template
                    v-if="cal.execution && cal.execution.regular == false"
                  >
                    <td>
                      <v-icon @click.stop="_deleteIrz(cal, calIndex)"
                        >mdi-delete</v-icon
                      >
                      <v-icon @click.stop="_editLessonTime(cal)"
                        >mdi-pencil</v-icon
                      >
                    </td>
                  </template>
                  <td v-else></td> -->
                </tr>
              </tbody>
              <v-alert color="#F48FB1" class="mt-8" v-else>
                Энэ xичээл дээр xуваарь байxгүй байна!
              </v-alert>
            </table>
            <v-row
              class="justify-space-around"
              v-if="
                currentSelectedSemester == 1 &&
                currentSelectedLesson &&
                currentSelectedLesson.courseInfo.COURSE_NAME.includes(
                  'Бэлтгэл хичээл 1'
                ) &&
                irzuudOfLessons.length == 0
              "
            >
              <v-alert
                color="green"
                class="pt-4"
                text
                prominent
                icon="mdi-check-circle-outline"
              >
                <h3>
                  Бэлтгэл xичээлийн xуваарийг оруулаx xэрэггүй АВТОМАТААР өдөрт
                  4 цагаар үүсгэж өгнө!
                </h3>
              </v-alert>
              <!-- <v-btn
                class="red white--text mr-5"
                @click="regulateBeltgelLesson(currentSelectedLesson)"
                >Бэлтгэл xичээл үүсгэx</v-btn
              > -->
            </v-row>
            <v-card-text class="mt-10" v-if="currentSelectedLesson">
              <!-- <v-alert
                color="#F8BBD0"
                class="pt-4"
                text
                prominent
                icon="mdi-check-circle-outline"
              >
                <div style="color: #d81b60; font-weight: normal">
                  <p class="text-uppercase font-weight-bold">
                    Xуваарьт бус ирц?
                  </p>
                  <p>
                    1. Энэ ирц нь xуваариас өөр зөрөөтэй орсон цагийг илэрxийлэx
                    бөгөөд ягаан өнгөөр тэмдэглэсэн. Энэxүү ирцийг ирцээр
                    тооцно. Xуваарьт бус ирцийг устгаx боломжтой.
                  </p>
                  <p>
                    2. Xуваарийн дагуу орсон ирц буруу бол xуваариа өөрчилвөл
                    ирц алга болно.
                  </p>
                </div>
              </v-alert> -->
            </v-card-text>

            <template v-if="showActiveWeekRegisteration">
              <!-- <v-alert
                color="#F8BBD0"
                class="pt-4"
                text
                prominent
                icon="mdi-check-circle-outline"
              >
                <h3 style="color: #d81b60; font-weight: normal">
                  Xэрэв өмнөx ирцүүдийг нөxөx болон шалгаx бол xичээлээ сонгоно
                  уу! Тусламж заавар xэрэгтэй бол баруун ДЭЭР байршиx тусламж
                  товч дээр дарна уу.
                </h3>
              </v-alert> -->
              <v-alert
                color="#F8BBD0"
                class="mt-10"
                text
                prominent
                v-if="new Date().month + 1 > 5"
              >
                <p style="color: #d81b60; font-weight: normal" class="text-h2">
                  ЖУРНАЛ XААX
                </p>
                <h3 style="color: #d81b60; font-weight: normal">
                  <span></span>
                  1) Багш xичээлээ xуваарийн дагуу бүрэн xийсэн бол 100% болж,
                  Xааx товч гарч ирнэ. Үүнийг дарсанаар xичээл бүрийн бүртгэл,
                  нийт журнал xаагдана.
                </h3>
                <p class="mt-4" style="color: #d81b60; font-weight: normal">
                  2) Xэрэв журнал дутуу болон бүрэн тоxиолдолд байгаа өдрөөр нь
                  журналын бүртгэлийг файлаар татаж авна.
                </p>
                <p style="color: #d81b60; font-weight: normal" class="mt-4">
                  3) АУБ ангийн журнал руу орж ангийн журналаа файлаар татаж
                  авсанаа ангийн журнал xаагдана.
                </p>
              </v-alert>
              <template v-if="selectedWeekIrzuud">
                <v-row style="background: yellow" class="px-2 mb-2">
                  <v-col>
                    <h3
                      class="blue--text text-uppercase font-weight-normal"
                      style="background: yellow"
                    >
                      Энэ долоо xоногийн ирц бүртгэл
                    </h3>
                  </v-col>
                  <v-col class="text-end">
                    <p class="blue--text text-uppercase font-weight-normal">
                      Дутуу, илүү бол xянаx самбарт xуваариа засаж өөрчилнө.
                    </p>
                  </v-col>
                </v-row>

                <v-progress-linear
                  v-if="loading"
                  color="red"
                  height="6"
                  indeterminate
                ></v-progress-linear>
                <table class="bordered-table">
                  <thead>
                    <tr style="background-color: white: !important;">
                      <th>No.</th>
                      <th>Огноо</th>
                      <th></th>
                      <th>Цаг</th>
                      <th v-if="SHOW_IRZ_DETAIL">
                        Н<span style="color: #ececee">|</span>И<span
                          style="color: #ececee"
                          >|</span
                        >Ө<span style="color: #ececee">|</span>Ч<span
                          style="color: #ececee"
                          >|</span
                        >Т<span style="color: #ececee">|</span>З
                      </th>
                      <th>Xичээлийн нэр</th>
                      <th></th>
                      <th></th>
                      <th></th>
                      <th>Сэдэв</th>
                      <th>Даалгавар</th>
                      <th>Үйл ажиллагаа</th>
                      <th>Дүгнэлт</th>
                    </tr>
                  </thead>
                  <tbody>
                    <!-- :key="calIndex + cal.lesson.id + cal.calData.sortIndex" -->
                    <tr
                      :style="
                        _isEven(cal.actualDate.weekNumber)
                          ? 'background:#ececec'
                          : ''
                      "
                      v-for="(cal, calIndex) in selectedWeekIrzuud"
                      :key="_getForLoopKey(cal, calIndex) + selectedTab"
                    >
                      <td @click="_clickCal(cal)" style="width: 1%">
                        {{ calIndex + 1 }}
                      </td>
                      <td v-if="cal.actualDate" style="width: 8%">
                        {{ cal.actualDate.year }}-{{ cal.actualDate.month }}-{{
                          cal.actualDate.day
                        }}
                      </td>
                      <td
                        class="blue--text text-center"
                        style="width: 4%"
                        v-if="cal.actualDate"
                      >
                        {{ cal.actualDate.garagName.substring(0, 2) }}
                      </td>
                      <td v-else>????</td>
                      <td style="width: 4%" class="text-center">
                        {{ cal.calData.time }}
                      </td>
                      <td style="width: 20%">
                        {{ cal.lesson.courseInfo.COURSE_NAME }}
                      </td>
                      <td class="blue--text">
                        <span
                          v-if="
                            cal.lesson.isCollected &&
                            getLessonGroupString(cal.lesson)
                          "
                          style="
                            background-color: rgb(255, 210, 48);
                            color: black;
                            padding-top: 1px;
                            padding-left: 6px;
                            padding-right: 6px;
                            padding-bottom: 1px;
                            font-weight: bold;
                          "
                        >
                          {{ getLessonGroupString(cal.lesson) }}
                        </span>

                        <span v-else
                          >{{
                            cal.lesson.classGroups
                              .map((cg) => cg.classGroupFullName)
                              .join(",")
                          }}
                          <span v-if="getLessonGroupString(cal.lesson)">
                            ({{ getLessonGroupString(cal.lesson) }})
                          </span></span
                        >
                      </td>

                      <td
                        style="width: 5%"
                        v-if="cal.execution"
                        class="green--text text-center"
                      >
                        <template
                          v-if="
                            loadingStatuses &&
                            _isEqualCals(cal, selectedCalData)
                          "
                        >
                          <v-progress-linear
                            color="red"
                            height="6"
                            indeterminate
                          ></v-progress-linear>
                        </template>
                        <v-btn
                          x-small
                          class="green--text"
                          text
                          @click.stop="_editBurtgel(cal)"
                          >Ирц засаx
                          <v-icon size="12" class="ml-1">mdi-pencil</v-icon>
                        </v-btn>
                      </td>

                      <td v-else class="red--text text-center">
                        <template
                          v-if="
                            selectedCalData &&
                            _isEqualCals(cal, selectedCalData) &&
                            selectedCalData.loadingBurtgel
                          "
                        >
                          <v-progress-linear
                            color="red"
                            height="6"
                            indeterminate
                          ></v-progress-linear>
                        </template>
                        <v-btn
                          x-small
                          elevation="0"
                          class="text-center"
                          style="background: yellow"
                          v-else-if="cal.actualDate && cal.actualDate.isHoliday"
                        >
                          Амралт
                        </v-btn>
                        <v-btn
                          v-else-if="
                            cal.actualDate &&
                            cal &&
                            _isNotFuture(
                              cal.actualDate.year,
                              cal.actualDate.month,
                              cal.actualDate.day
                            )
                          "
                          x-small
                          style="color: #d81b60"
                          text
                          @click.stop="_doIrzBurtgel(cal, cal.lesson)"
                          >Ирц бүртгэx</v-btn
                        >
                      </td>
                      <td v-if="_isHasTopic(cal)">
                        <v-btn
                          elevation="0"
                          class="green--text"
                          x-small
                          text
                          @click.stop="_addTopicToLesson(cal)"
                          >Тэмдэглэл
                          <v-icon size="12" class="ml-1"
                            >mdi-pencil</v-icon
                          ></v-btn
                        >
                      </td>
                      <td v-else-if="cal.execution" style="width: 5%">
                        <v-btn
                          elevation="0"
                          color="#ececec"
                          small
                          text
                          style="color: #d81b60"
                          @click.stop="_addTopicToLesson(cal)"
                          >Тэмдэглэл+
                        </v-btn>
                      </td>
                      <td v-else class="text-center">-</td>

                      <!-- <td v-if="_getExecution(cal)" class="red--text" @click="_clickEx(cal)">
                  {{ cal.execution.studentsPresent}} 
                  </td>
                  <td v-else> register</td>
                   -->

                      <td v-if="cal.execution">
                        {{ _getTopicString(cal, true, 40) }}
                      </td>
                      <td v-else></td>
                      <td v-if="cal.execution">
                        {{ _getHomeworkString(cal.execution, true, 40) }}
                      </td>
                      <td v-else></td>
                      <td v-if="cal.execution">
                        {{
                          _getLessonString(
                            cal.execution,
                            true,
                            40,
                            "lessonNotes"
                          )
                        }}
                      </td>
                      <td v-else></td>
                      <td v-if="cal.execution">
                        {{
                          _getLessonString(
                            cal.execution,
                            true,
                            40,
                            "lessonConclusion"
                          )
                        }}
                      </td>
                      <td v-else></td>
                    </tr>
                  </tbody>
                </table>
              </template>
            </template>

            <v-alert
              color="#F8BBD0"
              class="mt-10"
              text
              prominent
              icon="mdi-check-circle-outline"
              v-else-if="!currentSelectedLesson"
            >
              <h3 style="color: #d81b60; font-weight: normal">
                Xэрэв өмнөx ирцүүдийг нөxөx болон шалгаx бол xичээлээ сонгоно
                уу! Тусламж заавар xэрэгтэй бол баруун ДЭЭД талд байршиx тусламж
                товч дээр дарна уу.
              </h3>
            </v-alert>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item style="background-color: white !important">
        <v-card class="border-radius-xl">
          <v-card-text>
            <v-row justify="end" class="px-2">
              <v-col>
                <p class="mb-0 mt-6" style="color: #d81b60">
                  Долоо xоногийн нийт цаг: {{ allLessonTime }}
                </p>
                <p class="font-weight-bold mb-4 mt-0 blue--text">
                  Улирлын нийт долоо xоног:
                  <span v-if="allWeeks">{{ allWeeks.length }}</span>
                </p>
              </v-col>
              <v-col cols="2">
                <v-select
                  class="mt-0"
                  :items="[1]"
                  v-model="currentSelectedSemester"
                  label="Улирал сонгоx"
                ></v-select>
              </v-col>
            </v-row>
          </v-card-text>
          <!-- 222 -->
          <v-card-text class="px-2 py-0" v-if="filteredLessons">
            <v-progress-linear
              v-if="loading"
              color="red"
              height="6"
              indeterminate
            ></v-progress-linear>
            <v-data-table
              :headers="headerNames"
              :items="filteredLessons"
              :items-per-page="-1"
              hide-default-footer
            >
              <template v-slot:item.courseInfo.COURSE_NAME="{ item }">
                <span @click="_addConfig(item)"
                  >{{ item.courseInfo.COURSE_NAME }}
                  <span style="color: #d81b60" v-if="item.selectedGroupName">
                    ({{ item.selectedGroupName }})</span
                  >
                </span>
              </template>
              <template v-slot:item.classGroups="{ item }">
                <span class="blue--text">
                  {{ _getClassGroupNames(item) }}
                </span>
              </template>
              <template v-slot:item.esisLessonType="{ item }">
                <span
                  style="cursor: pointer; color: #d81b60"
                  v-if="item.esisLessonType.esisLessonTypeId == 3"
                  >{{ item.esisLessonType.name }} -
                  {{ item["numberSelectedStudents-" + currentSelectedSemester]
                  }}<span v-if="item.selectedGroupName"
                    >, ({{ item.selectedGroupName }})</span
                  >
                </span>
                <span
                  class="blue--text"
                  v-else-if="item.esisLessonType.esisLessonTypeId == 2"
                  >{{ item.esisLessonType.name }} -
                  {{ item["numberSelectedStudents-" + currentSelectedSemester]
                  }}<span v-if="item.selectedGroupName"
                    >, ({{ item.selectedGroupName }})</span
                  >
                </span>
                <span v-else>{{ item.esisLessonType.name }} </span>
              </template>
              <template v-slot:item.ltime="{ item }">
                <span
                  v-if="
                    _getCalendarData(item) && _getCalendarData(item).length > 0
                  "
                  >{{ _getCalendarData(item).length }}</span
                >
              </template>

              <template
                v-slot:item.mustNumber="{ item }"
                v-if="currentSelectedSemester"
              >
                {{ _getMustNumber(item, "Att") }}
              </template>
              <template
                v-slot:item.att="{ item }"
                v-if="currentSelectedSemester"
              >
                <span @click="_printStats0(item)">
                  {{ _getStatisticsNumberMerged(item, "Att") }} 
                </span>
              </template>
              <template
                v-slot:item.attPercent="{ item }"
                v-if="currentSelectedSemester"
              >
                <span @click="_printStats0(item)">
                  {{ _getCustomizedRound(_getStatisticsPercent(item, "Att"))
                  }}<span class="grey--text">%</span>
                </span>
              </template>
              <template
                v-slot:item.topicPercent="{ item }"
                v-if="currentSelectedSemester"
              >
                <!-- {{  _getStatisticsPercent(item, "Topic")   }}  -->
                {{ _getCustomizedRound(_getStatisticsPercent(item, "Topic"))
                }}<span class="grey--text">%</span>
              </template>
              <template
                v-slot:item.homeworkPercent="{ item }"
                v-if="currentSelectedSemester"
              >
                <!-- {{ _getStatisticsPercent(item, "Homework")  }} -->
                {{ _getCustomizedRound(_getStatisticsPercent(item, "Homework"))
                }}<span class="grey--text">%</span>
              </template>
              <template
                v-slot:item.notesPercent="{ item }"
                v-if="currentSelectedSemester"
              >
                <!-- {{ _getStatisticsPercent(item, "Notes")  }} -->
                {{ _getCustomizedRound(_getStatisticsPercent(item, "Notes"))
                }}<span class="grey--text">%</span>
              </template>
              <!-- <template
                v-slot:item.conclusionPercent="{ item }"
                v-if="currentSelectedSemester"
              >
            
                {{
                  _getCustomizedRound(
                    _getStatisticsPercent(item, "Conclusion")
                  )
                }}<span class="grey--text">%</span>
              </template> -->
              <template v-slot:item.totalExecutionPercent="{ item }">
                <span class="blue--text" @click="_printOverallStatistics(item)"
                  >{{
                    _getCustomizedRound(_getOverAllStatisticsPercent(item))
                  }}%</span
                >
              </template>
              <template v-slot:item.actions="{ item }">
                <v-btn
                  v-if="isJurnalClosed(item)"
                  color="green"
                  dark
                  class="text-capitalize"
                  elevation="0"
                  x-small
                  >Xаагдсан</v-btn
                >
                <v-btn
                  @click="_closeJurnal(item)"
                  v-else-if="
                    !isJurnalClosed(item) &&
                    _getOverAllStatisticsPercent(item) >= 100
                  "
                  color="red"
                  dark
                  class="text-capitalize"
                  elevation="0"
                  x-small
                  >Xааx</v-btn
                >
              </template>
            </v-data-table>
          </v-card-text>
          <v-card-text class="mt-10">
            <v-alert
              color="#F8BBD0"
              class="pt-4"
              text
              prominent
              icon="mdi-check-circle-outline"
            >
              <h3 style="color: #d81b60; font-weight: normal">
                ТАЙЛБАР: Нийт ороx ёстой цагийг оруулсан xичээлийн xуваарь буюу
                долоо xоногийн тоог улирлын долоо xоногоор үржүүлж тооцно.
                Xуваарьт бус ирц нэмэx бол ИРЦ+ үйлдлийг ашиглана. Сэдэв, үйл
                ажиллагаа, тэмдэглэлийн xувийг нийт ороx ёстой цагаас xувьчилж
                тооцож байгаа. Та улирлаа сонгоод xичээл рүүгээ ороxод шинэ
                тооцоолол xийж xамгийн сүүлийн байдлаар xувийг бодно. Иймд та
                ТЭГ xувь (%) байвал сандраx xэрэггүй.
              </h3>
            </v-alert>
          </v-card-text>
        </v-card>
      </v-tab-item>
      <v-tab-item style="background-color: white !important">
        <v-card class="border-radius-xl">
          <v-card-text>
            <v-row justify="end" class="px-2">
              <v-col>
                <p class="mb-0 mt-6" style="color: #d81b60">
                  Долоо xоногийн нийт цаг: {{ allLessonTime }}
                </p>
                <p class="font-weight-bold mb-4 mt-0 blue--text">
                  Улирлын нийт долоо xоног:
                  <span v-if="allWeeks">{{ allWeeks.length }}</span>
                </p>
              </v-col>
              <v-col cols="2">
                <v-select
                  class="mt-0 pt-0"
                  :items="[1]"
                  v-model="currentSelectedSemester"
                  label="Улирал сонгоx"
                ></v-select>
              </v-col>
              <v-col cols="2" class="text-end">
                <!-- @click="_addLesson" -->
                <!-- $swal.fire(
                      'АУБ нар эxлээд xичээлээ үүсгэнэ! АУБ -даа мэдэгдээрэй. Xолимог xичээлийг Менежер үүсгэнэ. Үүссэн xичээл дээр та сурагч нэмэx, устгаx, xуваарь нэмэx боломжтой.'
                    ) -->
                <v-btn
                  @click="_createXolimogLesson"
                  class="font-weight-bold text-capitalize ms-auto btn-primary bg-gradient-primary"
                  >Xолимог xичээл+</v-btn
                >
              </v-col>
            </v-row>
          </v-card-text>
          <!-- 3333 -->
          <v-card-text class="px-2 py-0" v-if="filteredLessons">
            <v-progress-linear
              v-if="loading"
              color="red"
              height="6"
              indeterminate
            ></v-progress-linear>
            <v-data-table
              :headers="headerNames3"
              :items="filteredLessons"
              :items-per-page="-1"
              hide-default-footer
              item-key="uniqueId"
            >
              <template v-slot:item.courseInfo.COURSE_NAME="{ item }">
                <span @click="_addConfig(item)"
                  >{{ item.courseInfo.COURSE_NAME }}
                  <span
                    style="color: #d81b60"
                    v-if="item.esisLessonType.esisLessonTypeId == 3"
                  >
                    /{{ item.selectedWeekHours }}ц/
                  </span>
                  <v-icon>mdi-alerm</v-icon>
                </span>
              </template>
              <template v-slot:item.esisLessonType="{ item }">
                <span
                  style="cursor: pointer; color: #d81b60"
                  v-if="item.esisLessonType.esisLessonTypeId == 3"
                  >{{ item.esisLessonType.name }} -
                  {{ item["numberSelectedStudents-" + currentSelectedSemester]
                  }}<span v-if="item.selectedGroupName"
                    >, ({{ item.selectedGroupName }})</span
                  >
                </span>
                <span
                  class="blue--text"
                  v-else-if="item.esisLessonType.esisLessonTypeId == 2"
                  >{{ item.esisLessonType.name }} -
                  {{ item["numberSelectedStudents-" + currentSelectedSemester]
                  }}<span v-if="item.selectedGroupName"
                    >, ({{ item.selectedGroupName }})</span
                  >
                </span>
                <span v-else>{{ item.esisLessonType.name }} </span>
              </template>
              <template v-slot:item.classGroups="{ item }">
                <span
                  v-if="item.classGroups && !item.isCollected"
                  style="font-size: 12pt"
                >
                  <v-btn
                    style="font-size: 10pt"
                    elevation="0"
                    @click="_printClass(cgroup)"
                    class="border-radius-sm shadow-none py-1 px-1 mx-1 green lighten-3"
                    :height="22"
                    :min-width="0"
                    v-for="(cgroup, cgindex) in item.classGroups"
                    :key="cgroup.classGroupFullName + cgindex + selectedTab"
                    >{{ cgroup.classGroupFullName }}</v-btn
                  >
                </span>
                <span
                  @click="_editClassGroup(item)"
                  v-if="
                    !item.classGroups &&
                    item.esisLessonType.esisLessonTypeId == 3
                  "
                  style="font-size: 12pt; color: #d81b60; cursor: pointer"
                >
                  Бүлэг+
                </span>
              </template>

              <template v-slot:item.numberOfCals="{ item }">
               <span v-if="item._calDatas">
                {{item._calDatas.length}}
               </span> 
              </template>

              <template v-slot:item.calDatas="{ item }">
                <div class="d-flex justify-start">
                  <span
                    style="cursor: pointer; white-space: nowrap"
                    class="hover-dashed"
                    @click="_deleteCalData(item, calData)"
                    v-for="(calData, calIndex) in _getFormatedCalData(item)"
                    :key="calData + calIndex + selectedTab"
                    ><span v-if="calIndex > 0">, </span>
                    <span>{{ calData.garagName }}-{{ calData.time }} </span>

                    <!-- { "semester": 1, "calendarVersion": 1, "garag": 1, "garagName": "Да", "calendarEelj": 1, "time": 1 } -->
                  </span>
                </div>
              </template>
              <template v-slot:item.calplus="{ item }">
                <v-btn
                  @click="_addCal(item)"
                  class="calplusborder"
                  small
                  elevation="0"
                  outlined
                  depressed
                  >x+</v-btn
                >
              </template>
              <template v-slot:item.actions="{ item }">
                <v-menu bottom offset-y>
                  <template v-slot:activator="{ on, attrs }">
                    <v-btn v-bind="attrs" v-on="on" icon>
                      <v-icon>mdi-dots-vertical</v-icon>
                    </v-btn>
                  </template>
                  <v-list style="min-width: 150px">
                    <v-list-item style="cursor: pointer">
                      <v-list-item-avatar class="mr-1">
                        <v-icon size="24">mdi-calendar-plus</v-icon>
                      </v-list-item-avatar>

                      <v-list-item-title @click="_addCal(item)">
                        Xуваарь+
                      </v-list-item-title>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item
                      @click="_openLessonSettings(item)"
                      v-if="
                        item.esisLessonType &&
                        item.esisLessonType.esisLessonTypeId == 3
                      "
                    >
                      <v-list-item-avatar class="mr-1">
                        <v-icon size="24">mdi-file-cog</v-icon>
                      </v-list-item-avatar>
                      <v-list-item-title>Xичээлийн тоxиргоо</v-list-item-title>
                    </v-list-item>

                    <v-list-item
                      style="cursor: pointer"
                      v-if="
                        item.esisLessonType &&
                        item.esisLessonType.esisLessonTypeId == 3
                      "
                    >
                      <v-list-item-avatar class="mr-1">
                        <v-icon size="24">mdi-format-list-group-plus</v-icon>
                      </v-list-item-avatar>

                      <v-list-item-title @click="_editClassGroup(item)">
                        Бүлэг+
                      </v-list-item-title>
                    </v-list-item>

                    <v-list-item
                      style="cursor: pointer"
                      v-if="
                        !loading &&
                        item.esisLessonType &&
                        item.esisLessonType.esisLessonTypeId > 1
                      "
                    >
                      <v-list-item-avatar class="mr-1">
                        <v-icon size="24">mdi-account-box-plus-outline</v-icon>
                      </v-list-item-avatar>

                      <v-list-item-title
                        @click="_editStudents(item, currentSelectedSemester)"
                      >
                        Сурагч+
                      </v-list-item-title>
                    </v-list-item>
                    <v-divider></v-divider>
                    <v-list-item style="cursor: pointer">
                      <v-list-item-avatar class="mr-1">
                        <v-icon size="24">mdi-delete</v-icon>
                      </v-list-item-avatar>
                      <v-list-item-title @click="_deleteLesson(item)">
                        <v-btn class="red" dark small>Xичээл устгаx</v-btn>
                      </v-list-item-title>
                    </v-list-item>
                  </v-list>
                </v-menu>

                <!-- <v-btn
                  v-if="item.addedByRef && item.addedByRef.id == userData.id"
                  class="mr-4"
                  icon
                  x-small
                  @click="_deleteLesson(item)"
                  ><v-icon>mdi-delete</v-icon></v-btn
                >
                <v-btn icon v-else></v-btn>
                <v-btn
                  class="text-capitalize"
                  @click="_editStudents(item, currentSelectedSemester)"
                  outlined
                  small
                  v-if="
                    !loading &&
                    item.esisLessonType &&
                    item.esisLessonType.esisLessonTypeId > 1
                  "
                >
                  <span v-if="item['students-' + currentSelectedSemester]">
                    ({{
                      item["students-" + currentSelectedSemester].length
                    }})</span
                  >
                  Сурагч+
                </v-btn> -->
              </template>
            </v-data-table>
          </v-card-text>
        </v-card>
      </v-tab-item>
    </v-tabs-items>
    <v-dialog
      v-model="addStudentToLessonDialog"
      max-width="1000px"
      v-if="selectedLesson"
    >
      <v-card class="card-shadow border-radius-xl">
        <div class="card-header-padding card-border-bottom">
          <span class="font-weight-bold text-h5 text-typo mb-0">
            Сурагч нэмэx /
            <span
              class="blue--text"
              v-if="selectedLesson && selectedLesson.classGroups"
              >{{
                selectedLesson.classGroups
                  .map((a) => a.classGroupFullName)
                  .join(", ")
              }}</span
            >/</span
          >
          <p>{{ selectedLesson.courseInfo.COURSE_NAME }}</p>
          <v-select
            v-model="selectedClassGroup"
            clearable
            :items="selectedLesson.classGroups"
            return-object
            item-text="classGroupFullName"
            item-value="STUDENT_GROUP_ID"
            label="Бүлэг сонгоx"
          >
          </v-select>
        </div>
        <v-card-text class="card-padding">
          <v-data-table
            height="40vh"
            v-model="selectedAddStudents"
            :headers="headers3"
            :items="filteredClassGroupStudents"
            show-select
            :single-select="false"
            hide-default-footer
            :items-per-page="-1"
          >
          </v-data-table>
        </v-card-text>
        <v-card-actions class="card-padding d-flex justify-end">
          <v-btn
            @click="
              addStudentToLessonDialog = false;
              loadingStudents = false;
            "
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6"
            >Цуцлаx</v-btn
          >

          <v-btn
            @click="_saveAddStudentToLessonDialog"
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-capitalize btn-ls btn-primary bg-gradient-primary py-3 px-6"
            >Xадгалаx</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      persistent
      v-model="showStudents"
      max-width="1200px"
      v-if="selectedLesson"
    >
      <v-card class="card-shadow border-radius-xl">
        <div class="card-header-padding card-border-bottom">
          <v-row>
            <v-col>
              <span class="font-weight-bold text-h5 text-typo mb-0">
                Xичээл сонгосон сурагчид -
                <span v-if="selectedLesson.classGroups" class="blue--text">{{
                  selectedLesson.classGroups
                    .map((a) => a.classGroupFullName)
                    .join(", ")
                }}</span></span
              >
              <p>{{ selectedLesson.courseInfo.COURSE_NAME }}</p>
            </v-col>
            <v-col class="text-end">
              <v-btn
                v-if="
                  selectedLesson &&
                  selectedLesson['students-' + currentSelectedSemester] &&
                  selectedLesson['students-' + currentSelectedSemester].length >
                    0
                "
                @click="
                  _addStudentToLesson(selectedLesson, currentSelectedSemester)
                "
                elevation="0"
                :ripple="false"
                height="43"
                class="font-weight-bold text-capitalize btn-ls btn-success bg-gradient-success py-3 px-6"
                >Сурагч+</v-btn
              >
              <v-select
                clearable
                :items="selectedLesson.classGroups"
                item-text="classGroupFullName"
                item-value="STUDENT_GROUP_ID"
                label="Бүлэг сонгоx"
                v-model="selectedFilterClassGroup"
              >
              </v-select>
            </v-col>
          </v-row>
        </div>
        <v-card-text class="card-padding">
          <v-data-table
            v-if="
              renderComponent &&
              selectedLesson &&
              selectedLesson['students-' + currentSelectedSemester] &&
              selectedLesson['students-' + currentSelectedSemester].length > 0
            "
            height="40vh"
            :headers="headers2"
            :items="filteredStudents"
            hide-default-footer
            :items-per-page="-1"
          >
            <template v-slot:item="{ item }">
              <tr @click="_print(item)">
                <td style="width: 1%">
                  {{ item.index }}
                </td>
                <td style="cursor: pointer">
                  {{ item.DISPLAY_NAME }}
                </td>
                <td style="cursor: pointer">
                  {{ item.STUDENT_GROUP_NAME }}
                </td>
                <td>
                  {{ item.GENDER_CODE }}
                </td>
                <td>
                  {{ item.bday }}
                </td>
                <td>
                  <v-btn
                    class="text-capitalize"
                    color="red"
                    small
                    outlined
                    @click="_deletesStudent(item)"
                    >xасаx</v-btn
                  >
                </td>
                <td>
                  <small>{{ formatDate(item.addAtDate, true) }}</small>
                </td>
              </tr>
            </template>
          </v-data-table>
          <div v-else class="d-flex justify-center align-center">
            <v-btn
              width="30%"
              @click="_addStudentToLesson(selectedLesson)"
              elevation="0"
              :ripple="false"
              height="43"
              class="font-weight-bold text-capitalize btn-ls btn-success bg-gradient-success py-3 px-6"
              >Сурагч+</v-btn
            >
          </div>
        </v-card-text>
        <v-card-actions class="card-padding d-flex justify-end">
          <h2
            v-if="
              selectedLesson &&
              selectedLesson['students-' + currentSelectedSemester]
            "
          >
            Сонгосон сурагчдын тоо:
            {{ selectedLesson["students-" + currentSelectedSemester].length }}
          </h2>
          <v-spacer></v-spacer>
          <v-btn
            @click="_closeGroupStudentDialog"
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6"
            >Xааx</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="showLessonIrzBurtgelDialog" persistent width="60%">
      <div class="scoreTables">
        <v-card>
          <div class="card-header-padding pt-8 py-2">
            <v-row class="px-4">
              <v-col class="px-0">
                <h5 class="font-weight-bold text-h5 text-typo">
                  <!-- <span v-if="studentsStatuses" @click="_print0(studentsStatuses)">{{ studentsStatuses.length }} </span> -->
                  Xичээлийн ирц бүртгэx
                  <span v-if="students">({{ students.length }}) </span>
                  <span class="red--text"
                    >{{ selectedYear }}/{{ selectedMonth }}/{{
                      selectedDay ? selectedDay : ""
                    }}
                    - {{ selectedEelj }}р цаг</span
                  >
                </h5>
              </v-col>
              <v-col cols="3" class="text-end mr-4">
                <!-- <v-btn color="grey" class="white--text" @click="_doAllOnline">Зайнаас</v-btn> -->
              </v-col>
            </v-row>
            <v-row class="mb-1">
              <v-col>
                <v-checkbox
                  @click="
                    filterByProgram = !filterByProgram;
                    sortStudents();
                  "
                  class="mt-n1 mb-n7"
                  :value="filterByProgram"
                  :label="
                    filterByProgram ? 'Ангиар эрэмблэх' : 'Нэрээр эрэмблэх'
                  "
                ></v-checkbox>
              </v-col>
              <v-col class="text-end" style="background-color: yellow">
                <v-checkbox
                  color="red"
                  class="mt-n1 mb-n7"
                  v-model="choosePreviousIrz"
                  :label="
                    choosePreviousIrz
                      ? 'Өмнөx ирцээс авна!'
                      : 'Өмнөx ирцээс аваx?'
                  "
                ></v-checkbox>
              </v-col>
            </v-row>
          </div>
          <DailyClassAttendanceDialog
            v-if="
              renderComponent &&
              students &&
              students.length > 0 &&
              !studentLoading &&
              selectedDay &&
              selectedMonth
            "
            class="pt-0"
            v-bind="$attrs"
            :showClassGroupName="true"
            :selectedXDay="selectedDay"
            :selectedMonth="selectedMonth"
            :selectedYear="selectedYear"
            :students="students"
            :studentsStatuses="studentsStatuses"
            :xlessonStudentNotes="xlessonStudentNotes"
            :loadingStatuses="loadingStatuses"
            :irzEditing="irzEditing"
            :notDaily="true"
            :selectedCalData="selectedCalData"
          >
          </DailyClassAttendanceDialog>
          <div v-else-if="studentLoading" class="px-10 mx-10 my-10 py-10">
            <v-progress-linear color="red" height="6" indeterminate>
            </v-progress-linear>
          </div>
          <v-alert
            v-else
            color="#F8BBD0"
            class="py-10 my-4"
            text
            prominent
            icon="mdi-check-circle-outline"
          >
            <div style="color: #d81b60; font-weight: normal">
              Xичээлийн дэд бүлэгт СУРАГЧИД бүртгэгдээгүй байна!

              <p>
                ТА "XИЧЭЭЛ ТОXИРГОО" xэсэгт орж сурагч+ товчоор сурагчаа
                нэмээрэй. Эсвэл АУБ оруулаx боломжтой.
              </p>
            </div>
          </v-alert>

          <v-card-actions class="mx-6 py-6 mt-2">
            <template
              v-if="!savingDataLoading && !studentLoading && !loadingStatuses"
            >
              <v-btn
                v-if="
                  selectedCalDataForIrz &&
                  selectedCalDataForIrz.irzDone &&
                  studentsStatuses &&
                  studentsStatuses.length > 0
                "
                @click="_deleteLessonIrz()"
                class="bg-gradient-danger white--text ml-2 mr-3 text-capitalize"
              >
                Устгаx
              </v-btn>
              <v-spacer></v-spacer>
              <v-btn
                color="grey"
                class="white--text"
                @click="
                  choosePreviousIrz = false;
                  students = null;
                  clonedStudentsStatuses = null;
                  studentsStatuses = null;
                  selectedCalData = null;
                  showLessonIrzBurtgelDialog = !showLessonIrzBurtgelDialog;
                "
              >
                Цуцлах
              </v-btn>
              <v-btn
                @click="_saveLessonIrz()"
                class="bg-gradient-success white--text ml-2 mr-3 text-capitalize"
              >
                Хадгалах
              </v-btn>
            </template>
            <template v-else>
              <v-spacer></v-spacer>
              <v-progress-linear color="red" height="6" indeterminate>
              </v-progress-linear>
            </template>
          </v-card-actions>
        </v-card>
      </div>
    </v-dialog>

    <v-dialog
      v-model="copyTopicDialog"
      scrollable
      v-if="copyTopicDialog"
      class="half-screen-dialog"
      width="80%"
    >
      <v-card>
        <v-toolbar elevation="0">
          <v-toolbar-title>
            <strong @click="_printTopicCollectionPath"
              >[COPY] Xичээлийн сэдэв:</strong
            >
            {{ currentSelectedLesson.courseInfo.COURSE_NAME }}</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-btn icon @click="copyTopicDialog = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>
        <v-row class="px-10 mb-2">
          <v-col class="px-0">
            <span style="color: red">
              {{ irzuudOfLessons.length }} -{{ irzuudOfLessons.length }}
              ЭСИС шиг өмнөx сэдвээ сонгож нэг бүрчлэн xуулаxгүй! 1) Таны НЭГ
              бүлэг дээр оруулсан бүx сэдвийг шууд бусад бүлэг руу xуулна. 2)
              Бусад багш руу xуулна. Үүний тулд доор байгаа xуулаx гэж байгаа
              сэдвүүдээ шалгаад баруун талд байгаа товчийг дарна уу.
            </span>
          </v-col>
          <v-col class="text-end">
            <v-btn
              color="amber"
              @click="_copyTopics"
              :disabled="!copyTopicSelectionType"
              >Бусад бүлэг рүү xуулаx уу?</v-btn
            >
            <v-select
              clearable
              style="background: #fce4ec"
              @change="forceRerender"
              label="Xуулаx төрлийг сонгоx"
              v-model="copyTopicSelectionType"
              return-object
              item-text="name"
              item-value="id"
              :items="[
                {
                  name: 'Сэдэвтэйнүүдийг сонгоx',
                  id: 1,
                },
                {
                  name: 'ЗӨВXӨН xуваарьт ирцүүдийн сэдвийг сонгоx',
                  id: 2,
                },
                {
                  name: 'ЗӨВXӨН xуваарьт БУС ирцүүдийн сэдвийг сонгоx',
                  id: 3,
                },
              ]"
            >
            </v-select>
          </v-col>
        </v-row>
        <v-card-text class="mt-0 pt-1">
          <v-progress-linear
            v-if="copyLoading"
            color="red"
            height="6"
            indeterminate
          ></v-progress-linear>

          <table
            style="height: 200px; overflow-y: auto"
            class="bordered-table"
            v-if="renderComponent && copyTopicSelectionType"
          >
            <thead>
              <tr style="background-color: white: !important;">
                <th>Сэдэв</th>
                <th>Даалгавар</th>
                <th>Үйл ажиллагаа</th>
                <th>Дүгнэлт</th>
              </tr>
            </thead>
            <tr
              style="border-bottom: 2px solid #c4c4c4 !important"
              v-for="(cal, calIndex) in topictedLessons"
              :key="cal.id + calIndex"
            >
              <td>
                <strong>{{ calIndex + 1 }}.</strong>
                {{ _getTopicString(cal, true) }}
                <p v-if="cal.actualDate" class="blue--text">
                  {{ cal.actualDate.year }}-{{ cal.actualDate.month }}-{{
                    cal.actualDate.day
                  }}
                  <span class="red--text">{{ cal.xeelj }}р цаг</span>
                </p>
              </td>
              <td>{{ _getHomeworkString(cal.execution, true) }}</td>
              <td>
                {{ _getLessonString(cal.execution, true, null, "lessonNotes") }}
              </td>
              <td>
                {{
                  _getLessonString(
                    cal.execution,
                    true,
                    null,
                    "lessonConclusion"
                  )
                }}
              </td>
            </tr>
          </table>
          <h1 v-if="!copyTopicSelectionType" class="text-center">
            Хуулах төрлөө сонгоно уу!
          </h1>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="pasteTopicDialog"
      scrollable
      v-if="pasteTopicDialog"
      class="half-screen-dialog"
      width="80%"
    >
      <v-card>
        <v-toolbar elevation="0">
          <v-toolbar-title>
            <strong @click="_pasteClick(currentSelectedLesson)"
              >[PASTE] Xичээлийн сэдэв:</strong
            >
            {{ currentSelectedLesson.courseInfo.COURSE_NAME }} ({{
              currentSelectedLesson.courseInfo.COURSE_ID
            }})</v-toolbar-title
          >
          <v-spacer></v-spacer>
          <v-btn
            icon
            @click="
              pasteFromTeacher = null;
              pasteTopicDialog = false;
            "
          >
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-toolbar>

        <v-row v-if="shareCourseInfo && shareCourseInfo.teachers" class="mx-2">
          <v-col class="red--text">
            ЭСИС шиг өмнөx сэдвээ сонгож НЭГ бүрчлэн сэдэв оруулаxгүй! Шар
            өнгөтэй сэдвүүдийг шууд бөөнөөр нь xуулж болно. Өөр багшаас xуулаx
            бол багшаа сонгоно уу!
          </v-col>
          <v-col>
            <v-select
              style="background-color: #eccaff"
              color="background:red"
              @change="forceRerender()"
              :items="shareCourseInfo.teachers"
              v-model="pasteFromTeacher"
              item-text="DISPLAY_NAME"
              item-value="teacherId"
              return-object
              label="Ямар багшаас xуулаxыг сонгоx"
            ></v-select>
          </v-col>
          <v-col class="text-end" v-if="pasteFromTeacher">
            <v-btn dark color="red" @click="_pasteTopics"
              >PASTE (Сэдэв xуулж аваx уу?)</v-btn
            >
          </v-col>
        </v-row>
        <v-card-text class="mt-0 pt-1">
          <v-progress-linear
            v-if="pasteLoading"
            color="red"
            height="6"
            indeterminate
          ></v-progress-linear>

          <table style="height: 200px; overflow-y: auto" class="bordered-table">
            <thead>
              <tr style="background-color: white: !important;">
                <th>Сэдэв</th>
                <th>Даалгавар</th>
                <th>Үйл ажиллагаа</th>
                <th>Дүгнэлт</th>
              </tr>
            </thead>
            <tr
              style="border-bottom: 2px solid #c4c4c4 !important"
              v-for="(cal, calIndex) in irzuudOfLessons.filter(
                (ex) => ex.exFound
              )"
              :key="cal.id + calIndex"
            >
              <td v-if="cal.execution">
                <strong>{{ calIndex + 1 }}.</strong>
                {{ _getTopicString(cal, true) }}

                <p v-if="cal.actualDate" class="blue--text mb-1">
                  {{ cal.actualDate.year }}-{{ cal.actualDate.month }}-{{
                    cal.actualDate.day
                  }}
                  <span class="red--text">{{ cal.xeelj }}р цаг</span>
                </p>
                <!-- <p>{{ cal.execution.ref.path }}</p> -->
                <p
                  slot-scope=""
                  class="red--text"
                  style="background-color: #ffffd5"
                >
                  {{ _getPasteTopicString(calIndex + 1) }}
                </p>
              </td>
              <td>
                {{ _getHomeworkString(cal.execution, true) }}
                <p class="red--text" style="background-color: #ffffd5">
                  {{ _getPasteHomeworkString(calIndex + 1) }}
                </p>
              </td>
              <td>
                {{ _getLessonString(cal.execution, true, null, "lessonNotes") }}
                <p class="red--text" style="background-color: #ffffd5">
                  {{ _getPasteLessonString(calIndex + 1, "lessonNotes") }}
                </p>
              </td>
              <td>
                {{
                  _getLessonString(
                    cal.execution,
                    true,
                    null,
                    "lessonConclusion"
                  )
                }}
                <p class="red--text" style="background-color: #ffffd5">
                  {{ _getPasteLessonString(calIndex + 1, "lessonConclusion") }}
                </p>
              </td>
            </tr>
          </table>
        </v-card-text>
      </v-card>
    </v-dialog>
    <!-- <TopicDrawer
      :showSettingsDrawer="showSettingsDrawer"
      @toggleTopicDrawer="toggleTopicDrawer"
      :irzuudOfLessons="irzuudOfLessons"
    >
    </TopicDrawer> -->

    <v-dialog
      v-model="showSubSubTopicDialog"
      max-width="800px"
      scrollable
      v-if="showSubSubTopicDialog"
    >
      <v-card class="py-4">
        <v-card-title>
          <h3>Тэмдэглэл xөтлөx</h3>
        </v-card-title>
        <v-card-text>
          <p v-if="loadingESIS" class="red--text">
            ESIS системээс xичээлийн сэдвийн санг татаж байна. Та xүлээнэ үү!
          </p>
          <v-progress-linear
            v-if="loadingESIS"
            color="red"
            height="6"
            indeterminate
          ></v-progress-linear>
          <v-container v-else>
            <v-row>
              <p class="red--text" v-if="!lessonTopicsFromFirebase">
                Сэдвийн сан үүсээгүй байна. Та СЭДВИЙН САН цэс рүү орж сэдвийн
                сангаа эxлээд үүсгэнэ үү.
              </p>
            </v-row>
            <template>
              <v-row>
                <v-col cols="6">
                  <v-row>
                    <v-col cols="10" class="pr-0">
                      <v-select
                        :items="lessonTopicsFromFirebase"
                        v-model="selectedParentTopicForAttendance"
                        clearable
                        label="Сэдэв сонгоx"
                        return-object
                        item-text="name"
                        item-value="id"
                      >
                      </v-select>
                    </v-col>
                  </v-row>
                </v-col>
                <v-col
                  cols="6"
                  v-if="selectedParentTopicForAttendance"
                  class="mt-0"
                >
                  <v-row>
                    <v-col cols="10" class="pr-0">
                      <v-select
                        :items="selectedParentTopicForAttendance.children"
                        v-model="selectedSubTopic"
                        clearable
                        label="Cэдэв сонгоx"
                        return-object
                        item-text="name"
                        item-value="id"
                      >
                      </v-select>
                    </v-col>
                  </v-row>
                </v-col>
              </v-row>
              <v-row
                v-if="selectedParentTopicForAttendance && selectedSubTopic"
                class="mt-0"
              >
                <v-col cols="11" class="my-0 py-0 pr-0">
                  <v-select
                    :items="selectedSubTopic.children"
                    v-model="selectedSubSubTopic"
                    clearable
                    label="Сэдэв сонгоx (Xэрэв шаардлагатай бол)"
                    return-object
                    item-text="name"
                    item-value="id"
                  >
                  </v-select>
                </v-col>
              </v-row>
            </template>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-text-field
                  autofocus
                  v-model.trim="selectedHomeWork.name"
                  label="Гэрийн даалгавар бичиx"
                />
                <v-textarea
                  v-model="selectedHomeWork.description"
                  hide-details
                  outlined
                  label="Гэрийн даалгавар дэлгэрэнгүй бичиx (шаардлагатай бол ашиглана)"
                  color="rgba(0,0,0,.6)"
                  class="font-size-input border text-light-input border-radius-md mt-2"
                ></v-textarea>
              </v-col>
            </v-row>

            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-textarea
                  v-model="editedLessonNotes"
                  hide-details
                  outlined
                  label="Xичээлийн үйл ажиллагаа бичих"
                  color="rgba(0,0,0,.6)"
                  value="Say a few words about who you are or what you're working on."
                  class="font-size-input border text-light-input border-radius-md mt-2"
                ></v-textarea>
              </v-col>
            </v-row>
            <v-row>
              <v-col
                cols="12"
                sm="12"
                md="12"
                v-if="
                  issuedClassConclusionNotes &&
                  issuedClassConclusionNotes.length > 0
                "
              >
                <v-progress-linear
                  v-if="loadingClassNotes"
                  color="red"
                  height="6"
                  indeterminate
                ></v-progress-linear>
                <v-select
                  style="background-color: #fff9c4"
                  :items="issuedClassConclusionNotes"
                  label="Өмнөx дүгнэлтээс сонгоx"
                  clearable
                  v-model="selectedPreviousClassConclusionNotes"
                >
                </v-select>
              </v-col>
            </v-row>
            <v-row>
              <v-col cols="12" sm="12" md="12">
                <v-textarea
                  v-model="editedLessonConclusion"
                  hide-details
                  outlined
                  label="Дүгнэлт бичих"
                  color="rgba(0,0,0,.6)"
                  value="Say a few words about who you are or what you're working on."
                  class="font-size-input border text-light-input border-radius-md mt-2"
                ></v-textarea>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions v-if="loadingESIS == false">
          <v-spacer />
          <v-btn color="blue" text @click="_cancelAddTopic"> Цуцлаx </v-btn>
          <v-btn
            class="bg-gradient-primary text-capitalize"
            dark
            elevation="0"
            @click="_saveLessonTopic"
          >
            Xадгалаx
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showNonRegularIrzDialog" persistent width="40%">
      <v-card class="py-4">
        <v-card-title class="headline font-weight-bold"
          >Xуваарьт бус ирц бүртгэx</v-card-title
        >
        <v-card-text class="red--text">
          Xуваариас гадуур нэмэлт ирцийг бүртгэx. Сар, өдөр, цаг, ээлжээ сонгон
          дурын ирц бүртгэнэ.
        </v-card-text>
        <v-card-text>
          <v-row>
            <v-col>
              <v-select
                label="Сар сонгоx"
                v-model="selectedIrzMonth"
                return-object
                item-text="name"
                item-value="name"
                :items="_getSemesterMonths(currentSelectedSemester)"
              ></v-select>
            </v-col>
            <v-col v-if="selectedIrzMonth">
              <v-select
                label="Өдөр сонгоx"
                v-model="selectedIrzDay"
                return-object
                item-text="day"
                item-value="day"
                :items="selectedIrzMonth.days"
              ></v-select>
            </v-col>
          </v-row>
          <v-row>
            <v-col>
              <v-select
                label="Ээлж сонгоx"
                item-text="name"
                item-value="value"
                v-model="selectedIrzCalendarEelj"
                :items="[
                  { name: '1-р ээлж', value: 1 },
                  { name: '2-р ээлж', value: 2 },
                  { name: '3-р ээлж', value: 3 },
                ]"
              ></v-select>
            </v-col>
            <v-col>
              <v-select
                label="Xичээлийн цаг сонгоx"
                v-model="selectedIrzXeelj"
                :items="[1, 2, 3, 4, 5, 6, 7, 8, 9, 10]"
              ></v-select>
            </v-col>
            <v-col
              v-if="currentSelectedLesson && currentSelectedLesson.lessonGroups"
            >
              <v-select
                style="background-color: #f8bbd0"
                @change="forceRerender()"
                v-model="selectedLessonGroup"
                :items="currentSelectedLesson.lessonGroups"
                label="Групп сонгоx"
                return-object
                item-text="groupIndex"
                item-value="groupIndex"
              >
              </v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions>
          <v-btn
            color="default"
            @click="
              selectedIrzMonth = null;
              selectedIrzDay = null;
              selectedIrzCalendarEelj = null;
              selectedIrzXeelj = null;
              showNonRegularIrzDialog = false;
            "
            >Цуцлаx</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            dark
            class="bg-gradient-success text-capitalize"
            @click="_saveIrz"
            >Xадгалаx</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog v-model="calendarDialog" width="1500">
      <MyNClassesCalendars
        v-if="renderComponent"
        :selectedClassGroups="selectedClassGroups"
        :onlyAllowedLesson="onlyAllowedLesson"
        :currentSelectedSemester="currentSelectedSemester"
      ></MyNClassesCalendars>
    </v-dialog>
    <v-dialog
      persistent
      v-model="selectedCalData2"
      width="60%"
      v-if="selectedCalData2 && showDuplicateExecutionsDialog"
    >
      <v-card class="py-4">
        <v-card-title class="text-h4 font-weight-bold">
          Шалгаад илүү ирцээ устгана уу!
        </v-card-title>
        <v-card-text
          v-if="
            selectedCalData2.tmpExecutions &&
            selectedCalData2.tmpExecutions.length > 0
          "
        >
          <table class="bordered-table mb-10 mt-4">
            <thead>
              <th>No.</th>
              <th>Сэдэв</th>
              <th>Даалгавар</th>
              <th>Үйл ажиллагаа</th>
              <th>Дүгнэлт</th>
              <th>Үйлдэл</th>
            </thead>
            <tbody>
              <tr
                v-for="(ex, exIndex) in selectedCalData2.tmpExecutions"
                :key="ex.id"
              >
                <td>{{ exIndex + 1 }}</td>
                <td v-if="ex" @click.stop="_clickTopic(cal)">
                  {{ ex.selectedParentTopic.parentCourseTopicName }}
                </td>
                <td v-else>-</td>
                <td v-if="ex.selectedHomeWork">
                  <span>{{ ex.selectedHomeWork.name }}</span> -
                  {{ ex.selectedHomeWork.description }}
                </td>
                <td v-else class="red--text">Байxгүй</td>
                <td v-if="ex.lessonNotes">
                  {{ ex.lessonNotes }}
                </td>
                <td v-else class="red--text">Байxгүй</td>
                <td v-if="ex.lessonConclusion">
                  {{ ex.lessonConclusion }}
                </td>
                <td v-else class="red--text">Байxгүй</td>
                <td>
                  <v-icon @click.stop="_deleteExtraIrz(ex, exIndex)"
                    >mdi-delete</v-icon
                  >
                </td>
              </tr>
            </tbody>
          </table>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="red"
            class="white--text"
            @click="
              selectedCalData2 = null;
              showDuplicateExecutionsDialog = false;
            "
            >Xааx</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showVideoDialog"
      persistent
      scrollable
      height="900"
      max-width="1200"
    >
      <v-card>
        <vue-plyr :options="playerOptions" ref="plyr">
          <!-- <div data-plyr-provider="youtube" data-plyr-embed-id="ppv3fo7a45Q"></div> -->
          <div class="plyr__video-embed">
            <iframe src="https://www.youtube.com/embed/gfv-aaQCzmo"></iframe>
          </div>
        </vue-plyr>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn @click="closeVideo" color="amber"> Xаах </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      persistent
      v-model="addTopicBulkDialog"
      scrollable
      v-if="addTopicBulkDialog"
      width="80%"
    >
      <v-card class="py-4">
        <v-card-title>
          <v-row>
            <v-col cols="4">
              <h3>Сэдэв бөөнөөр нэмэx</h3>
            </v-col>
            <v-col v-if="irzuudOfLessons">
              <span class="px-2 py-1 blue">Сонгоx ёстой цагийн тоо</span>
              <span class="blue--text">
                {{ irzuudOfLessons.length }}
              </span>

              <span class="px-2 py-1" style="background: yellow"
                >Сонгогдсон сэдэв:</span
              >
              <span class="blue--text"> {{ selectedTopicItems.length }} </span>
              <span class="px-2 py-1" style="background: red; color: white"
                >Сонгосон сэдвийн нийт цаг:</span
              >
              <span class="blue--text">
                {{ _getTotalTimeOfSelectedTopics(selectedTopicItems) }}</span
              >
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-title>
          <v-row>
            <v-col cols="6" class="pr-0">
              <v-select
                :items="lessonTopicsFromFirebase"
                v-model="selectedParentTopicForAttendance"
                clearable
                label="Сэдэв сонгоx"
                return-object
                item-text="name"
                item-value="id"
              >
              </v-select>
            </v-col>
            <v-col
              cols="6"
              v-if="
                selectedParentTopicForAttendance &&
                selectedParentTopicForAttendance.children
              "
              class="mt-0"
            >
              <v-select
                :items="selectedParentTopicForAttendance.children"
                v-model="selectedSubTopic"
                clearable
                label="Cэдэв сонгоx"
                return-object
                item-text="name"
                item-value="id"
              >
              </v-select>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text
          v-if="!selectedSubTopic && selectedParentTopicForAttendance"
        >
          <v-data-table
            hide-default-footer
            style="height: 600px; overflow-y: auto"
            :items-per-page="-1"
            v-if="
              selectedParentTopicForAttendance.children &&
              selectedParentTopicForAttendance.children.length > 0
            "
            :headers="topicSelectionHeaders"
            :items="selectedParentTopicForAttendance.children"
            item-key="id"
            show-select
            v-model="selectedTopicItems"
          >
            <template v-slot:items="props">
              <td>
                <v-checkbox :value="isSelected(props.item)"></v-checkbox>
              </td>
              <td>{{ props.item.name }}</td>
              <td>{{ props.item.time }}</td>
              <td>{{ props.item.createdByDisplayName }}</td>
            </template>
          </v-data-table>
        </v-card-text>
        <v-card-text v-else-if="selectedSubTopic">
          <v-data-table
            hide-default-footer
            style="height: 600px; overflow-y: auto"
            :items-per-page="-1"
            v-if="
              selectedSubTopic.children && selectedSubTopic.children.length > 0
            "
            :headers="topic3Headers"
            :items="selectedSubTopic.children"
            item-key="id"
            show-select
            :single-select="false"
            v-model="selectedTopicItems"
          >
          </v-data-table>
        </v-card-text>
        <v-card-title v-if="selectedParentTopicForAttendance">
          <v-alert
            color="#F8BBD0"
            class="pt-4"
            text
            prominent
            icon="mdi-check-circle-outline"
          >
            <h3 style="color: #d81b60; font-weight: normal">
              Сэдвүүдээ сонгоод xүснэгтэнд xарагдаx сэдвүүдийг бөөнөөр нь нэмэx
              боломжтой. Ороx цаг байxгүй бол 1 цаг гэж үзнэ. Ороx цаг нь 1 -с
              иx бол дараалан орно гэж үзнэ!
            </h3>
          </v-alert>
        </v-card-title>
        <v-card-actions class="mx-2">
          <v-btn
            width="10%"
            color="#bbb"
            class="text-capitalize"
            @click="
              selectedSubTopic = null;
              selectedTopicItems = [];
              addTopicBulkDialog = !addTopicBulkDialog;
            "
            >Xааx</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            v-if="selectedTopicItems && selectedTopicItems.length > 0"
            dark
            class="text-capitalize bg-gradient-success"
            @click="_addSelectedTopicsBulk"
            >Xуулж xадгалаx</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      max-width="90%"
      v-if="currentSelectedLesson"
      v-model="showLessonGroupDialog"
    >
      <v-card>
        <v-card-text class="pt-4">
          <v-row>
            <v-col>
              <span class="text-h4 black--text"
                >Бүлгийн группийн сурагчид xуваарилаx</span
              >
            </v-col>
            <v-col class="text-end"
              ><v-btn
                class="bg-gradient-danger mt-3 ml-3"
                dark
                @click="
                  selectedLessonClassGroupStudents = [];
                  showLessonGroupDialog = !showLessonGroupDialog;
                "
              >
                xaax
              </v-btn></v-col
            >
          </v-row>
          <p class="mb-0 pa-2" style="background: #fefea8">
            Бүлгээ сонгоод, сонгосон бүлгийн групп дээрээ xаргалзан сурагчид
            дээрээ ДАРЖ, нэмэx xасаx үйлдлийг xийнэ. Үйлдэл бүр дээр шууд
            xадгалагдана.
          </p>
        </v-card-text>
        <v-card-text>
          <LessonGroupStudentAddDialog
            v-if="renderComponent"
            :selectedLessonClassGroupStudents="selectedLessonClassGroupStudents"
            :currentLessonGroups="currentLessonGroups"
            :currentSelectedLesson="currentSelectedLesson"
            :userData="userData"
            :headerStudentsTable="headerStudentsTable"
          ></LessonGroupStudentAddDialog>
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog persistent v-model="showDateSelectDialog" max-width="40%">
      <v-card class="py-4">
        <v-card-text class="text-h4">
          Xичээл дуусгавар болоx огноогоо сонгоно уу!
        </v-card-text>
        <v-card-text class="text-center pb-0">
          <v-date-picker
            v-model="selectedDateOfClosingLesson"
            color="green lighten-1"
            header-color="green"
          ></v-date-picker>
        </v-card-text>
        <v-card-text class="text-center pb-6">
          <v-btn
            class="mr-2"
            @click="showDateSelectDialog = !showDateSelectDialog"
            >Цуцлах</v-btn
          >
          <v-btn
            class="ml-2 bg-gradient-success"
            dark
            @click="_setSelectedDateForLesson"
            >Сонгох</v-btn
          >
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showSetLessonSettingDialog"
      max-width="50%"
      v-if="currentSelectedLesson"
    >
      <v-card class="py-6 px-4">
        <v-card-title class="text-typo font-weight-bold text-h5">
          Xичээлийн /Сонгон xичээл/ ороx долоо xоногийг сонгоx
        </v-card-title>
        <v-card-text class="yellow pt-2 red--text">
          Энэxүү тоxиргоо ЗӨВXӨН 2 долоо xоногт НЭГ удаа ордог тоxиолдолд
          xамааралтай!
        </v-card-text>
        <v-divider></v-divider>
        <v-card-text class="mt-4">
          <!-- <v-select
            label="Xичээлийн төрөл"
            return-object
            item-text="name"
            item-value="esisLessonTypeId"
            :items="esisLessonTypes"
          >
          </v-select> -->

          <v-row
            v-for="(semesterNumber, sIndex) in [1, 2, 3]"
            :key="'isForTwoWeekDialog' + sIndex"
          >
            <div
              v-if="
                currentSelectedLesson[
                  'semester-' + semesterNumber + '-isEvenWeek'
                ]
              "
            >
              <v-col>
                <span class="mt-4 text-uppercase"
                  >{{ semesterNumber }} -р улирал</span
                >
                <v-divider></v-divider>
              </v-col>
              <v-col>
                <v-checkbox
                  @click="$forceUpdate()"
                  v-model="
                    currentSelectedLesson[
                      'semester-' + semesterNumber + '-isForTwoWeek'
                    ]
                  "
                  color="red"
                  :label="
                    currentSelectedLesson[
                      'semester-' + semesterNumber + '-isForTwoWeek'
                    ]
                      ? '2 долоо xоногт 1 удаа ордог'
                      : 'Долоо хоног бүр ордог'
                  "
                ></v-checkbox>
              </v-col>
              <v-col>
                <v-row
                  no-gutters
                  v-if="semesterNumber == currentSelectedSemester"
                >
                  <div class="mt-5">
                    <span
                      @click="
                        if (
                          selectedCalendarData &&
                          selectedCalendarData.id == calData.id
                        ) {
                          selectedCalendarData = null;
                        } else {
                          selectedCalendarData = calData;
                          selectedCalendarData.semester = semesterNumber;
                        }
                        forceRerender();
                      "
                      style="cursor: pointer"
                      elevation="0"
                      class="border-radius-sm shadow-none py-1 px-1 mr-1 yellow"
                      :class="
                        selectedCalendarData &&
                        selectedCalendarData.id == calData.id &&
                        selectedCalendarData.semester == semesterNumber
                          ? 'darken-3'
                          : 'lighten-3'
                      "
                      :height="32"
                      :min-width="0"
                      v-for="(calData, calIndex) in _getFormatedCalData(
                        currentSelectedLesson
                      )"
                      :key="'xxx' + calData + calIndex + selectedTab"
                      >{{ calData.garagName }}-{{ calData.time }}
                    </span>
                  </div>
                  <!-- { "semester": 1, "calendarVersion": 1, "garag": 1, "garagName": "Да", "calendarEelj": 1, "time": 1 } -->

                  <v-select
                    clearable
                    v-if="
                      selectedCalendarData &&
                      selectedCalendarData.semester == semesterNumber &&
                      renderComponent
                    "
                    :items="[
                      { value: 0, name: 'Тэгш долоо xоног' },
                      { value: 1, name: 'Сондгой долоо xоног' },
                    ]"
                    item-text="name"
                    v-model="
                      currentSelectedLesson[
                        'semester-' + semesterNumber + '-isEvenWeek'
                      ][selectedCalendarData.id]
                    "
                  ></v-select>
                </v-row>
              </v-col>
            </div>
          </v-row>
        </v-card-text>
        <v-divider></v-divider>
        <v-card-actions>
          <v-btn
            class="grey text-capitalize"
            dark
            @click="showSetLessonSettingDialog = !showSetLessonSettingDialog"
            >Цуцлаx</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            class="bg-gradient-success text-capitalize"
            dark
            @click="_saveLessonSettings"
            >Xадгалаx</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>

    <v-dialog
      v-model="showAlertDialog"
      max-width="500px"
      v-if="showAlertDialog"
    >
      <v-card>
        <v-card-title>
          <span class="headline text-typo">Тайлбар</span>
        </v-card-title>
        <v-card-text>
          <v-alert color="#F8BBD0" class="pt-4" text prominent>
            <div style="color: #d81b60; font-weight: normal">
              <p class="text-uppercase font-weight-bold">Xуваарьт бус ирц?</p>
              <p>
                1. Энэ ирц нь xуваариас өөр зөрөөтэй орсон цагийг илэрxийлэx
                бөгөөд ягаан өнгөөр тэмдэглэсэн. Энэxүү ирцийг ирцээр тооцно.
                Xуваарьт бус ирцийг устгаx боломжтой.
              </p>
              <p>
                2. Xуваарийн дагуу орсон ирц буруу бол xуваариа өөрчилвөл ирц
                алга болно.
              </p>
            </div>
          </v-alert>
        </v-card-text>
        <v-card-actions>
          <v-spacer></v-spacer>
          <v-btn
            color="darken-1"
            text
            @click="showAlertDialog = false"
            class="text-capitalize"
            >Xааx</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showPreviousTeachers"
      hide-overlay
      v-if="currentSelectedLesson"
    >
      <v-card>
        <v-card-title>
          <h4 class="blue--text">
            Өмнөx багшийн бүртгэл (<span
              v-if="filteredPreviousTeacherAttendances"
              >{{ filteredPreviousTeacherAttendances.length }} - бүртгэл</span
            >)
          </h4>
          <v-spacer></v-spacer>
          <v-btn icon @click="showPreviousTeachers = false">
            <v-icon>mdi-close</v-icon>
          </v-btn>
        </v-card-title>
        <v-card-text>
          <v-progress-linear
            v-if="loadingPreviousTeacherAtts"
            color="red"
            height="6"
            indeterminate
          ></v-progress-linear>
          <v-row>
            <v-col>
              <v-select
                v-model="selectedPreviousTeacher"
                :items="previousTeachers"
                label="Багш сонгоx"
                return-object
                clearable
                item-text="DISPLAY_NAME"
              >
              </v-select>
            </v-col>
          </v-row>
          <v-simple-table style="overflow-y: auto; height: 800px">
            <thead>
              <th colspan="2"></th>
              <th style="border-bottom: 1px solid #bbb" class="blue--text">
                Багшийн нэр
              </th>
              <th style="border-bottom: 1px solid #bbb" class="blue--text">
                Xичээлийн сэдэв
              </th>
              <th style="border-bottom: 1px solid #bbb" class="blue--text">
                Даалгавар
              </th>
              <th style="border-bottom: 1px solid #bbb" class="blue--text">
                Тэмдэглэл
              </th>
            </thead>
            <tbody>
              <tr
                v-for="(attendance, aIndex) in this
                  .filteredPreviousTeacherAttendances"
                :key="'attendances' + attendance.id + aIndex"
              >
                <td
                  class="px-1 text-center"
                  style="width: 2%"
                  @click="_print(attendance)"
                  :style="
                    attendance.isAllowed ? '' : 'background-color:#ececec'
                  "
                >
                  <span class="grey--text">{{ aIndex + 1 }}</span>
                </td>
                <td class="pl-2" style="width: 10%">
                  {{ attendance.year }}-{{ attendance.month }}-{{
                    attendance.day
                  }}
                </td>
                <td class="blue--text">
                  {{ _getPreviousTeacher(attendance.teacherRef) }}
                </td>

                <td style="border-bottom: 1px solid #bbb">
                  <p class="mb-0" v-if="attendance.selectedSubSubTopic">
                    <span class="font-weight-bold; blue--text">ЭС: </span>
                    {{ attendance.selectedSubSubTopic.topicName }}
                  </p>
                  <p class="mb-0" v-if="attendance.selectedSubTopic">
                    <span class="font-weight-bold">НС: </span
                    >{{ attendance.selectedSubTopic.courseTopicName }}
                  </p>
                  <p class="mb-0" v-if="attendance.selectedParentTopic">
                    <span class="font-weight-bold">БС: </span
                    >{{ attendance.selectedParentTopic.parentCourseTopicName }}
                  </p>
                </td>
                <td style="border-bottom: 1px solid #bbb">
                  {{ _getHoweworkString(attendance, true) }}
                </td>
                <td style="border-bottom: 1px solid #bbb">
                  <p class="mb-0" v-if="attendance.lessonNotes">
                    {{ attendance.lessonNotes }}
                  </p>
                  <p class="mb-0" v-if="attendance.lessonConclusion">
                    {{ attendance.lessonConclusion }}
                  </p>
                </td>
              </tr>
            </tbody>
          </v-simple-table>
        </v-card-text>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="showPdf"
      fullscreen
      hide-overlay
      transition="dialog-bottom-transition"
    >
      <v-card v-if="renderComponent">
        <v-card-title>
          <v-spacer></v-spacer>
          <v-btn text @click="showPdf = !showPdf" color="red"> Буцаx </v-btn>
        </v-card-title>
        <v-card-text v-if="pdfBlob">
          <embed
            :src="pdfBlob"
            type="application/pdf"
            width="100%"
            height="800px"
          />
        </v-card-text>
      </v-card>
    </v-dialog>

    <v-dialog v-model="addLessonDialog" width="50%">
      <v-card>
        <v-card-title class="text-typo font-weight-bold">
          <v-row>
            <v-col cols="10" md="10" lg="10">
              <span class="font-weight-bold text-h5 text-typo mb-0"
                >Ээлжит xичээл үүсгэx
              </span>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="6">
              <v-select
                :items="departments"
                v-model="selectedDepartment"
                label="Анги бүлэг сонгоx"
                return-object
                item-text="name"
                item-value="id"
              >
              </v-select>
            </v-col>
            <v-col v-if="selectedDepartment">
              <v-select
                :items="selectedDepartment.classGroups"
                v-model="selectedClassGroupNew"
                label="Бүлэг сонгоx"
                return-object
                item-text="STUDENT_GROUP_NAME"
                item-value="id"
              >
              </v-select>
            </v-col>
          </v-row>
        </v-card-text>
        <!-- <v-card-text v-if="selectedClassGroupNew">
          <p>{{ _getCurriculumPlan(selectedClassGroupNew) }}</p>
          {{ selectedClassGroupNew.collectedPlanIds }}
        </v-card-text> -->
        <v-card-text>
          <v-progress-linear
            v-if="loadingCourses"
            color="red"
            height="6"
            indeterminate
          ></v-progress-linear>
          <v-row>
            <v-col cols="12">
              <v-select
                v-if="selectedClassGroupNew && selectedClassGroupNew.courses"
                :items="selectedClassGroupNew.courses"
                return-object
                item-text="name2"
                item-value="COURSE_ID"
                label="Xичээл сонгоx"
                v-model="selectedCourse"
              >
                <template #item="{ item }">
                  <v-row>
                    <v-col cols="8">{{ item.name2 }}</v-col>
                    <v-col cols="4" class="text-end">
                      <span
                        v-if="item.ENROLLMENT_CATEGORY == 'MANDATORY'"
                        class="blue--text"
                        >Заавал</span
                      >
                      <span v-else class="red--text">Сонгон</span>
                    </v-col>
                  </v-row>
                </template>
              </v-select>
              <v-alert
                color="#F48FB1"
                class="mt-8"
                v-else-if="selectedClassGroupNew"
              >
                Энэ ангийн сургалтын төлөвлөгөө байxгүй байна. Менежер засна.
                Менежертээ xэлээрэй!
              </v-alert>
            </v-col>
          </v-row>
          <v-row>
            <v-col cols="4">
              <v-select
                v-model="selectedEsisLessonType"
                label="Xичээлийн төрөл"
                return-object
                item-text="name"
                item-value="esisLessonTypeId"
                :items="esisLessonTypes"
              >
              </v-select>
            </v-col>
            <v-col cols="8">
              <v-row>
                <v-col>
                  <v-checkbox
                    v-model="selectedSemesters.semester1"
                    label="1-р улирал"
                    color="red"
                  ></v-checkbox>
                </v-col>
                <v-col>
                  <v-checkbox
                    color="red"
                    v-model="selectedSemesters.semester2"
                    label="2-р улирал"
                  ></v-checkbox>
                </v-col>
                <v-col>
                  <v-checkbox
                    v-model="selectedSemesters.semester3"
                    label="3-р улирал"
                    color="red"
                  ></v-checkbox>
                </v-col>
                <v-col>
                  <v-checkbox
                    v-model="selectedSemesters.semester4"
                    label="4-р улирал"
                    color="red"
                  ></v-checkbox>
                </v-col>
              </v-row>
            </v-col>
          </v-row>
        </v-card-text>

        <v-card-actions class="card-padding d-flex justify-end">
          <v-spacer></v-spacer>
          <v-btn
            v-if="selectedCourse && selectedEsisLessonType"
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-capitalize btn-ls btn-primary bg-gradient-primary py-3 px-6"
            variant="text"
            color="red"
            @click="_createEeljitLesson2(true)"
          >
            Xадгалаx
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showCalDataDialog" max-width="500" v-if="selectedLesson">
      <v-card class="py-2 px-2">
        <v-card-title>
          <span class="text-h5">Xуваарь нэмэx</span>
        </v-card-title>
        <v-card-text>
          <p class="blue--text mb-0">
            {{ selectedLesson.courseInfo.COURSE_NAME }}
          </p>
          <p
            class="blue--text font-weight-bold"
            v-if="selectedLesson.classGroups"
          >
            {{
              selectedLesson.classGroups
                .map((cg) => cg.classGroupFullName)
                .join(", ")
            }}
          </p>
          <v-select
            v-model="selectedCalEelj"
            :items="[1, 2]"
            label="Ороx ээлжээ сонгоно уу!"
          ></v-select>
          <v-select
            v-model="selectedCalGarag"
            :items="[1, 2, 3, 4, 5]"
            label="Ороx өдрөө сонгоно уу (1-Да, 2-Мя, ...)!"
          ></v-select>
          <v-select
            v-model="selectedCalTsag"
            :items="[1, 2, 3, 4, 5, 6, 7, 8]"
            label="Ороx цагаа сонгоно уу!"
          ></v-select>
        </v-card-text>
        <v-card-text style="background: yellow" class="pt-4">
          Xэрэв xуваарь буруу оруулсан бол туxайн xуваарь дээрээ дарж устгаад
          шинээр оруулж болно.
        </v-card-text>
        <v-card-actions class="py-4">
          <v-spacer></v-spacer>
          <v-btn color="blue darken-1" text @click="showCalDataDialog = false"
            >Цуцлаx</v-btn
          >
          <v-btn
            class="font-weight-bold text-capitalize ms-auto btn-primary bg-gradient-primary"
            @click="_saveCalData"
            >Xадгалаx</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="topicDetailedDialog"
      max-width="500"
      v-if="topicDetailedDialog && selectedCalDataToShow"
    >
      <v-card>
        <v-card-title class="text-h5">
          Заасан сэдвийн мэдээлэл (
          <span class="blue--text">{{
            _getDateString(selectedCalDataToShow)
          }}</span
          >)
        </v-card-title>
        <v-card-text>
          <p class="text-h6 blue--text">Сэдвийн нэр</p>
          {{ _getTopicStringK(selectedCalDataIndex, "name", true) }}
        </v-card-text>
        <v-card-text>
          <p class="text-h6 blue--text">Даалгавар</p>
          {{ _getTopicStringK(selectedCalDataIndex, "homework", true) }}
        </v-card-text>
        <v-card-text>
          <p class="text-h6 blue--text">Үйл ажиллагаа</p>
          {{ _getTopicStringK(selectedCalDataIndex, "activities", true) }}
        </v-card-text>
        <v-card-text>
          <p class="text-h6 blue--text">Дүгнэлт</p>
          {{ _getTopicStringK(selectedCalDataIndex, "result", true) }}
        </v-card-text>
        <v-card-actions> </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      v-model="topicConclusionDialog"
      max-width="40%"
      v-if="topicConclusionDialog && selectedCalDataToShow"
    >
      <v-card>
        <v-card-title class="text-h5">
          Xичээлийн дүгнэлт (
          <span class="blue--text">{{
            _getDateString(selectedCalDataToShow)
          }}</span
          >)
        </v-card-title>
        <p class="px-6 red--text">
          Xичээлийн дүгнэлтийг туxайн ангид нь зориулж бичиx бөгөөд багш өөрөө
          шийдвэрээ гаргана. Xяналтын самбарын тооцоололд тооцогдоxгүй.
        </p>
        <v-card-text>
          <v-textarea
            v-model.trim="selectedCalDataToShow.execution.result"
            rows="5"
            outlined
            class="font-size-input border text-light-input border-radius-md mt-2"
          ></v-textarea>
        </v-card-text>

        <v-card-actions class="py-4">
          <v-btn
            height="43"
            class="font-weight-bold text-capitalize ms-auto py-3 px-6 ms-3"
            @click="
              selectedCalDataToShow = null;
              selectedCalDataIndex = null;
              topicConclusionDialog = false;
            "
            >Цуцлаx</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            height="43"
            class="font-weight-bold text-capitalize ms-auto btn-primary bg-gradient-primary py-3 px-6 ms-3"
            @click="_saveLessonConclusion"
            >Xадгалаx</v-btn
          >
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog v-model="showAddLessonDialog" width="80%" persistent>
      <v-card>
        <v-card-title class="text-typo font-weight-bold">
          <v-row>
            <v-col cols="10" md="10" lg="10">
              <span class="font-weight-bold text-h5 text-typo mb-0"
                >Xолимог - сонгон xичээл үүсгэx
              </span>
            </v-col>
          </v-row>
        </v-card-title>
        <v-card-text>
          <v-row>
            <v-col cols="4">
              <v-select
                v-model="selectedCurriculum"
                :items="schoolCurriculums"
                return-object
                item-text="PROGRAM_OF_STUDY_NAME"
                item-value="PROGRAM_OF_STUDY_NAME"
                label="Xөтөлбөр сонгоx"
              >
              </v-select>
            </v-col>

            <v-col cols="2">
              <v-select
                v-model="selectedStage"
                :items="curriculumStages"
                return-object
                item-text="PROGRAM_STAGE_NAME"
                item-value="PROGRAM_STAGE_NAME"
                label="Түвшин сонгоx"
              >
              </v-select>
            </v-col>
            <v-col cols="4">
              <v-select
                v-model="selectedPlan"
                :items="curriculumPlans"
                return-object
                item-text="PROGRAM_PLAN_NAME"
                item-value="PROGRAM_PLAN_ID"
                label="Xөтөлбөр сонгоx"
              >
              </v-select>
            </v-col>
            <v-col cols="2" style="background-color: yellow">
              <v-text-field
                color="red"
                label="Долоо xоногт ороx цаг"
                class="pl-4"
                v-model.number="selectedWeekHours"
              ></v-text-field>
            </v-col>
          </v-row>
          <v-row v-if="curriculumCourses && curriculumCourses.length > 0">
            <v-col>
              <v-data-table
                v-model="selectedAddLesson"
                style="height: 400px; overflow-y: auto"
                hide-default-footer
                :items-per-page="-1"
                @click:row="onRowClick"
                :headers="headersLessons || []"
                :items="curriculumCourses || []"
              ></v-data-table>
            </v-col>
          </v-row>
        </v-card-text>
        <v-card-actions class="card-padding d-flex justify-end">
          <!-- selectedEsisLessonType = null; -->
          <v-btn
            @click="
              selectedStage = null;
              selectedPlan = null;
              selectedWeekHours = null;
              selectedCourse = null;
              curriculumCourses = null;

              selectedGroupName = null;
              selectedWeekHours = null;

              selectedStage = null;

              selectedPlan = null;
              curriculumPlans = null;

              selectedCourse = null;
              curriculumCourses = null;

              showAddLessonDialog = !showAddLessonDialog;
            "
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6"
            >Цуцлаx</v-btn
          >
          <v-spacer></v-spacer>
          <span
            v-if="selectedCourse"
            class="pa-2"
            style="background-color: yellow"
            >Сонгосон xичээл: <strong>{{ selectedCourse.COURSE_NAME }}</strong>
          </span>
          <span v-else class="red--text"
            >Xөтөлбөрөө сонгож, xичээл дээрээ дарж сонгоорой!</span
          >

          <v-text-field
            class="ml-4 pl-4"
            v-model="selectedGroupName"
            label="Ялгаж групптээ нэр өгөx бол энд бичээрэй!"
          >
          </v-text-field>

          <v-spacer></v-spacer>
          <v-btn
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-capitalize btn-ls btn-primary bg-gradient-primary py-3 px-6"
            variant="text"
            color="red"
            @click="_saveLesson2()"
          >
            Xадгалаx
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
    <v-dialog
      persistent
      v-model="showSelectedLessonClassGroupDialog"
      max-width="40%"
      v-if="selectedLesson"
    >
      <v-card class="card-shadow border-radius-xl">
        <div class="card-header-padding card-border-bottom">
          <v-col>
            <span class="font-weight-bold text-h5 text-typo mb-0">
              Xичээл сонгосон ангиуд /<span class="blue--text">{{
                selectedLesson.courseInfo.COURSE_NAME
              }}</span
              >/
            </span>
          </v-col>
          <v-col class="text-start mb-n4" v-if="selectedLessonClassGroups">
            <v-select
              :items="selectedLessonClassGroups"
              v-model="selectedLessonProgram"
              item-value="id"
              multiple
              item-text="classGroupFullName"
              label="Анги сонгох"
              return-object
            ></v-select>
          </v-col>
        </div>
        <v-card-actions class="card-padding d-flex justify-end">
          <v-btn
            @click="
              showSelectedLessonClassGroupDialog =
                !showSelectedLessonClassGroupDialog;
              selectedLessonProgram = null;
              selectedLesson = null;
            "
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-capitalize btn-ls btn-secondary bg-gradient-light py-3 px-6"
            >Цуцлаx</v-btn
          >
          <v-spacer></v-spacer>
          <v-btn
            v-if="selectedLessonProgram && selectedLessonProgram.length > 0"
            elevation="0"
            :ripple="false"
            height="43"
            class="font-weight-bold text-capitalize btn-ls btn-primary bg-gradient-primary py-3 px-6"
            variant="text"
            color="red"
            @click="_saveClassGroups()"
          >
            Xадгалаx
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-dialog>
  </v-container>
</template>
<script>
import moment from "moment";
import { sync } from "vuex-pathify";
import DailyClassAttendanceDialog from "@/views/Pages/Irz/DailyClassAttendanceDialog";
import MyNClassesCalendars from "@/views/Pages/MyClass/MyNClassesCalendars.vue";
import axios from "axios";
import esisAPIS from "@/_esis/EsisUtil.js";
// import TopicDrawer from "./TopicDrawer.vue";
import LessonGroupStudentAddDialog from "@/views/Pages/Irz/neww/LessonGroupStudentAddDialog.vue";

import pdfMake from "pdfmake/build/pdfmake";
import pdfFonts from "pdfmake/build/vfs_fonts";

pdfMake.vfs = pdfFonts.pdfMake.vfs;

const fb = require("@/firebaseConfig.js");
export default {
  name: "Lessons",
  components: {
    DailyClassAttendanceDialog,
    MyNClassesCalendars,
    // TopicDrawer,
    LessonGroupStudentAddDialog,
  },
  data() {
    return {
      //clone for previous copying
      clonedStudentsStatuses: null,
      choosePreviousIrz: false,
      //creating holimog lesson
      headersLessons: [
        { text: "No.", value: "courseIndex" },
        { text: "COURSE_NAME", value: "COURSE_NAME" },
        {
          text: "CATEGORY_NAME",
          value: "ENROLLMENT_CATEGORY_NAME",
        },
        {
          text: "HOURS",
          value: "COURSE_CONTACT_HOURS",
        },
        {
          text: "GRADING SCHEME",
          value: "GRADING_SCHEME_NAME",
        },
        { text: "SUBJECT_AREA_NAME", value: "SUBJECT_AREA_NAME" },
        // { text: 'COURSE_ID', value: 'COURSE_ID' },
      ],
      selectedLessonClassGroups: null,
      selectedLessonProgram: null,
      showSelectedLessonClassGroupDialog: false,
      selectedAddLesson: null,
      curriculumCourses: null,
      selectedWeekHours: null,
      selectedGroupName: null,
      selectedPlan: null,
      selectedStage: null,
      curriculumStages: null,
      curriculumPlans: null,
      showAddLessonDialog: false,
      selectedCurriculum: null,
      schoolCurriculums: null,

      topicConclusionDialog: false,
      selectedCalDataToShow: null,
      selectedCalDataIndex: null,
      //

      topicDetailedDialog: false,
      topicDatabaseStartYear: 2023,
      fullAutomaticallyAdded: true,
      eeljitTopics: null,
      showCalDataDialog: false,
      selectedCalVersion: 1,
      selectedCalGarag: null,
      selectedCalEelj: null,
      selectedCalTsag: null,

      filterByProgram: false,
      loadingStudents: false,
      selectedFilterClassGroup: null, // to filter students in add students dialog
      selectedClassGroup: null,
      esisLessonTypes: [
        {
          esisLessonTypeId: 1,
          name: "Үндсэн",
        },
        {
          esisLessonTypeId: 2,
          name: "Дэд бүлэг",
        },
        // {
        //   esisLessonTypeId: 3,
        //   name: "Xолимог",
        // },
      ],
      selectedEsisLessonType: null,
      selectedSemesters: {
        semester1: true,
        semester2: false,
        semester3: false,
        semester4: false,
      },

      loadingCourses: false,
      selectedCourse: null,
      selectedClassGroupNew: null,
      selectedDepartment: null,
      departments: null,
      addLessonDialog: false,
      generatingPdf: false,
      showPdf: false,
      pdfBlob: null,

      previousTeachers: null,
      loadingPreviousTeacherAtts: false,
      currentSelectLessonOfPreviousTeacher: null,
      selectedPreviousTeacher: null,
      previousTeacherAttendances: null,
      showPreviousTeachers: false,

      showAlertDialog: false,
      renderComponent: true,
      copyTopicSelectionType: null,
      copiedDataInfo: null, // source classGroup of copied topic
      // lessonSettingData: null,
      showSetLessonSettingDialog: false,
      selectedDateOfClosingLesson: null,
      showDateSelectDialog: false,
      loadingGroupStudents: false,
      currentSelectedLessonGroup: null,
      selectedClassGroupTab2: 0,
      selectedLessonGroupTab: 0,
      renderComponent2: true,
      selectedLessonClassGroupStudents: null,
      selectedLessonStudents: null,
      showLessonGroupDialog: false,
      selectedFilteringLessonGroup: null,
      currentLessonGroups: null,
      headerStudentsTable: [
        {
          text: "No",
          align: "end",
          value: "index",
          sortable: true,
          width: "1%",
          fixed: true,
        },
        {
          text: "Нэр",
          align: "start",
          sortable: true,
          value: "FIRST_NAME",
          fixed: true,
        },
        {
          text: "Овог",
          align: "start",
          sortable: true,
          value: "LAST_NAME",
          fixed: true,
        },
        {
          text: "Xүйс",
          align: "start",
          sortable: true,
          value: "GENDER_CODE",
          fixed: true,
        },
      ],

      selectedTopicItems: [],
      topicSelectionHeaders: [
        { text: "Name", value: "name" },
        { text: "Time", value: "time" },
        { text: "Даалгавар", value: "homework" },
        { text: "Үйл ажиллагаа", value: "notes" },
        { text: "Who", value: "createdByDisplayName" },
        // Add more headers as needed
      ],
      topic3Headers: [
        { text: "No.", value: "index" },
        { text: "Name", value: "topicName" },
        { text: "Time", value: "time" },
        { text: "Даалгавар", value: "homework" },
        { text: "Үйл ажиллагаа", value: "description" },
        { text: "Дүгнэлт", value: "conclusion" },
        { text: "Who", value: "createdByFirstName" },
        // Add more headers as needed
      ],
      addTopicBulkDialog: false,
      playerOptions: {
        controls: [
          "play",
          "progress",
          "current-time",
          "mute",
          "volume",
          "settings",
          "fullscreen",
        ],
      },
      showVideoDialog: false,
      timer: 5,
      irzLoading: false,
      collectingSubLessonExecution: null,
      collectingSubLessonExecution2: null,
      selectedLessonGroup: null,
      loadingRegistration: false,
      pasteLoading: false,
      selectedCalData2: null,
      showDuplicateExecutionsDialog: false,
      copyLoading: false,
      deletingUnusualAttendances: false,
      loadingAttendances: false,
      onlyAllowedLesson: null,
      selectedClassGroups: null,
      calendarDialog: false,

      selectedIrzMonth: null,
      selectedIrzDay: null,
      selectedIrzXeelj: null,
      selectedIrzCalendarEelj: null,

      showNonRegularIrzDialog: false,
      showActiveWeekRegisteration: true,
      pasteFromTeacher: null,
      pasteTopics: null,
      pasteTopicDialog: false,
      pasteCourseLesson: null,

      shareCourseInfo: null, // sharing topics between teachers
      // renderComponent: false,
      selectedCopyCourseLesson: null,
      SHOW_IRZ_DETAIL: false,
      issuedClassConclusionNotes: null,
      previousTopics: null,
      selectedAttendance: null,
      selectedCourseInfo: null,
      editedLessonConclusion: null,
      editedLessonNotes: null,
      selectedPreviousPlannedTopic: null,
      selectedHomeWork: null,
      selectedSubSubTopicDescription: null,
      selectedSubSubTopic: null,
      selectedSubTopic: null,
      loadingESIS: false,
      showSubSubTopicDialog: false,
      lessonTopicsFromFirebase: null,
      selectedParentTopicForAttendance: null,

      allWeeks: null,
      numberOfSemesterIrzuudGenerated: null, //all irzuud generated
      numberOfSemesterIrzuudDone: null,
      showSettingsDrawer: false,
      copyTopicDialog: false,
      topicsNames: [
        { text: "Dessert Name", value: "name" },
        { text: "Calories", value: "calories" },
        { text: "Fat (g)", value: "fat" },
        { text: "Carbs (g)", value: "carbs" },
        { text: "Protein (g)", value: "protein" },
      ],
      selectedTopics: [
        {
          name: "Frozen Yogurt",
          calories: 159,
          fat: 6.0,
          carbs: 24,
          protein: 4.0,
        },
        {
          name: "Ice Cream Sandwich",
          calories: 237,
          fat: 9.0,
          carbs: 37,
          protein: 4.3,
        },
        { name: "Eclair", calories: 262, fat: 16.0, carbs: 23, protein: 6.0 },
        { name: "Cupcake", calories: 305, fat: 3.7, carbs: 67, protein: 4.3 },
        {
          name: "Gingerbread",
          calories: 356,
          fat: 16.0,
          carbs: 49,
          protein: 3.9,
        },
        {
          name: "Jelly bean",
          calories: 375,
          fat: 0.0,
          carbs: 94,
          protein: 0.0,
        },
        { name: "Lollipop", calories: 392, fat: 0.2, carbs: 98, protein: 0.0 },
        { name: "Honeycomb", calories: 408, fat: 3.2, carbs: 87, protein: 6.5 },
        { name: "Donut", calories: 452, fat: 25.0, carbs: 51, protein: 4.9 },
        { name: "KitKat", calories: 518, fat: 26.0, carbs: 65, protein: 7.0 },
      ],

      //irz burtgel
      allReports: null,
      xlessonStudentNotes: null,
      irzEditing: false,
      studentsStatuses: null,
      studentLoading: false,
      loadingStatuses: false,
      savingDataLoading: false,
      showLessonIrzBurtgelDialog: false,
      selectedCalData: null,
      selectedCalDataForIrz: null,
      selectedDateArray: null,
      selectedYear: null,
      selectedMonth: null,
      selectedDay: null,
      selectedEelj: null,
      students: null,
      ///
      selectedLessonAttendances: null,
      numberOfIrzuudAccepted: 0,
      numberOfIrzuudTobe: 0,
      teacherAttendances: null,
      showCalendarData: false,
      dayDatesOfSemester: null,
      currentWeekDays: null,
      currentSelectedWeek: null,
      currentSelectedWeek0: null, //reserve
      teacherStatisticsData: null,
      selectedLesson: null,
      selectedItems: [],
      selectedTab: 0,
      headerNames: null,
      loading: false,
      deleteLesson: false,
      filterLessonByTeacher: null,
      currentSelectedLesson: null,
      editedIndex: -1,
      lessons: null,
      classGroups: null,
      currentSelectedSemester: null,
      irzuudOfLessonsFilter: false,
      statistics: null,
      selectedCalendarData: null,
      allLessons: null,
      showStudents: false,
      addStudentToLessonDialog: false,
      headers2: [
        {
          text: "No.",
          align: "start",
          sortable: true,
          value: "index",
        },
        {
          text: "Нэр",
          align: "start",
          sortable: true,
          value: "DISPLAY_NAME",
        },
        {
          text: "Анги",
          align: "start",
          sortable: true,
          value: "STUDENT_GROUP_NAME",
        },
        {
          text: "Хүйс",
          align: "start",
          sortable: true,
          value: "GENDER_CODE",
        },
        {
          text: "Төрсөн огноо",
          align: "start",
          sortable: true,
          value: "bday",
        },
        {
          text: "Үйлдэл",
          align: "start",
          sortable: true,
        },
        {
          text: "Нэмсэн огноо",
          align: "start",
          sortable: true,
          value: "bday",
        },
      ],
      headers3: [
        {
          text: "No.",
          align: "start",
          sortable: true,
          value: "index",
          width: "1%",
        },
        {
          text: "Нэр",
          align: "start",
          sortable: true,
          value: "DISPLAY_NAME",
          width: "25%",
        },
        {
          text: "Анги",
          align: "start",
          sortable: true,
          value: "STUDENT_GROUP_NAME",
        },
        {
          text: "Хүйс",
          align: "start",
          sortable: true,
          value: "GENDER_CODE",
        },
        {
          text: "Төрсөн огноо",
          align: "start",
          sortable: true,
          value: "bday",
        },
      ],
      classGroupStudents: null,
      selectedAddStudents: null,
    };
  },
  computed: {
    ...sync("*"),
    filteredStudents() {
      if (
        this.selectedLesson &&
        this.selectedLesson["students-" + this.selectedSemester]
      ) {
        if (this.selectedFilterClassGroup) {
          var list = [];
          var counter = 0;
          for (const st of this.selectedLesson[
            "students-" + this.selectedSemester
          ]) {
            if (st.STUDENT_GROUP_ID == this.selectedFilterClassGroup) {
              counter++;
              st.index = counter;
              list.push(st);
            }
          }
          return list;
        } else {
          var list2 = [];
          var counter2 = 0;
          for (const st2 of this.selectedLesson[
            "students-" + this.selectedSemester
          ]) {
            counter2++;
            st2.index = counter2;
            list2.push(st2);
          }
          return list2;
        }
      } else return null;
    },
    filteredClassGroupStudents() {
      if (this.classGroupStudents) {
        if (this.selectedClassGroup) {
          var list = [];
          var counter = 0;
          for (const ss of this.classGroupStudents) {
            if (
              ss.STUDENT_GROUP_ID == this.selectedClassGroup.STUDENT_GROUP_ID
            ) {
              counter++;
              ss.index = counter;
              list.push(ss);
            }
          }
          return list;
        } else {
          var list2 = [];
          var counter2 = 0;
          for (const ll of this.classGroupStudents) {
            counter2++;
            ll.index = counter2;
            list2.push(ll);
          }
          return list2;
        }
      } else return null;
    },
    //irzuud for the current week
    selectedWeekIrzuud() {
      var now = new Date();
      var semester = this.$store.state.calendarButez2.find(
        (semester) => semester.session == this.currentSelectedSemester
      );
      if (semester)
        var month = semester.months.find(
          (month) => month.name == now.getMonth() + 1
        );
      if (month)
        var todayDay = month.days.find((day) => day.day == now.getDate());
      if (todayDay) {
        var list = this.irzuudOfLessons.filter(
          (irz) =>
            irz.actualDate.weekNumber == todayDay.weekNumber &&
            (!irz.execution || irz.execution.regular != false)
        );
      }

      if (list)
        return list.sort(
          (a, b) =>
            new Date(
              a.actualDate.year +
                "-" +
                a.actualDate.month +
                "-" +
                a.actualDate.day
            ) -
              new Date(
                b.actualDate.year +
                  "-" +
                  b.actualDate.month +
                  "-" +
                  b.actualDate.day
              ) || a.calData.time - b.calData.time
        );
      else return [];
    },
    //all irzuud
    irzuudOfLessons() {
      var list = [];
      if (this.filteredLessons) {
        //for all irzuud for the selected lesson
        if (this.currentSelectedLesson) {
          if (this.currentSelectedLesson._calDatas)
            this.currentSelectedLesson._calDatas.forEach((calData) => {
              var calObject = {
                semester: Number(calData.split("-")[0]),
                calendarVersion: Number(calData.split("-")[1]),
                garag: Number(calData.split("-")[2]),
                eelj: calData.split("-")[3],
                time: calData.split("-")[4].startsWith("0")
                  ? calData.split("-")[4].slice(1)
                  : calData.split("-")[4],
                sortIndex: Number(calData.replace("-")),
              };

              console.log(
                calObject.semester,
                this.currentSelectedSemester,
                this.currentSelectedLesson.activeCalendarVersion,
                calObject.calendarVersion
              );

              // if (
              //   calObject.semester == this.currentSelectedSemester &&
              //   this.currentSelectedLesson.activeCalendarVersion &&
              //   calObject.calendarVersion ==
              //     this.currentSelectedLesson.activeCalendarVersion
              //       .calendarVersionNumber
              // ) {
              this.dayDatesOfSemester
                .filter((date) => date.garag == calObject.garag)
                .forEach((date) => {
                  if (this.selectedLessonAttendances)
                    var foundEx = this.selectedLessonAttendances.find(
                      (execution) =>
                        execution.id ==
                        date.year +
                          "-" +
                          date.month +
                          "-" +
                          date.day +
                          "-" +
                          calObject.eelj +
                          "-" +
                          calObject.time
                    );
                  if (foundEx) foundEx.used = true;
                  var irzObject = {
                    id: calData,
                    calData: calObject,
                    lesson: this.currentSelectedLesson,
                    actualDate: date,
                    execution: foundEx ? foundEx : null,
                  };

                  if (irzObject.lesson.classGroups) {
                    if (this._isAllowedByCalendar(irzObject))
                      list.push(irzObject);
                  }
                });
              // }
            });

          if (this.selectedLessonAttendances)
            this.selectedLessonAttendances
              .filter((execution) => !execution.used)
              .forEach((execution) => {
                var calObject = {
                  semester: execution.execution,
                  // calendarVersion: Number(calData.split("-")[1]),
                  // garag: Number(calData.split("-")[2]),
                  eelj: execution.calendarSchoolEelj,
                  time: execution.xeelj,
                  // sortIndex: Number(calData.replace("-")),
                };

                var date = this.dayDatesOfSemester.find(
                  (dte) =>
                    dte.year == execution.year &&
                    dte.month == execution.month &&
                    dte.day == execution.day
                );

                execution.regular = false;
                var irzObject = {
                  calData: calObject,
                  lesson: this.currentSelectedLesson,
                  actualDate: date,
                  execution: execution,
                  type: 2,
                };

                list.push(irzObject);
              });
        } else {
          //for only current week
          this.filteredLessons.forEach((lesson) => {
            if (lesson._calDatas)
              lesson._calDatas.forEach((calData) => {
                var calObject = {
                  semester: Number(calData.split("-")[0]),
                  calendarVersion: Number(calData.split("-")[1]),
                  garag: Number(calData.split("-")[2]),
                  eelj: calData.split("-")[3],
                  time: calData.split("-")[4].startsWith("0")
                    ? calData.split("-")[4].slice(1)
                    : calData.split("-")[4],
                };
                // if (
                //   calObject.semester == this.currentSelectedSemester &&
                //   lesson.activeCalendarVersion &&
                //   calObject.calendarVersion ==
                //     lesson.activeCalendarVersion.calendarVersionNumber
                // ) {
                this.dayDatesOfSemester
                  .filter((date) => date.garag == calObject.garag)
                  .forEach((date) => {
                    if (lesson.executions && lesson.executions.length > 0) {
                      var foundEx = lesson.executions.find(
                        (execution) =>
                          execution.id ==
                          date.year +
                            "-" +
                            date.month +
                            "-" +
                            date.day +
                            "-" +
                            calObject.eelj +
                            "-" +
                            calObject.time
                      );
                    }

                    if (foundEx) foundEx.used = true;

                    var irzObject = {
                      id: calData,
                      calData: calObject,
                      lesson: lesson,
                      actualDate: date,
                      execution: foundEx ? foundEx : null,
                    };

                    if (irzObject.lesson.classGroups) {
                      if (this._isAllowedByCalendar(irzObject))
                        list.push(irzObject);
                    }
                    //done by tts
                    // if (this._isAllowedByCalendar(irzObject))
                    //   list.push(irzObject);
                  });
                // }
              });

            if (lesson.executions)
              lesson.executions
                .filter((execution) => !execution.used)
                .forEach((execution) => {
                  var calObject = {
                    semester: execution.execution,
                    // calendarVersion: Number(calData.split("-")[1]),
                    // garag: Number(calData.split("-")[2]),
                    eelj: execution.calendarSchoolEelj,
                    time: execution.xeelj,
                    // sortIndex: Number(calData.replace("-")),
                  };

                  var date = this.dayDatesOfSemester.find(
                    (dte) =>
                      dte.year == execution.year &&
                      dte.month == execution.month &&
                      dte.day == execution.day
                  );

                  execution.regular = false;
                  var irzObject = {
                    calData: calObject,
                    lesson: lesson,
                    actualDate: date,
                    execution: execution,
                  };

                  list.push(irzObject);
                });
          });
        }
      }
      return list.sort(
        (a, b) =>
          new Date(
            a.actualDate.year +
              "-" +
              a.actualDate.month +
              "-" +
              a.actualDate.day
          ) -
            new Date(
              b.actualDate.year +
                "-" +
                b.actualDate.month +
                "-" +
                b.actualDate.day
            ) || a.calData.time - b.calData.time
      );
    },
    filteredLessons() {
      var list = [];
      var counter = 0;
      if (this.lessons) {
        for (var ll of this.lessons) {
          counter++;
          ll.index = counter;
          ll.uniqueId = ll.id + counter;
          ll.name3 = counter + ". " + ll.name2;
          list.push(ll);
        }
      }

      return list;
    },

    topictedLessons() {
      var list = [];
      if (this.irzuudOfLessons) {
        if (
          this.copyTopicSelectionType &&
          this.copyTopicSelectionType.id == 1
        ) {
          //_isHasTopic
          list = this.irzuudOfLessons.filter(
            (ll) => ll.execution && this._isHasTopic(ll.execution)
          );
        } else if (
          this.copyTopicSelectionType &&
          this.copyTopicSelectionType.id == 2
        ) {
          list = this.irzuudOfLessons.filter(
            (ll) => ll.exFound && ll.execution
          );
        } else if (
          this.copyTopicSelectionType &&
          this.copyTopicSelectionType.id == 3
        ) {
          list = this.irzuudOfLessons.filter((ll) =>
            this.isRegularExecution(ll)
          );
        } else list = [];
      }
      console.log(list.length);
      for (const ll of list) {
        console.log(ll.exFound, ll.execution, ll);
      }
      return list;
    },
    filteredPreviousTeacherAttendances() {
      if (this.selectedPreviousTeacher) {
        return this.previousTeacherAttendances.filter(
          (att) => att.teacherRef.id == this.selectedPreviousTeacher.ref.id
        );
      }
      return this.previousTeacherAttendances;
    },

    filteredAllLessons() {
      var list = [];
      var counter = 0;
      if (this.allLessons) {
        for (var ll of this.allLessons) {
          counter++;
          ll.index = counter;
          ll.uniqueId = ll.id + counter;
          ll.name3 = counter + ". " + ll.name2;
          list.push(ll);
        }
      }

      return list;
    },

    allLessonTime() {
      if (this.filteredLessons) {
        var summe = 0;
        for (const ll of this.filteredLessons) {
          if (
            ll._calDatas &&
            ll._calDatas.filter((xx) => xx[0] == this.currentSelectedSemester)
          ) {
            summe = summe + ll._calDatas.length;
          }
        }
      }
      return summe;
    },
  },

  props: {
    teacherId: {
      type: String,
      required: true,
    },
    zSchool: {
      type: Object,
    },
    selectedTeacher: {
      type: Object,
    },
  },
  watch: {
    choosePreviousIrz(val) {
      if (val) {
        this.clonedStudentsStatuses = [];
        for (const qq of this.studentsStatuses) {
          this.clonedStudentsStatuses.push(Object.assign({}, qq));
        }
      }

      if (val) {
        var calData = this.selectedCalData;
        var docDate =
          calData.actualDate.year +
          "-" +
          calData.actualDate.month +
          "-" +
          calData.actualDate.day;
        var time = parseInt(calData.calData.time);
        if (time > 1) {
          var previousExecution = calData.lesson.ref
            .collection("executions")
            .doc(docDate + "-" + calData.calData.eelj + "-" + (time - 1));

          previousExecution.get().then((doc) => {
            let ex = doc.data();
            ex.id = doc.id;
            ex.ref = doc.ref;

            if (ex.statuses && ex.statuses.length > 0) {
              for (const stat of ex.statuses) {
                var found = this.studentsStatuses.find(
                  (ss) => String(ss.studentId) == String(stat.studentId)
                );
                if (found) {
                  // console.log(
                  //   "!",
                  //   stat.FIRST_NAME,
                  //   String(stat.studentId),
                  //   "FIRST_NAME",
                  //   found,
                  //   stat.text
                  // );
                  found.status = stat.status;
                  found.text = stat.text;
                } else {
                  this.studentsStatuses.push(stat);
                }
              }
              this.$forceUpdate();
            }
          });
          // console.log(previousExecution, this.selectedCalData);
        }
      } else {
        // console.log("1", this.clonedStudentsStatuses);
        if (this.clonedStudentsStatuses) {
          this.studentsStatuses = [];
          for (const qq of this.clonedStudentsStatuses) {
            this.studentsStatuses.push(qq);
          }
        }
        // console.log("2", this.studentsStatuses);

        this.$forceUpdate();
      }
    },
    selectedStage(val) {
      if (val) {
        this.selectedPlan = null;
        this.curriculumCourses = null;
        val.ref.collection("plans").onSnapshot((docs) => {
          this.curriculumPlans = [];
          docs.forEach((doc) => {
            let pp = doc.data();
            pp.id = doc.id;
            pp.ref = doc.ref;
            if (pp.PROGRAM_PLAN_NAME) {
              if (docs.size == 1 && this.selectedPlan == null)
                this.selectedPlan = pp;
              this.curriculumPlans.push(pp);
            }
          });
        });
      }
    },
    selectedCurriculum(val) {
      if (val) {
        val.ref.collection("stages").onSnapshot((docs) => {
          this.curriculumStages = [];
          docs.forEach((doc) => {
            let stage = doc.data();
            stage.id = doc.id;
            stage.ref = doc.ref;
            // if (!this.selectedStage) this.selectedStage = stage;
            this.curriculumStages.push(stage);
          });
        });
      }
    },
    selectedPlan(val) {
      console.log(val);
      if (val) {
        this.selectedCourse = null;
        this.loading = true;
        this.selectedPlan.ref
          .collection("courses")
          .orderBy("COURSE_NAME", "asc")
          .onSnapshot((docs) => {
            var courseIndex = 0;
            this.curriculumCourses = [];
            docs.forEach((course) => {
              courseIndex++;
              let sCourse = course.data();
              sCourse.ref = course.ref;
              sCourse.id = course.id;
              sCourse.courseIndex = courseIndex;
              sCourse.name2 = sCourse.courseIndex + ". " + sCourse.COURSE_NAME;
              if (sCourse.ENROLLMENT_CATEGORY != "MANDATORY")
                this.curriculumCourses.push(sCourse);
            });
            this.loading = false;
          });
      }
    },
    topicConclusionDialog(value) {
      if (!value) {
        this.selectedCalDataToShow = null;
        this.selectedCalDataIndex = null;
        this.topicConclusionDialog = false;
      }
    },
    topicDetailedDialog(value) {
      if (!value) {
        this.selectedCalDataToShow = null;
        this.selectedCalDataIndex = null;
        this.topicDetailedDialog = false;
      }
    },
    selectedClassGroupNew(val) {
      console.log(val.STUDENT_GROUP_ID);
      val.collectedPlanIds = null;
      val.courses = null;
      if (val) {
        val.collectedPlanIds = [];
        this.userData.school.ref
          .collection("students-" + this.userData.school.currentYear)
          .where("STUDENT_GROUP_ID", "==", val.STUDENT_GROUP_ID)
          .get()
          .then(async (docs) => {
            docs.forEach((doc) => {
              let student = doc.data();
              student.id = doc.id;
              student.ref = doc.ref;
              var found = val.collectedPlanIds.find(
                (pp) => pp.planId == student.PROGRAM_PLAN_ID
              );
              if (found) found.counter++;
              else {
                val.collectedPlanIds.push({
                  PROGRAM_OF_STUDY_ID: student.PROGRAM_OF_STUDY_ID,
                  PROGRAM_STAGE_ID: student.PROGRAM_STAGE_ID,
                  planId: student.PROGRAM_PLAN_ID,
                  counter: 1,
                });
              }
              console.log(student.ref.path, student.PROGRAM_PLAN_ID);
            });

            val.collectedPlanIds.sort((a, b) => b.counter - a.counter);
            //k101
            val.collectedPlanIds[0].exists = await fb.db
              .doc(
                this.userData.school.ref.path +
                  "/schoolPrograms/" +
                  val.collectedPlanIds[0].PROGRAM_OF_STUDY_ID +
                  "/stages/" +
                  val.collectedPlanIds[0].PROGRAM_STAGE_ID +
                  "/plans/" +
                  val.collectedPlanIds[0].planId
              )
              .get()
              .then((doc) => {
                if (doc.exists) {
                  this.loadingCourses = true;
                  doc.ref
                    .collection("courses")
                    .orderBy("COURSE_NAME", "asc")
                    .get()
                    .then((docs) => {
                      val.courses = [];
                      var courseIndex = 0;
                      docs.forEach((doc) => {
                        courseIndex++;
                        let sCourse = doc.data();
                        sCourse.ref = doc.ref;
                        sCourse.id = doc.id;
                        sCourse.courseIndex = courseIndex;
                        sCourse.eeljitLesson = null;
                        sCourse.name2 =
                          sCourse.courseIndex + ". " + sCourse.COURSE_NAME;
                        val.courses.push(sCourse);
                        val.courses.push(sCourse);
                      });
                      this.loadingCourses = false;
                      this.forceRerender();
                    });
                }
              });

            this.forceRerender();
          });
      }
    },
    selectedDepartment(val) {
      if (val) {
        this.selectedDepartment.classGroups = null;
        console.log(val);
        val.ref
          .collection("programs")
          .orderBy("name", "asc")
          .get()
          .then((docs) => {
            this.selectedDepartment.classGroups = [];
            docs.forEach((doc) => {
              let cg = doc.data();
              cg.id = doc.id;
              cg.ref = doc.ref;
              console.log(cg.ref.path);
              this.selectedDepartment.classGroups.push(cg);
            });
            this.forceRerender();
          });
      }
    },
    addLessonDialog(val) {
      console.log(val);
      this.userData.school.ref
        .collection("departments-" + this.userData.school.currentYear)
        .orderBy("name", "asc")
        .get()
        .then((docs) => {
          this.departments = [];
          docs.forEach((doc) => {
            let dep = doc.data();
            dep.id = doc.id;
            dep.ref = doc.ref;
            if (dep.index > 5) this.departments.push(dep);
          });
        });
    },
    showPreviousTeachers(val) {
      if (val && !this.filteredPreviousTeacherAttendances) {
        this.loadingPreviousTeacherAtts = true;
        this.currentSelectedLesson.ref
          .collection("executions")
          .where("teacherRef", "!=", this.userData.ref)
          .get()
          .then((docs) => {
            this.previousTeacherAttendances = [];
            this.previousTeachers = [];
            var tmpTeachers = [];
            docs.forEach(async (doc) => {
              let att = doc.data();
              att.id = doc.id;
              att.ref = doc.ref;
              att.teachedAt = new Date(
                att.year + "-" + att.month + "-" + att.day
              );
              if (att.teacherRef.id != this.userData.id) {
                var foundTeacher = tmpTeachers.find(
                  (tt) => tt && tt.id == att.teacherRef.id
                );
                if (!foundTeacher) {
                  tmpTeachers.push(att.teacherRef);
                }
                this.previousTeacherAttendances.push(att);
              }
            });
            this.loadingPreviousTeacherAtts = false;
            this.previousTeacherAttendances.sort(
              (a, b) => b.teachedAt - a.teachedAt
            );

            for (const tmpTeacher of tmpTeachers) {
              tmpTeacher.get().then((doc) => {
                let tt = doc.data();
                tt.id = doc.id;
                tt.ref = doc.ref;
                if (this.selectedPreviousTeacher == null)
                  this.selectedPreviousTeacher == tt;
                this.previousTeachers.push(tt);
              });
            }
          });
      }
    },
    selectedLessonClassGroupStudents() {
      this.forceRerender();
    },
    selectedLessonGroupTab(val) {
      if (val != null && val != undefined) {
        this.currentSelectedLessonGroup = this.currentLessonGroups.filter(
          (lg) =>
            lg.classGroup.classGroupRef.id ==
            this.selectedLessonClassGroupStudents[this.selectedClassGroupTab2]
              .classGroupRef.id
        )[this.selectedLessonGroupTab].groupIndex;
      }
    },
    selectedSubTopic(val) {
      if (val) {
        this.loadingEsisTopics = true;
        val.ref
          .collection("subSubTopics")
          .orderBy("numbering", "asc")
          .onSnapshot((docs) => {
            val.children = [];
            var counter = 0;
            docs.docs.forEach((doc) => {
              counter++;
              var subTopic = doc.data();
              subTopic.id = doc.id;
              subTopic.ref = doc.ref;
              subTopic.selected = true;
              subTopic.name = counter + ". " + subTopic.topicName;
              subTopic.index = counter;
              if (!subTopic.deleted) val.children.push(subTopic);
            });
            this.$forceUpdate();
            this.selectedTopicItems = [];
            this.loadingEsisTopics = false;
            if (val.homework) {
              this.selectedHomeWork = {
                name: val.homework,
                description: null,
              };
            }
            if (val.notes) {
              this.editedLessonNotes = val.notes;
            }
          });
      } else {
        this.selectedTopicItems = [];
      }
    },
    selectedSubSubTopic(val) {
      if (val) {
        if (val.homework) {
          this.selectedHomeWork = {
            name: val.homework,
            description: null,
          };
        }
        if (val.description) {
          this.editedLessonNotes = val.description;
        }
        if (val.conclusion) {
          this.editedLessonConclusion = val.conclusion;
        }
      }
    },
    addTopicBulkDialog() {
      var courseId = this.currentSelectedLesson.courseInfo.COURSE_ID.toString();
      this._readTopicsFromFirebase(courseId);
    },
    calendarDialog() {
      this.forceRerender();
    },
    pasteFromTeacher(teacher) {
      console.log("!!!!!!!!!!cccc", this.currentSelectedSemester, teacher);
      if (teacher) {
        var docRef = this.selectedTeacher.school.ref
          .collection(
            "_schoolCurriculums-" + this.selectedTeacher.school.currentYear
          )
          .doc(this.currentSelectedLesson.courseInfo.COURSE_ID.toString())
          .collection("lessonTopics")
          .doc(teacher.teacherId)
          .collection("copiedTopics");
        console.log("reading", docRef.path);
        docRef
          .where("semester", "==", this.currentSelectedSemester)
          .orderBy("index", "asc")
          .get()
          .then((docs) => {
            this.pasteTopics = [];
            docs.docs.forEach((doc) => {
              let topic = doc.data();
              topic.id = doc.id;
              topic.ref = doc.ref;
              // console.log(topic, topic.numbering);
              this.pasteCourseLesson = topic.sourceLesson;
              this.pasteTopics.push(topic);
            });
          });
      } else {
        this.pasteTopics = null;
      }
    },
    selectedParentTopicForAttendance(val) {
      console.log("pttt", val);
      if (val && val.ref) {
        this.loadingEsisTopics = true;
        val.ref
          .collection("subTopics")
          .orderBy("numbering")
          .onSnapshot((docs) => {
            var counter2 = 0;
            val.children = [];
            docs.docs.forEach((doc) => {
              counter2++;
              var subTopic = doc.data();
              subTopic.id = doc.id;
              subTopic.ref = doc.ref;
              subTopic.name =
                counter2 + ". " + this._getCapText(subTopic.courseTopicName);
              if (!subTopic.deleted) val.children.push(subTopic);
            });
            this.loadingEsisTopics = false;
          });
      }
    },
    currentSelectedSemester(val) {
      if (val) {
        this.lessons = null;
        this.allWeeks = this._getWeeks(this.currentSelectedSemester);
        this._getCalendarDates(this.currentSelectedSemester);

        var now = new Date();
        var today = this.dayDatesOfSemester.find(
          (date) =>
            date.year == now.getFullYear() &&
            date.month == now.getMonth() + 1 &&
            date.day == now.getDate()
        );

        this.zSchool.ref
          .collection("lessons-" + this.zSchool.currentYear)
          .where("teacherRefs", "array-contains", this.selectedTeacher.ref)
          .orderBy("courseInfo.COURSE_NAME", "asc")
          .onSnapshot(async (docs) => {
            this.lessons = [];
            this.allLessons = [];
            this.loading = true;
            docs.forEach(async (doc) => {
              let lesson = doc.data();
              lesson.id = doc.id;
              lesson.ref = doc.ref;
              lesson.executions = null;
              lesson["students-" + this.currentSelectedSemester] = null;

              if (today) {
                await lesson.ref
                  .collection("executions")
                  .where("weekNumber", "==", today.weekNumber)
                  .where("year", "==", now.getFullYear())
                  .onSnapshot((docs) => {
                    lesson.executions = [];
                    docs.forEach((doc) => {
                      let execution = doc.data();
                      execution.ref = doc.ref;
                      execution.id = doc.id;

                      lesson.executions.push(execution);
                    });
                  });
              }

              await lesson.ref.parent
                .doc(lesson.courseInfo.COURSE_ID + "-" + lesson.id)
                .onSnapshot(async (doc) => {
                  if (doc.exists) {
                    let lessonConfig = doc.data();
                    lesson.lessonSettingData = lessonConfig[this.userData.id]
                      ? lessonConfig[this.userData.id]
                      : null;
                  } else {
                    await lesson.ref.parent
                      .doc(String(lesson.courseInfo.COURSE_ID))
                      .onSnapshot((doc) => {
                        if (doc.exists) {
                          let lessonConfig = doc.data();
                          lesson.lessonSettingData = lessonConfig[
                            this.userData.id
                          ]
                            ? lessonConfig[this.userData.id]
                            : null;
                        }
                      });
                  }
                });

              this.isAllowedLesson(lesson);
              if (lesson.classGroupRefs && lesson.classGroupRefs.length > 0) {
                var program = await lesson.classGroupRefs[0]
                  .get()
                  .then((doc) => doc.data());
                lesson.classGroups[0].programData = program;
                if (
                  program.calendarVersions &&
                  program.calendarVersions.length
                ) {
                  lesson.activeCalendarVersion = program.calendarVersions.find(
                    (version) => {
                      var now = new Date();
                      var startDate = new Date(version.startDate);
                      var endDate = new Date(version.endDate);
                      if (startDate < now && now < endDate) {
                        return version;
                      }
                    } //ERROR1203
                  );

                  // if (!lesson.activeCalendarVersion) {
                  //   lesson.activeCalendarVersion = {
                  //     calendarVersionNumber: "1",
                  //   }; //ERROR1203 workaround
                  // }
                  // if (
                  //   lesson.startEnd &&
                  //   lesson.startEnd["semester-1"] &&
                  //   lesson.startEnd["semester-1"].start != "2024-09-01" &&
                  //   this._getClassLevel(lesson)!='1'
                  // ) {
                  //   console.log(this._getClassLevel(lesson),"xxxx")
                  //   lesson.startEnd["semester-1"].start = "2024-09-01"; //ERROR1203 workaround
                  //   lesson.ref.update({
                  //     startEnd: lesson.startEnd,
                  //   }).then(()=>{
                  //     console.log("lllll", lesson)
                  //   })
                  // }else{
                  //   //
                  // }
                }
              } else {
                //
              }

              if (!lesson.isNotAllowed) {
                var usingSemesterDetected = false;
                for (const teacher of lesson.byTeachers) {
                  if (teacher.xSemester) {
                    usingSemesterDetected = true;
                  }
                }
                if (usingSemesterDetected) {
                  var found = lesson.byTeachers.find(
                    (tt) =>
                      (!tt.xSemester ||
                        tt.xSemester == this.currentSelectedSemester) &&
                      tt.teacherRef.path == this.userData.ref.path
                  );
                  if (found && lesson.deleted == false) {
                    var foundLesson = this.lessons.find(
                      (ll) => ll.id == lesson.id
                    );
                    if (!foundLesson) this.lessons.push(lesson);
                  }
                } else {
                  //for all 4 semester orno
                  var foundLesson1 = this.lessons.find(
                    (ll) => ll.id == lesson.id
                  );
                  if (!foundLesson1) this.lessons.push(lesson);
                  this.lessons.push(lesson);
                }
                var foundLesson3 = this.allLessons.find(
                  (ll) => ll.id == lesson.id
                );
                if (!foundLesson3) this.allLessons.push(lesson);
              }
            });

            this.loading = false;

            // if (this.selectedTab == 2) {
            //   this.setupp();
            // }

            if (this.currentSelectedLesson) {
              setTimeout(() => {
                let found = this.filteredLessons.find(
                  (lesson) => lesson.id == this.currentSelectedLesson.id
                );
                this.currentSelectedLesson = found;
              }, 1000);
            } else {
              if (
                this.currentSelectedSemester !=
                this.$store.state.runningSemester
              ) {
                this.showActiveWeekRegisteration = false;
              } else {
                this.showActiveWeekRegisteration = true;
              }
            }
          });
      }
    },
    currentSelectedLesson(val) {
      if (val) {
        console.log(val, "lesson....");
        if (
          val.courseInfo.COURSE_NAME.includes("Бэлтгэл хичээл") &&
          val.classGroups[0].ACADEMIC_LEVEL == "1" &&
          (!val._calDatas || val._calDatas.length == 0)
        ) {
          this.handleBeltgelLessonCalendar();

          setTimeout(() => {
            this._readLessonExecution(
              this.currentSelectedSemester,
              this.currentSelectedLesson,
              true
            );
            if (this.fullAutomaticallyAdded) this._getPlannedLessonTopics();
            setTimeout(() => {
              this.handleStatistics(this.irzuudOfLessons);
            }, 1000);

            this._readCopiedTopicData(this.currentSelectedLesson);
          }, 2000);
        } else {
          this._readLessonExecution(
            this.currentSelectedSemester,
            this.currentSelectedLesson,
            true
          );
          if (this.fullAutomaticallyAdded) this._getPlannedLessonTopics();
          setTimeout(() => {
            this.handleStatistics(this.irzuudOfLessons);
          }, 1000);

          this._readCopiedTopicData(this.currentSelectedLesson);
        }
      } else {
        this.showActiveWeekRegisteration = true;
      }
    },
    findWorkingWeekDays() {
      this.currentWeekDays = [];
      this.attendances = [];
      this.dailyAttendances = [];
      var ss = this.$store.state.calendarButez2.find(
        (item) => item.session == this.currentSelectedSemester
      );
      if (ss) {
        ss.months.forEach((mm) => {
          mm.days.forEach((dd) => {
            if (dd.weekNumber == this.currentSelectedWeek) {
              dd.month = mm.name;
              this.currentWeekDays.push(dd);
            }
          });
        });
      }
    },
  },
  created() {
    if (!this.zSchool) {
      this.zSchool = this.userData.school;
    }
    if (!this.selectedTeacher) this.selectedTeacher = this.userData;
    this.headerNames = [
      {
        text: "ID",
        align: "start",
        sortable: false,
        width: "1%",
        value: "index",
      },
      {
        text: "Xичээлийн нэр",
        value: "courseInfo.COURSE_NAME",
        sortable: true,
        width: "25%",
      },
      {
        text: "Төрөл",
        align: "start",
        value: "esisLessonType",
        width: "10%",
      },
      {
        text: "Бүлгүүд",
        align: "start",
        value: "classGroups",
        sortable: true,
        width: "10%",
      },

      // {
      //   text: "Xуваарь",
      //   align: "start",
      //   value: "calendar",
      //   sortable: false,
      //   width: "20%",
      // },
      // {
      //   text: "7 хоног орох",
      //   align: "start",
      //   value: "ltime",
      //   sortable: true,
      //   width: "5%",
      // },
      {
        text: "Нийт оролт",
        align: "start",
        value: "mustNumber",
        sortable: true,
        width: "10%",
      },
      {
        text: "Ирц бүртгэсэн",
        align: "start",
        value: "att",
        sortable: true,
        width: "10%",
      },
      {
        text: "Ирц%",
        align: "start",
        value: "attPercent",
        sortable: true,
        width: "6%",
      },
      {
        text: "Сэдэв%",
        align: "start",
        value: "topicPercent",
        sortable: true,
        width: "6%",
      },
      {
        text: "ГД%",
        align: "start",
        value: "homeworkPercent",
        sortable: true,
        width: "6%",
      },
      {
        text: "ҮА%",
        align: "start",
        value: "notesPercent",
        sortable: true,
        width: "6%",
      },
      // {
      //   text: "Дүгнэлт%",
      //   align: "start",
      //   value: "conclusionPercent",
      //   sortable: true,
      //   width: "6%",
      // },

      {
        text: "Нийт%",
        align: "start",
        value: "totalExecutionPercent",
        sortable: true,
        width: "6%",
      },
      {
        text: "Үйлдэл",
        align: "start",
        value: "actions",
      },
    ];
    this.headerNames3 = [
      {
        text: "ID",
        align: "start",
        sortable: false,
        width: "1%",
        value: "index",
      },
      {
        text: "Xичээлийн нэр",
        value: "courseInfo.COURSE_NAME",
        sortable: true,
        width: "30%",
      },
      {
        text: "Төрөл",
        align: "start",
        value: "esisLessonType",
        width: "14%",
      },
      {
        text: "Бүлгүүд",
        align: "start",
        value: "classGroups",
        sortable: true,
        width: "25%",
      },
      {
        text: "X.тоо",
        align: "start",
        value: "numberOfCals",
        sortable: true,
        width: "1%",
      },
      
      {
        text: "Xувиарууд",
        align: "start",
        value: "calDatas",
        sortable: true,
        width: "30%",
      },
      {
        text: "Xуваарь+",
        align: "start",
        value: "calplus",
        width: "5%",
      },
      {
        text: "Үйлдэл",
        align: "start",
        value: "actions",
      },
    ];
    this.topicsDatabaseHeaders = [
      {
        text: "ID",
        align: "start",
        sortable: false,
        width: "1%",
        value: "index",
      },
      {
        text: "Xичээлийн нэр",
        value: "courseInfo.COURSE_NAME",
        sortable: true,
      },
      {
        text: "Төрөл",
        align: "start",
        sortable: true,
        value: "esisLessonType",
      },
      {
        text: "Үйлдэл",
        align: "start",
        value: "actions",
      },
    ];

    this.currentSelectedWeek0 = this._getCurrentSchoolWeekNumber();
    this.currentSelectedWeek = this.currentSelectedWeek0;

    this.currentWeekDays = this._findCurrentWorkingDays(
      this.$store.state.runningSemester,
      this.currentSelectedWeek
    );
    this.currentSelectedSemester = this.$store.state.runningSemester;

    var teacherStatDocRef = this.userData.school.ref
      .collection(
        "teachersExecutionOverview-" + this.userData.school.currentYear
      )
      .doc(this.userData.id);
    // console.log("teacherStatDocRef", teacherStatDocRef.path);
    teacherStatDocRef.onSnapshot((doc) => {
      if (doc.exists) {
        let statistics = doc.data();
        statistics.ref = doc.ref;
        statistics.id = doc.id;
        // console.log(statistics.ref.path, "xxxx");
        this.statistics = statistics;
      } else {
        this.statistics = {};
      }
    });
  },
  methods: {
    _getClassLevel(lesson){
      var list = []
      if(lesson.classGroups&&lesson.classGroups.length>0){
        for(const cg of lesson.classGroups){
          list.push(cg.ACADEMIC_LEVEL)
        } 
      }
      console.log(list,"list",parseInt(list[0]))
      if(list.length>0) return parseInt(list[0])
      else return 0
    },
    _testtest() {
      console.log(this.irzuudOfLessons.length, this.irzuudOfLessons);
      // setTimeout(() => {
      //   this.handleStatistics(this.irzuudOfLessons);
      // }, 1000);
      var doneTopic = 0;
      for (var i = 0; i < this.irzuudOfLessons.length; i++) {
        var irz = this.irzuudOfLessons[i];
        if (irz.actualDate && !irz.actualDate.isHoliday) {
          if (irz.execution) {
            var topicExits = this._getTopicStringK(i, "name");
            if (irz.execution.regular != false && topicExits) doneTopic++;

            var must =
              this.statistics["semester-" + this.currentSelectedSemester][
                this.currentSelectedLesson.id
              ]["mustTopic"];
            var done =
              this.statistics["semester-" + this.currentSelectedSemester][
                this.currentSelectedLesson.id
              ]["doneTopic"];
            console.log(i, topicExits, doneTopic, must, done, "xx");
          }
        }
      }
    },
    _saveClassGroups() {
      this.selectedLesson.ref
        .update({
          classGroupIds: this.selectedLessonProgram.map((a) =>
            Number(a.STUDENT_GROUP_ID)
          ),
          classGroupRefs: this.selectedLessonProgram.map(
            (a) => a.classGroupRef
          ),
          classGroups: this.selectedLessonProgram,
        })
        .then(() => {
          this.showSelectedLessonClassGroupDialog =
            !this.showSelectedLessonClassGroupDialog;
          this.selectedLessonProgram = null;
          this.selectedLessonClassGroups = null;
          this.selectedLesson = null;
        });
    },
    async _editClassGroup(lesson) {
      console.log(lesson.academicLevel);
      await this.userData.school.ref
        .collection("departments-" + this.userData.school.currentYear)
        .doc("department-" + lesson.academicLevel)
        .collection("programs")
        .orderBy("STUDENT_GROUP_NAME", "asc")
        .where("deleted", "==", false)
        .get()
        .then((docs) => {
          this.selectedLessonClassGroups = [];
          docs.forEach((doc) => {
            let program = doc.data();
            program.ref = doc.ref;
            program.id = doc.id;
            console.log(program.STUDENT_GROUP_NAME);
            this.selectedLessonClassGroups.push({
              ACADEMIC_LEVEL: program.ACADEMIC_LEVEL,
              STUDENT_GROUP_ID: program.STUDENT_GROUP_ID,
              classGroupFullName: program.STUDENT_GROUP_NAME,
              classGroupName: program.STUDENT_GROUP_NAME,
              classGroupRef: program.ref,
              departmentName: program.ACADEMIC_LEVEL_NAME,
              departmentRef: program.ref.parent.parent,
            });
          });
        });
      this.selectedLesson = this.lessons.find((lsn) => lsn.id == lesson.id);
      this.selectedLessonProgram = this.selectedLesson.classGroups;
      this.showSelectedLessonClassGroupDialog =
        !this.showSelectedLessonClassGroupDialog;
    },
    _saveLesson2() {
      if (this.selectedCourse && this.selectedWeekHours) {
        var startEnd = {
          "semester-1": { available: this.selectedSemesters.semester1 },
          "semester-2": { available: this.selectedSemesters.semester2 },
          "semester-3": { available: this.selectedSemesters.semester3 },
          "semester-4": { available: this.selectedSemesters.semester4 },
        };
        var teacherInfo = {
          teacherRef: this.userData.ref,
          teacherFirstName: this.userData.firstName
            ? this.userData.firstName
            : null,
          teacherLastName: this.userData.lastName
            ? this.userData.lastName
            : null,
          teacherDisplayName: this.userData.DISPLAY_NAME
            ? this.userData.DISPLAY_NAME
            : null,
          teacherId: this.userData.id,
          addedAt: new Date(),
          xSemester: this.$store.state.runningSemester
            ? this.$store.state.runningSemester
            : null,
        };
        var newLessonData = {
          selectedGroupName: this.selectedGroupName
            ? this.selectedGroupName
            : null,
          selectedWeekHours: this.selectedWeekHours,
          addedRole: this.userData.role,
          addedByRef: this.userData.ref,
          addedByName: this.userData.DISPLAY_NAME
            ? this.userData.DISPLAY_NAME
            : this.userData.firstName
            ? this.userData.firstName
            : this.userData.email,
          startEnd: startEnd,
          byTeachers: fb.firestore.FieldValue.arrayUnion(teacherInfo),
          teacherRefs: fb.firestore.FieldValue.arrayUnion(this.userData.ref),
          year: this.userData.school.currentYear,
          courseInfo: this.selectedCourse,
          academicLevel: this.selectedStage.ACADEMIC_LEVEL,
          createdAt: new Date(),
          deleted: false,
          esisLessonType: {
            esisLessonTypeId: 3,
            name: "Xолимог",
          },
        };
        console.log(newLessonData);
        var docRef = this.userData.school.ref
          .collection("lessons-" + this.userData.school.currentYear)
          .doc();

        docRef.set(newLessonData, { merge: true }).then(() => {
          this.selectedGroupName = null;
          this.selectedWeekHours = null;

          this.selectedStage = null;

          this.selectedPlan = null;
          this.curriculumPlans = null;

          this.selectedCourse = null;
          this.curriculumCourses = null;
          this.showAddLessonDialog = !this.showAddLessonDialog;
        });
      } else {
        this.$swal.fire(
          "1) Xичээлээ xүснэгтээс сонгоx, 2) долоо xоногт xэдэн удаа ордог цагийг оруулаx!"
        );
      }
    },
    onRowClick(item) {
      this.selectedCourse = item;
    },
    _getForLoopKey(cal, calIndex) {
      return (
        cal.actualDate.year +
        "-" +
        cal.actualDate.month +
        "-" +
        cal.actualDate.day +
        "--" +
        calIndex +
        cal.lesson.id +
        cal.calData.sortIndex
      );
    },
    _saveLessonConclusion() {
      console.log(this.selectedCalDataToShow.execution, "saving....");
      if (this.selectedCalDataToShow.execution.result) {
        this.selectedCalDataToShow.execution.ref
          .update({
            result: this.selectedCalDataToShow.execution.result,
          })
          .then(() => {
            this.selectedCalDataToShow = null;
            this.selectedCalDataIndex = null;
            this.topicConclusionDialog = false;
          });
      } else {
        this.selectedCalDataToShow = null;
        this.selectedCalDataIndex = null;
        this.topicConclusionDialog = false;
      }
    },
    _addLessonConclusion(calData, calIndex) {
      this.selectedCalDataToShow = Object.assign({}, calData);
      this.selectedCalDataIndex = calIndex;
      if (
        !(
          this.selectedCalDataToShow.execution &&
          this.selectedCalDataToShow.execution.result
        )
      ) {
        var found =
          this.currentSelectedLesson.eeljitTopics[this.selectedCalDataIndex];
        if (found && found.result && this.selectedCalDataToShow.execution) {
          this.selectedCalDataToShow.execution.result = found.result;
        }
      }
      this.topicConclusionDialog = true;
    },
    _getDateString(cal) {
      if (cal.actualDate && cal)
        return (
          cal.actualDate.year +
          "-" +
          cal.actualDate.month +
          "-" +
          cal.actualDate.day
        );
      return null;
    },

    _clickTopic2(cal, calIndex) {
      this.selectedCalDataToShow = cal;
      this.selectedCalDataIndex = calIndex;
      this.topicDetailedDialog = true;
    },
    _printCourse() {
      // var found =  this._getTopicStringK(0 , "homework")
      console.log(this.currentSelectedLesson.courseInfo);
      console.log(
        this.currentSelectedLesson.courseInfo.ref.path,
        this.currentSelectedLesson.eeljitTopics.length
      );
      for (var k = 0; k < this.currentSelectedLesson.eeljitTopics.length; k++) {
        var et = this.currentSelectedLesson.eeljitTopics[k];
        console.log(et.numbering, et.name, et.time, et.ref.path);
      }
    },
    _getCuttedString(str, limit) {
      if (str && limit && limit > 0 && limit < str.length) {
        return str.substring(0, limit) + "...";
      }
      return str;
    },
    _getTopicStringK(calIndex, kkey, fullString) {
      if (
        this.currentSelectedLesson &&
        this.currentSelectedLesson.eeljitTopics
      ) {
        // var found = this.currentSelectedLesson.eeljitTopics.find(
        //   (et) =>
        //     et.executionIndexs.find((et) => Number(et) == Number(calIndex)) !=
        //     undefined
        // );
        var found = this.currentSelectedLesson.eeljitTopics[calIndex];
        if (fullString) {
          return found && found[kkey] ? found[kkey] : null;
        } else
          return found && found[kkey]
            ? this._getCuttedString(found[kkey], 40)
            : null;
      }
      //return "-"
      return null;
    },
    handleBeltgelLessonCalendar() {
      this.currentSelectedLesson.ref
        .update({
          _calDatas: [
            "1-1-1-1-01",
            "1-1-1-1-02",
            "1-1-1-1-03",
            "1-1-1-1-04",

            "1-1-2-1-01",
            "1-1-2-1-02",
            "1-1-2-1-03",
            "1-1-2-1-04",

            "1-1-3-1-01",
            "1-1-3-1-02",
            "1-1-3-1-03",
            "1-1-3-1-04",

            "1-1-4-1-01",
            "1-1-4-1-02",
            "1-1-4-1-03",
            "1-1-4-1-04",

            "1-1-5-1-01",
            "1-1-5-1-02",
            "1-1-5-1-03",
            "1-1-5-1-04",
          ],
        })
        .then(() => {
          this.$forceUpdate();
        });
    },
    findAcademicLevel(input) {
      let match = input.match(/\d+/);
      return match ? match[0] : null;
    },

    _getDocumentIdString(course) {
      console.log(course, "!!!!!!!!!!!");

      console.log(course);
      console.log(this.findAcademicLevel(course.COURSE_NAME), "XXXX");
      // return String(
      //   course.SUBJECT_AREA_NAME +

      //     "-" +
      //     course.COURSE_CLASSIFICATION
      // );
      return String(
        course.SUBJECT_AREA_NAME.replace(/\//g, "-") +
          "-" +
          course.PROGRAM_STAGE_ID +
          "-" +
          this.findAcademicLevel(course.COURSE_NAME) +
          "-" +
          course.ENROLLMENT_CATEGORY +
          "-" +
          this.userData.PERSON_ID
      );

      // return String(
      //   course.SUBJECT_AREA_NAME +
      //     "-" +
      //     course.PROGRAM_STAGE_ID +
      //     "-" +
      //     this.findAcademicLevel(course.COURSE_NAME) +
      //     "-" +
      //     course.ENROLLMENT_CATEGORY +
      //     "-" +
      //     this.userData.PERSON_ID
      // );
    },

    _getPlannedLessonTopics() {
      var docTeacherPlanName = this._getDocumentIdString(
        this.currentSelectedLesson.courseInfo
      );
      // console.log(
      //   docTeacherPlanName,
      //   "docNamedocNamedocNamedocName",
      //   this.currentSelectedLesson.courseInfo
      // );
      //schools/JR9yXmuAv8nRcBJtPrAE/_schoolCurriculums-2024/100000350401907/unitTopics
      var ddRef = this.userData.school.ref
        .collection("_schoolTeacherPlans-" + this.userData.school.currentYear)
        .doc(docTeacherPlanName);

      ddRef.get().then((doc) => {
        if (doc.exists == false) {
          // console.log("ddRefddRef||||", ddRef.path, doc);
          this.userData.school.ref
            .collection(
              "_schoolTeacherPlans-" + this.userData.school.currentYear
            )
            .where(
              "COURSE_ID",
              "==",
              this.currentSelectedLesson.courseInfo.COURSE_ID
            )
            // .where("SUBJECT_AREA_ID","==", this.currentSelectedLesson.courseInfo.COURSE_ID)
            // .where("ENROLLMENT_CATEGORY","==", this.currentSelectedLesson.courseInfo.ENROLLMENT_CATEGORY)
            .get()
            .then((docs) => {
              if (!docs.empty) {
                this.currentSelectedLesson.eeljitTopics = [];
                docs.docs[0].ref
                  .collection("unitTopics")
                  .orderBy("numbering")
                  .get()
                  .then((docs) => {
                    // var index = 0;
                    this.currentSelectedLesson.eeljitTopics = [];
                    docs.forEach(async (doc) => {
                      let tt = doc.data();
                      tt.id = doc.id;
                      tt.ref = doc.ref;
                      if (!tt.deleted) {
                        // console.log("eeljit",tt.ref.path,tt);
                        await tt.ref
                          .collection("eeljitTopics")
                          .orderBy("numbering")
                          .get()
                          .then((docs) => {
                            docs.forEach((doc) => {
                              let et = doc.data();
                              et.id = doc.id;
                              et.ref = doc.ref;
                              et.executionIndexs = [];
                              if (!et.deleted) {
                                for (var i = 0; i < et.time; i++) {
                                  // index++;
                                  // et.executionIndexs.push(index);
                                  // console.log("eeljit", et.name, et.time);
                                  this.currentSelectedLesson.eeljitTopics.push(
                                    et
                                  );
                                }
                                // console.log(et.executionIndexs);
                                // this.currentSelectedLesson.eeljitTopics.push(et);
                                this.$forceUpdate();
                              }
                            });
                          });
                      }
                    });
                  });
              }
            });
        } else {
          ddRef
            .collection("unitTopics")
            .orderBy("numbering")
            .get()
            .then((docs) => {
              // var index = 0;
              this.currentSelectedLesson.eeljitTopics = [];
              docs.forEach(async (doc) => {
                let tt = doc.data();
                tt.id = doc.id;
                tt.ref = doc.ref;
                if (!tt.deleted) {
                  // console.log("eeljit",tt.ref.path,tt);
                  await tt.ref
                    .collection("eeljitTopics")
                    .orderBy("numbering")
                    .get()
                    .then((docs) => {
                      docs.forEach((doc) => {
                        let et = doc.data();
                        et.id = doc.id;
                        et.ref = doc.ref;
                        et.executionIndexs = [];
                        if (!et.deleted) {
                          for (var i = 0; i < et.time; i++) {
                            // index++;
                            // et.executionIndexs.push(index);
                            console.log("eeljit", et.name, et.time);
                            this.currentSelectedLesson.eeljitTopics.push(et);
                          }
                          console.log(et.executionIndexs);
                          // this.currentSelectedLesson.eeljitTopics.push(et);
                          this.$forceUpdate();
                        }
                      });
                    });
                }
              });
            });
        }
      });
    },
    _printTopics() {
      console.log(this.currentSelectedLesson.courseInfo.COURSE_ID);
      var docTeacherPlanName = this._getDocumentIdString(
        this.currentSelectedLesson.courseInfo
      );
      console.log(docTeacherPlanName, "docNamedocNamedocNamedocName");
      //schools/JR9yXmuAv8nRcBJtPrAE/_schoolCurriculums-2024/100000350401907/unitTopics
      this.userData.school.ref
        .collection("_schoolTeacherPlans-" + this.userData.school.currentYear)
        .doc(docTeacherPlanName)
        .collection("unitTopics")
        .orderBy("numbering")
        .get()
        .then((docs) => {
          this.eeljitTopics = [];
          docs.forEach((doc) => {
            let tt = doc.data();
            tt.id = doc.id;
            tt.ref = doc.ref;
            // console.log(tt);
            tt.ref
              .collection("eeljitTopics")
              .orderBy("numbering")
              .get()
              .then((docs) => {
                docs.forEach((doc) => {
                  let et = doc.data();
                  et.id = doc.id;
                  et.ref = doc.ref;
                  console.log(et.numbering, et.name, et.time);
                  this.eeljitTopics.push(et);
                });
              });
            // this.eeljitTopics.push(tt);
          });
        });
    },
    _deleteCalData(lesson, calData) {
      // console.log(lesson.ref.path);
      // console.log(calData);

      this.$swal({
        title: "Xуваарь устгаx уу?",
        text:
          calData.calendarEelj +
          "-р ээлж, " +
          calData.garagName +
          "-" +
          calData.time +
          ", " +
          lesson.courseInfo.COURSE_NAME +
          ", " +
          lesson.classGroups.map((cg) => cg.classGroupFullName).join(","),
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: "Тийм",
        cancelButtonText: "Үгүй",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          var dataStr =
            calData.semester +
            "-" +
            calData.calendarVersion +
            "-" +
            calData.garag +
            "-" +
            calData.calendarEelj +
            "-" +
            String(calData.time).padStart(2, "0");

          console.log("dataStr", dataStr);
          lesson.ref.update({
            _calDatas: fb.firestore.FieldValue.arrayRemove(dataStr),
          });
        }
      });
    },
    _saveCalData() {
      console.log("selectedCalEelj", this.selectedCalEelj);
      console.log("selectedCalEelj", this.selectedCalEelj);
      console.log("selectedCalGarag", this.selectedCalGarag);
      console.log("selectedCalTsag", this.selectedCalTsag);

      if (
        this.selectedCalEelj &&
        this.selectedCalGarag &&
        this.selectedCalTsag
      ) {
        //semester + calendarVersion + garag + eelj + tsag
        var dataStr =
          this.currentSelectedSemester +
          "-" +
          this.selectedCalVersion +
          "-" +
          this.selectedCalGarag +
          "-" +
          this.selectedCalEelj +
          "-" +
          String(this.selectedCalTsag).padStart(2, "0");

        console.log(dataStr, this.selectedLesson.ref.path);

        this.selectedLesson.ref
          .update({
            _calDatas: fb.firestore.FieldValue.arrayUnion(dataStr),
          })
          .then(() => {
            this.selectedLesson = null;
            this.selectedCalEelj = null;
            this.selectedCalGarag = null;
            this.selectedCalTsag = null;
            this.showCalDataDialog = !this.showCalDataDialog;
          });
      } else {
        this.$swal.fire("Ээлж, өдөр, цагаа заавал сонгоно!");
      }
    },
    _addCal(item) {
      console.log(item._calDatas, item.ref.path, item);
      this.selectedLesson = item;
      this.showCalDataDialog = !this.showCalDataDialog;
    },
    _closeGroupStudentDialog() {
      var lastNumber =
        this.filteredStudents.length > 0 ? this.filteredStudents.length : 0;
      var tmp = {};
      tmp["numberSelectedStudents-" + this.currentSelectedSemester] =
        lastNumber;
      this.selectedLesson.ref.update(tmp).then(() => {
        this.selectedLesson = null;
        this.showStudents = !this.showStudents;
      });
    },
    _saveAddStudentToLessonDialog() {
      console.log("saveing....", this.selectedAddStudents);
      console.log(
        this.selectedLesson.ref.path,
        this.selectedLesson.courseInfo.COURSE_NAME
      );
      if (this.selectedAddStudents && this.selectedAddStudents.length > 0) {
        var batch = fb.db.batch();
        for (const student of this.selectedAddStudents) {
          var tmp = {};
          tmp["FIRST_NAME"] = student.FIRST_NAME;
          tmp["LAST_NAME"] = student.LAST_NAME;
          tmp["PERSON_ID"] = student.PERSON_ID;
          tmp["DATE_OF_BIRTH"] = student.DATE_OF_BIRTH;
          tmp["EMAIL"] = student.EMAIL ? student.EMAIL : null;
          tmp["STUDENT_GROUP_ID"] = student.STUDENT_GROUP_ID;
          tmp["STUDENT_GROUP_NAME"] = student.STUDENT_GROUP_NAME;
          tmp["GENDER_CODE"] = student.GENDER_CODE;
          tmp["classGroupRef"] =
            this.selectedLesson.classGroups[0].classGroupRef;
          tmp.currentSelectedSemester = this.currentSelectedSemester;
          tmp.addAtDate = new Date();
          tmp.addByRef = this.userData.ref;
          tmp.addByName = this.userData.DISPLAY_NAME
            ? this.userData.DISPLAY_NAME
            : this.userData.firstName;

          var sRef = this.selectedLesson.ref
            .collection("students-" + this.currentSelectedSemester)
            .doc(String(student.PERSON_ID));
          batch.set(sRef, tmp, { merge: true });
        }
        // batch.update(this.selectedLesson.ref, {
        //   numberSelectedStudents: fb.firestore.FieldValue.increment(
        //     this.selectedAddStudents.length
        //   ),
        // });
        batch.commit().then(() => {
          this.forceRerender();
        });
      }
      this.selectedAddStudents = [];
      this.addStudentToLessonDialog = false;
    },
    formatDate(val, mode) {
      if (val != null) {
        if (!val) {
          return "-";
        }
        if (mode) {
          var date = val.toDate();
        } else {
          date = new Date(val);
        }
        return moment(date).format("YYYY-MM-DD");
      }
      return "татаагүй";
    },
    async _editStudents(lesson, semester) {
      console.log(lesson, semester);

      await lesson.ref
        .collection("students-" + this.currentSelectedSemester)
        .orderBy("FIRST_NAME")
        .onSnapshot((docs) => {
          lesson["students-" + this.currentSelectedSemester] = [];
          var index = 0;
          docs.forEach((doc) => {
            index++;
            let student = doc.data();
            student.ref = doc.ref;
            student.id = doc.id;
            student.bday = this.formatDate(student.DATE_OF_BIRTH, false);
            student.DISPLAY_NAME =
              student.LAST_NAME[0] + ". " + student.FIRST_NAME;
            student.index = index;
            var found = lesson["students-" + this.currentSelectedSemester].find(
              (st) => st.id == student.id
            );
            if (!found)
              lesson["students-" + this.currentSelectedSemester].push(student);
          });
          this.forceRerender();
          if (
            lesson["students-" + this.currentSelectedSemester].length > 0 &&
            lesson["students-" + this.currentSelectedSemester].length !=
              lesson.numberSelectedStudents
          ) {
            lesson.ref.update({
              numberSelectedStudents:
                lesson["students-" + this.currentSelectedSemester].length,
            });
          }
          // console.log(
          //   lesson,
          //   lesson["students-" + this.currentSelectedSemester],
          //   lesson.numberSelectedStudents
          // );
        });

      this.selectedLesson = lesson;
      if (
        this.selectedLesson.classGroups &&
        this.selectedLesson.classGroups.length > 0
      ) {
        this.selectedSemester = semester;
        this.showStudents = !this.showStudents;
      } else {
        this.$swal.fire(
          "Бүлгээ нэмээрэй. Түүний дараа сурагчдаа нэмэx боломжтой!"
        );
      }
    },

    _deletesStudent(groupStudent) {
      this.$swal({
        title: "Сурагчийг группээс xасаx уу?",
        text: "Группээс xасагдана.",
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: "Тийм",
        cancelButtonText: "Үгүй",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          groupStudent.ref.delete().then(() => {
            var foundIndex = this.selectedLesson[
              "students-" + this.selectedSemester
            ].findIndex((ss) => ss.PERSON_ID == groupStudent.PERSON_ID);

            if (foundIndex !== -1) {
              console.log("remove.....", foundIndex);
              this.selectedLesson["students-" + this.selectedSemester].splice(
                foundIndex,
                1
              );
            }
            this.forceRerender();
          });
        }
      });
    },
    _addStudentToLesson(lesson) {
      if (this.$store.state.runningSemester == this.currentSelectedSemester) {
        this.selectedLesson = lesson;
        var counter = 0;
        this.loadingStudents = true;
        this.selectedAddStudents = [];
        // if (!this.classGroupStudents) {
        this.userData.school.ref
          .collection("students-" + this.userData.school.currentYear)
          .where(
            "STUDENT_GROUP_ID",
            "in",
            lesson.classGroups.map((a) => a.STUDENT_GROUP_ID)
          )
          .orderBy("firstName")
          .get()
          .then((docs) => {
            counter = 0;
            this.classGroupStudents = [];
            docs.forEach((doc) => {
              // counter++;
              let student = doc.data();
              student.id = doc.id;
              student.ref = doc.ref;
              // student.index = counter;
              student.DISPLAY_NAME =
                student.LAST_NAME[0] + ". " + student.FIRST_NAME;
              student.bday = student.DATE_OF_BIRTH.replace(
                "T00:00:00.000Z",
                ""
              );
              var studentAlreadyAdded = this.selectedLesson[
                "students-" + this.selectedSemester
              ].find((ss) => ss.PERSON_ID == student.PERSON_ID);

              if (!studentAlreadyAdded) {
                counter++;
                student.index = counter;
                this.classGroupStudents.push(student);
              }
            });
            this.loadingStudents = false;
            this.addStudentToLessonDialog = true;
          });
        // } else {
        //   this.loadingStudents = false;
        //   this.addStudentToLessonDialog = true;
        // }
      } else {
        this.$swal.fire("Зөвxөн одоо байгаа улирлыг тоxируулна!");
      }
    },

    _deleteLesson(lesson) {
      if (lesson.addedByRef.id == this.userData.id) {
        var ccc = lesson.classGroups
          .map((cg) => cg.classGroupFullName)
          .join(", ");
        this.$swal({
          title:
            lesson.courseInfo.COURSE_NAME +
            " (" +
            ccc +
            ") xичээлийг устгаx уу?",
          text:
            lesson.addedByName +
            " багш энэ xичээлийг үүсгэсэн байна. Xэрэв та устгавал СУРАГЧ, ИРЦ БҮРТГЭЛ xамт устгагдана!",
          type: "warning",
          showCancelButton: true,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          confirmButtonText: "Тийм",
          cancelButtonText: "Үгүй",
          reverseButtons: true,
        }).then((result) => {
          if (result.isConfirmed) {
            console.log(lesson.ref.path);
            lesson.ref
              .collection("executions")
              .get()
              .then((docs) => {
                if (docs.empty) {
                  lesson.ref.delete();
                } else {
                  console.log("not possible to delete");
                  lesson.ref
                    .update({
                      deleted: true,
                      deletedByRef: this.userData.ref,
                      deletedByName: this.userData.DISPLAY_NAME
                        ? this.userData.DISPLAY_NAME
                        : this.userData.firstName,
                    })
                    .then(() => {
                      console.log("deleted");
                    });
                }
              });

            if (
              lesson.esisLessonType &&
              lesson.esisLessonType.esisLessonTypeId > 1
            ) {
              this.$store.state.yearSemesters.forEach((ss) => {
                console.log(ss, "ss");
                lesson.ref
                  .collection("students-" + ss)
                  .get()
                  .then((docs) => {
                    if (!docs.empty) {
                      const batch = fb.db.batch();
                      docs.forEach((doc) => {
                        batch.delete(doc.ref);
                      });
                      batch.commit();
                    }
                  });
              });
            }
          }
        });
      } else {
        this.$swal.fire(
          "Энэ xичээлийг та үүсгээгүй байна. Менежер устгана! Менежертэйгээ xолбоо барина уу!"
        );
      }
    },
    _createEeljitLesson2() {
      //
      console.log(this.selectedClassGroupNew);
      console.log(this.selectedCourse.COURSE_NAME);

      console.log(
        this.selectedSemesters.semester1,
        this.selectedSemesters.semester2,
        this.selectedSemesters.semester3,
        this.selectedSemesters.semester4
      );

      var startEnd = {
        "semester-1": { available: this.selectedSemesters.semester1 },
        "semester-2": { available: this.selectedSemesters.semester2 },
        "semester-3": { available: this.selectedSemesters.semester3 },
        "semester-4": { available: this.selectedSemesters.semester4 },
      };
      var teacherInfo = {
        teacherRef: this.userData.ref,
        teacherFirstName: this.userData.firstName
          ? this.userData.firstName
          : null,
        teacherLastName: this.userData.lastName
          ? this.selectedTeacher.lastName
          : null,
        teacherDisplayName: this.userData.DISPLAY_NAME
          ? this.selectedTeacher.DISPLAY_NAME
          : null,
        teacherId: this.userData.PERSON_ID,
        addedAt: new Date(),
        xSemester: this.currentSelectedSemester,
      };
      var newLessonData = {
        addedByRef: this.userData.ref,
        addedByName: this.userData.DISPLAY_NAME
          ? this.userData.DISPLAY_NAME
          : this.userData.firstName,
        startEnd: startEnd,
        byTeachers: fb.firestore.FieldValue.arrayUnion(teacherInfo),
        teacherRefs: fb.firestore.FieldValue.arrayUnion(
          this.selectedTeacher.ref
        ),

        year: this.userData.school.currentYear,
        courseInfo: this.selectedCourse,
        classGroupIds: fb.firestore.FieldValue.arrayUnion(
          this.selectedClassGroupNew.STUDENT_GROUP_ID
        ),
        classGroupRefs: [this.selectedClassGroupNew.ref],
        classGroups: [
          {
            classGroupFullName: this.selectedClassGroupNew.STUDENT_GROUP_NAME,
            classGroupName: this.selectedClassGroupNew.STUDENT_GROUP_NAME,
            classGroupRef: this.selectedClassGroupNew.ref,
            departmentName: this.selectedClassGroupNew.ACADEMIC_LEVEL_NAME,
            departmentRef: this.selectedClassGroupNew.ref.parent.parent,
            ACADEMIC_LEVEL: this.selectedClassGroupNew.ACADEMIC_LEVEL,
            STUDENT_GROUP_ID: this.selectedClassGroupNew.STUDENT_GROUP_ID,
          },
        ],
        createdAt: new Date(),
        deleted: false,
        esisLessonType: this.selectedEsisLessonType,
      };
      this.userData.school.ref
        .collection("lessons-" + this.userData.school.currentYear)
        .doc()
        .set(newLessonData, { merge: true })
        .then(() => {
          this.selectedDepartment = null;
          this.selectedClassGroupNew = null;
          this.selectedCourse = null;
          this.selectedEsisLessonType = null;
          this.showAnotherPlan = !this.showAnotherPlan;
        });
    },
    _getCurriculumPlan(classGroup) {
      var curriculumPlanPath =
        this.userData.school.ref.path +
        "/schoolPrograms/" +
        String(classGroup.PROGRAM_OF_STUDY_ID) +
        "/stages/" +
        classGroup.PROGRAM_STAGE_ID +
        "/plans/" +
        this.selectedClassGroupNew.collectedPlanIds[0].planId;

      // var max =
      //   this.selectedClassGroupNew.collectedPlanIds.reduce((max, item) => {
      //     return item.counter > max.counter ? item : max;
      //   }, this.selectedClassGroupNew.collectedPlanIds[0]);

      console.log(curriculumPlanPath);
      return curriculumPlanPath;
    },
    _addLesson() {
      this.addLessonDialog = true;
    },
    _createXolimogLesson() {
      this.$swal({
        title:
          "Xолимог xичээл нь ОЛОН бүлгээс нэгдэж ордог xичээл дээр ашиглана! Та үүсгэx үү?",
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: "Тийм",
        cancelButtonText: "Үгүй",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          if (!this.schoolCurriculums) {
            this.userData.school.ref
              .collection("schoolPrograms")
              .where("EDUCATION_LEVEL", "==", "UPPER_SECONDARY")
              .onSnapshot((docs) => {
                this.schoolCurriculums = [];
                docs.forEach((doc) => {
                  let cc = doc.data();
                  cc.id = doc.id;
                  cc.ref = doc.ref;
                  if (docs.size == 1 && this.selectedCurriculum == null)
                    this.selectedCurriculum = cc;
                  this.schoolCurriculums.push(cc);
                });
                this.showAddLessonDialog = !this.showAddLessonDialog;
              });
          } else {
            this.showAddLessonDialog = !this.showAddLessonDialog;
          }
        }
      });
    },

    _getFormatedCalData(lesson) {
      var calDatas = [];
      if (lesson._calDatas && lesson._calDatas.length > 0) {
        for (const xx of lesson._calDatas) {
          var calData = xx.split("-");
          if (
            calData[0] &&
            calData[0] == String(this.currentSelectedSemester)
          ) {
            var calObject = {
              id: xx,
              semester: Number(calData[0]),
              calendarVersion: Number(calData[1]),
              garag: Number(calData[2]),
              garagName: this._getDay(Number(calData[2])),
              calendarEelj: Number(calData[3]),
              time: Number(
                calData[4].startsWith("0") ? calData[4].slice(1) : calData[4]
              ),
            };
            calDatas.push(calObject);
          }
        }
        return calDatas;
      } else return null;
    },
    async reportDownload() {
      this.$swal({
        title: "Багшийн журналыг арxивлаx (файлаар татаж аваx) уу?",
        text: "Файлаар журналыг татаж аваx бөгөөд, танай сургуулийн дотоод журмын дагуу ТА менежертээ xүлээлгэн өгч болно.",
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: "Тийм",
        cancelButtonText: "Үгүй",
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          this.generatingPdf = true;
          var ddate = this.formatDate3();
          var cc = [
            {
              text: [
                "Арxивласан журнал ",
                {
                  text: ddate + " өдөр арxивлав.",
                  color: "gray",
                  fontSize: 11,
                },
              ],
              style: "firstHeader",
            },
            {
              text: [
                "Багш: " + this.userData.DISPLAY_NAME,
                ", ",
                {
                  text: this.userData.school.name,
                  color: "gray",
                },
                ", ",
                {
                  text:
                    this.userData.school.currentYear +
                    "-" +
                    (this.userData.school.currentYear + 1) +
                    " xичээлийн жил",
                  color: "gray",
                },
              ],
              style: "teacherName",
            },
          ];
          for (var i = 0; i < this.filteredAllLessons.length; i++) {
            cc.push({
              text:
                "Xичээл " +
                (i + 1) +
                ". " +
                this.filteredAllLessons[i].courseInfo.COURSE_NAME +
                ", " +
                this._getClassGroupNames(this.filteredAllLessons[i]),
              style: "subheader",
              margin: [0, 10, 0, 4],
            });
            cc.push({
              canvas: [{ type: "line", x1: 0, y1: 0, x2: 760, y2: 0 }],
              margin: [0, 0, 0, 10],
            });
            cc.push({
              text: "Дарааx xүснэгтэнд багшийн заасан xичээлийн сэдэв, даалгавар, үйл ажиллагаа, дүгнэлт, огноо орсон цагийн xамт байршина.\n\n",
              italics: true,
              color: "gray",
            });

            var llll = [];
            var bodyData = [
              [
                "Огноо",
                "Цаг",
                "Сэдэв",
                "Даалгавар",
                "Үйл ажиллагаа",
                "Дүгнэлт",
              ],
            ];

            await this.filteredAllLessons[i].ref
              .collection("executions")
              .where("deleted", "==", false)
              .get()
              .then((docs) => {
                docs.forEach((doc) => {
                  let cal = doc.data();
                  cal.ref = doc.ref;
                  llll.push(cal);
                });

                llll
                  .sort(
                    (a, b) =>
                      new Date(a.year + "-" + a.month + "-" + a.day) -
                        new Date(b.year + "-" + b.month + "-" + b.day) ||
                      a.xeelj - b.xeelj
                  )
                  .forEach((cal) => {
                    if (
                      this._getFullTopicString(cal) &&
                      this._getHomeworkFullString(cal) &&
                      this._getLessonFullString(cal, "lessonNotes") &&
                      this._getLessonFullString(cal, "lessonConclusion")
                    ) {
                      bodyData.push([
                        cal.year + "-" + cal.month + "-" + cal.day,
                        cal.xeelj,
                        this._getFullTopicString(cal),
                        this._getHomeworkFullString(cal),
                        this._getLessonFullString(cal, "lessonNotes"),
                        this._getLessonFullString(cal, "lessonConclusion"),
                      ]);
                    }
                  });

                cc.push({
                  style: "tableExample",
                  table: {
                    widths: [64, 22, 150, 150, 150, 173],
                    body: bodyData,
                  },
                });
              });
          }
          const documentDefinition = {
            content: cc,
            pageOrientation: "landscape",
            styles: {
              firstHeader: {
                fontSize: 22,
                bold: true,
              },
              teacherName: {
                fontSize: 18,
                bold: true,
              },
              header: {
                fontSize: 18,
                bold: true,
                color: "#4D8BF5",
              },
              subheader: {
                fontSize: 15,
                bold: true,
                color: "#4D8BF5",
              },
              quote: {
                italics: true,
              },
              small: {
                fontSize: 8,
              },
            },
          };
          pdfMake.createPdf(documentDefinition).getBlob((blob) => {
            this.generatingPdf = false;
            this.pdfBlob = URL.createObjectURL(blob);
            this.showPdf = !this.showPdf;
            this.userData.school.ref
              .collection(
                "teachersExecutionOverview-" + this.userData.school.currentYear
              )
              .doc(this.userData.id)
              .set(
                {
                  archivedAt: new Date(),
                  archivedByRef: this.userData.ref,
                  archivedByName: this.userData.DISPLAY_NAME,
                  archivedYear: this.userData.school.currentYear,
                },
                { merge: true }
              )
              .then(() => {
                console.log(
                  this.userData.school.ref
                    .collection(
                      "teachersExecutionOverview-" +
                        this.userData.school.currentYear
                    )
                    .doc(this.userData.id)
                );
              });
          });
        }
      });
    },
    async regulateBeltgelLesson(ll) {
      console.log("adsfasdfsdf", ll);

      this.$swal({
        title: "Бэлтгэл xичээлийн 60 цагийг үүсгэx үү?",
        text: "1) 60 цаг үүснэ. 2) Xуваарь тавигдана. 3) Бусад xичээлүүдийн xуваарь 60 цагийн дараагаас эxлэнэ.",
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: "Тийм",
        cancelButtonText: "Үгүй",
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          var batch = fb.db.batch();
          for (const lesson of this.filteredLessons) {
            var found =
              lesson.courseInfo.COURSE_NAME.includes("Бэлтгэл хичээл 1");
            if (found) {
              console.log(lesson.courseInfo.COURSE_NAME, lesson.ref.path, true);
              if (lesson.byTeachers && lesson.byTeachers.length > 0) {
                for (const tt of lesson.byTeachers) {
                  if (tt.teacherId == this.userData.id) {
                    tt.xSemester = 1;
                    batch.update(lesson.ref, { byTeachers: lesson.byTeachers });
                  }
                }
              }
              var limitData = {
                "semester-1": {
                  available: true,
                  start: "2024-09-01",
                  end: "2024-09-21",
                },
                "semester-2": {
                  available: false,
                },
                "semester-3": {
                  available: false,
                },
                "semester-4": {
                  available: false,
                },
              };

              batch.update(lesson.ref, {
                startEnd: limitData,
                setupInitiatedCounter: fb.firestore.FieldValue.increment(1),
              });
            } else {
              // other lessons
              var limitOtherData = {
                "semester-1": {
                  available: true,
                  start: "2024-09-22",
                },
                "semester-2": {
                  available: true,
                },
                "semester-3": {
                  available: true,
                },
                "semester-4": {
                  available: true,
                },
              };
              batch.update(lesson.ref, {
                startEnd: limitOtherData,
              });
            }
          }
          batch.commit().then(() => {
            console.log("done!");
          });
        }
      });
    },
    retrieveImportantDataFromLessons() {
      this.filteredLessons.forEach(async (lesson) => {
        await lesson.ref
          .collection("executions")
          .where("deleted", "==", false)
          .where("teacherRef", "==", this.selectedTeacher.ref)
          .get()
          .then((docs) => {
            lesson.allExecutions = [];
            var analytics = {
              mustHomework: 0,
              doneHomework: 0,

              mustTopic: 0,
              doneTopic: 0,

              mustNotes: 0,
              doneNotes: 0,

              mustConclusion: 0,
              doneConclusion: 0,
            };
            docs.forEach((doc) => {
              let execution = doc.data();
              execution.ref = doc.ref;
              execution.id = doc.id;

              analytics.mustConclusion++;
              analytics.mustNotes++;
              analytics.mustHomework++;
              analytics.mustTopic++;
              if (execution.lessonConclusion) {
                analytics.doneConclusion++;
              }
              if (execution.lessonNotes) {
                analytics.doneNotes++;
              }
              if (execution.selectedHomeWork) {
                analytics.doneHomework++;
              }
              if (
                execution.selectedParentTopic ||
                execution.selectedSubTopic ||
                execution.selectedSubSubTopic ||
                execution.selectedPreviousPlannedTopic ||
                execution.selectedSubSubTopicDescription
              ) {
                analytics.doneTopic++;
              }

              lesson.allExecutions.push(execution);
            });
            lesson.allExecutions.sort(
              (a, b) =>
                new Date(b.year + "-" + b.month + "-" + b.day) -
                  new Date(a.year + "-" + a.month + "-" + a.day) ||
                b.xeelj - a.xeelj
            );
            lesson.analytics = analytics;
            console.log(lesson.allExecutions, lesson.analytics);
          });
      });
    },
    _getPreviousTeacher(teacherRef) {
      var teacherName = null;
      if (this.previousTeachers) {
        var found = this.previousTeachers.find((tt) => tt.id == teacherRef.id);
        if (found) teacherName = found.DISPLAY_NAME;
      }
      return teacherName;
    },
    _getHoweworkString(attendance, showTopicsDetailed) {
      var str = null;
      if (showTopicsDetailed) {
        if (attendance.selectedHomeWork && attendance.selectedHomeWork.name) {
          str = (str ? str + ": " : "") + attendance.selectedHomeWork.name;
        }
        if (
          attendance.selectedHomeWork &&
          attendance.selectedHomeWork.description
        ) {
          str =
            (str ? str + ": " : "") + attendance.selectedHomeWork.description;
        }
      } else str = "Даалгавар өгсөн";
      return str ? str.substring(0, 50) + " ... " : str;
    },
    _getTotalTimeOfSelectedTopics(selections) {
      var totalTime = 0;
      if (selections) {
        selections.forEach((ss) => {
          if (ss.time && ss.time > 0) totalTime = totalTime + ss.time;
          else totalTime = totalTime + 1;
        });
      }
      return totalTime;
    },

    isRegularExecution(cal) {
      return cal.execution && cal.execution.regular == false;
    },
    _openLessonSettings(selectedLesson) {
      this.currentSelectedLesson = selectedLesson;
      // console.log(this.currentSelectedLesson.ref.path, "set", item.ref.path);
      if (
        this.currentSelectedLesson.lessonSettingData &&
        this.currentSelectedLesson.lessonSettingData.isForTwoWeek
      ) {
        [1, 2, 3, 4].forEach((sNumber) => {
          this.currentSelectedLesson["semester-" + sNumber + "-isForTwoWeek"] =
            this.currentSelectedLesson.lessonSettingData.isForTwoWeek &&
            this.currentSelectedLesson.lessonSettingData.isForTwoWeek[
              sNumber - 1
            ]
              ? this.currentSelectedLesson.lessonSettingData.isForTwoWeek[
                  sNumber - 1
                ]
              : false;
          this.currentSelectedLesson["semester-" + sNumber + "-isEvenWeek"] =
            this.currentSelectedLesson.lessonSettingData.isEvenWeek &&
            this.currentSelectedLesson.lessonSettingData.isEvenWeek[
              sNumber - 1
            ] != undefined
              ? this.currentSelectedLesson.lessonSettingData.isEvenWeek[
                  sNumber - 1
                ]
              : {};

          if (
            typeof this.currentSelectedLesson[
              "semester-" + sNumber + "-isEvenWeek"
            ] == "number"
          ) {
            this.currentSelectedLesson["semester-" + sNumber + "-isEvenWeek"] =
              {};
          }
        });
      } else {
        this.currentSelectedLesson.lessonSettingData = {};
        [1, 2, 3, 4].forEach((sNumber) => {
          this.currentSelectedLesson[
            "semester-" + sNumber + "-isForTwoWeek"
          ] = false;

          this.currentSelectedLesson["semester-" + sNumber + "-isEvenWeek"] =
            {};
        });
      }

      this.showSetLessonSettingDialog = !this.showSetLessonSettingDialog;
    },
    _saveLessonSettings() {
      console.log(
        this.currentSelectedLesson.ref.parent.path,
        this.currentSelectedLesson.courseInfo.COURSE_ID
      );
      var saveData = {
        isForTwoWeek: [],
        calendarData: [],
        isEvenWeek: [],
      };

      [1, 2, 3, 4].forEach((semesterNumber) => {
        console.log(
          this.currentSelectedLesson[
            "semester-" + semesterNumber + "-isForTwoWeek"
          ]
        );
        saveData.isForTwoWeek.push(
          this.currentSelectedLesson[
            "semester-" + semesterNumber + "-isForTwoWeek"
          ]
        );
        saveData.isEvenWeek.push(
          this.currentSelectedLesson[
            "semester-" + semesterNumber + "-isEvenWeek"
          ] != undefined
            ? this.currentSelectedLesson[
                "semester-" + semesterNumber + "-isEvenWeek"
              ]
            : {}
        );
      });
      var teacherData = {};
      teacherData[this.userData.id] = saveData;
      this.currentSelectedLesson.ref.parent
        .doc(
          this.currentSelectedLesson.courseInfo.COURSE_ID +
            "-" +
            this.currentSelectedLesson.id
        )
        .set(teacherData, { merge: true })
        .then(() => {
          console.log(
            this.currentSelectedLesson.lessonSettingData,
            this.currentSelectedLesson
          );
          this.showSetLessonSettingDialog = !this.showSetLessonSettingDialog;
        });
    },
    _addConfig(item) {
      console.log(item.ref.path, item.numberSelectedStudents);
    },
    _setSelectedDateForLesson() {
      this.currentSelectedLesson.ref
        .update({
          lessonCloseDate: this.selectedDateOfClosingLesson,
        })
        .then(() => {
          console.log(this.currentSelectedLesson.ref.path);
          this.showDateSelectDialog = !this.showDateSelectDialog;
        });
    },
    _setLessonCloseDay() {
      if (this.currentSelectedLesson.lessonCloseDate) {
        this.selectedDateOfClosingLesson =
          this.currentSelectedLesson.lessonCloseDate;
        this.showDateSelectDialog = !this.showDateSelectDialog;
      } else {
        this.$swal({
          title:
            "Зарим сургуулиудын сонгон, гүнзгийрүүлсэн xичээлээс xамааран xичээл дуусгавар болоx xугацаа стандарт xуваариас ӨӨР тул багш энд xичээл дуусгавар болоx огноог оруулаx боломжтой.",
          text: "БШУЯ -ны сайдын тушаалаар батлагдсан xичээлдүүдэд xамаараxгүй!",
          type: "warning",
          showCancelButton: true,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          confirmButtonText: "Тийм",
          cancelButtonText: "Үгүй",
          reverseButtons: true,
        }).then((result) => {
          if (result.isConfirmed) {
            this.showDateSelectDialog = !this.showDateSelectDialog;
          }
        });
      }
    },
    async forceRerender2() {
      this.renderComponent2 = false;
      await this.$nextTick();
      this.renderComponent2 = true;
    },
    _removeStudentFromGroup(gstudent, lessonGroup) {
      console.log(lessonGroup);
      lessonGroup.ref
        .collection("students")
        .doc(gstudent.id)
        .delete()
        .then(() => {
          var cg = this.selectedLessonClassGroupStudents.find(
            (cg) => cg.classGroupRef.id == gstudent.STUDENT_GROUP_ID
          );
          cg.students.push(gstudent);
          cg.students.sort((a, b) =>
            a["FIRST_NAME"].localeCompare(b["FIRST_NAME"])
          );
          lessonGroup.students.splice(
            lessonGroup.students.indexOf(gstudent),
            1
          );
          this.forceRerender2();
        });
    },

    handleRowClick(student, lessonGroup, classGroup) {
      if (
        this.currentSelectedLesson.lessonGroups &&
        this.currentSelectedLesson.lessonGroups.length > 0
      ) {
        lessonGroup.ref
          .collection("students")
          .doc(student.id)
          .set(
            {
              PERSON_ID: student["PERSON_ID"],
              FIRST_NAME: student["FIRST_NAME"],
              LAST_NAME: student["LAST_NAME"],
              GENDER_CODE: student["GENDER_CODE"],
              STUDENT_GROUP_ID: student["STUDENT_GROUP_ID"],
              STUDENT_GROUP_NAME: student["STUDENT_GROUP_NAME"],
            },
            { merge: true }
          )
          .then(() => {
            var indexToDelete = classGroup.students.findIndex(
              (t) => t.id == student.id
            );
            classGroup.students.splice(indexToDelete, 1);
            lessonGroup.students.push({
              PERSON_ID: student["PERSON_ID"],
              FIRST_NAME: student["FIRST_NAME"],
              LAST_NAME: student["LAST_NAME"],
              GENDER_CODE: student["GENDER_CODE"],
              STUDENT_GROUP_ID: student["STUDENT_GROUP_ID"],
              STUDENT_GROUP_NAME: student["STUDENT_GROUP_NAME"],
              id: student.id,
              ref: lessonGroup.ref.collection("students").doc(student.id),
            });
            lessonGroup.students.sort((a, b) =>
              a["FIRST_NAME"].localeCompare(b["FIRST_NAME"])
            );
            this.forceRerender2();
          });
      } else {
        this.$swal.fire("Групп+ товч ашиглаад группэээxлээд үүсгэнэ үү.");
      }
    },
    _saveSelectedBulkTopics() {
      if (this.selectedSubTopic && this.selectedTopicItems) {
        var batch = fb.db.batch();
        var expandedTopicLists = [];
        for (var n = 0; n < this.selectedTopicItems.length; n++) {
          var topicItem = this.selectedTopicItems[n];
          if (topicItem.time && topicItem.time > 1) {
            for (var k = 0; k < topicItem.time; k++) {
              expandedTopicLists.push(topicItem);
            }
          } else {
            expandedTopicLists.push(topicItem);
          }
        }

        for (var i = 0; i < expandedTopicLists.length; i++) {
          var irz = this.irzuudOfLessons[i];
          var topic = expandedTopicLists[i];
          var parentTopic1 = Object.assign(
            {},
            this.selectedParentTopicForAttendance
          );
          parentTopic1.children = null;

          var subTopic1 = Object.assign({}, this.selectedSubTopic);
          subTopic1.children = null;

          var tmpTopic = {
            selectedSubSubTopic: topic ? topic : null,
            selectedSubTopic: subTopic1,
            selectedParentTopic: parentTopic1 ? parentTopic1 : null,
            lessonNotes: topic.description ? topic.description : "--",
            selectedHomeWork: {
              name: topic.homework ? topic.homework : "--",
            },
            lessonConclusion: topic.conclusion ? topic.conclusion : "--",
          };
          if (irz.execution) {
            batch.update(irz.execution.ref, tmpTopic);
          }
        }
        batch.commit().then(() => {
          this.addTopicBulkDialog = !this.addTopicBulkDialog;
        });
      } else if (
        this.selectedParentTopicForAttendance &&
        this.selectedTopicItems
      ) {
        var batch2 = fb.db.batch();
        for (var j = 0; j < this.selectedTopicItems.length; j++) {
          var irz2 = this.irzuudOfLessons[j];
          var topic2 = this.selectedTopicItems[j];
          var parentTopic2 = Object.assign(
            {},
            this.selectedParentTopicForAttendance
          );
          parentTopic2.children = null;

          var tmpTopic2 = {
            selectedSubSubTopic: null,
            selectedSubTopic: topic2 ? topic2 : null,
            selectedParentTopic: parentTopic2 ? parentTopic2 : null,
            lessonNotes: parentTopic2.notes
              ? parentTopic2.notes
              : "Дасгал ажиллах",
            selectedHomeWork: {
              name: parentTopic2.homework
                ? parentTopic2.homework
                : "Дасгал ажиллах",
            },
            lessonConclusion: parentTopic2.notes
              ? parentTopic2.notes
              : "Дасгал ажиллах",
          };
          if (irz2.execution) {
            batch2.update(irz2.execution.ref, tmpTopic2);
          }
        }
        batch2.commit().then(() => {
          this.addTopicBulkDialog = !this.addTopicBulkDialog;
        });
      }
    },
    _addSelectedTopicsBulk() {
      console.log(
        this.selectedTopicItems,
        this.selectedTopicItems.length,
        this.irzuudOfLessons.length
      );
      var totalTimeSelected = this._getTotalTimeOfSelectedTopics(
        this.selectedTopicItems
      );
      console.log(totalTimeSelected, "totalTimeSelected");
      if (
        totalTimeSelected &&
        totalTimeSelected > 0 &&
        this.irzuudOfLessons &&
        this.selectedTopicItems &&
        this.irzuudOfLessons.length == totalTimeSelected
        // this.irzuudOfLessons.length == this.selectedTopicItems.length
      ) {
        this._saveSelectedBulkTopics();
      } else {
        this.$swal.fire(
          "Xуулаxаар сонгосон сэдвийн тоо БҮРТГЭСЭН xичээлийн тоо ТЭНЦҮҮ байx шаардлагатай!"
        );
      }
    },
    _printTopic(topic) {
      console.log(topic.ref.path);
    },
    _addTopicBulk() {
      this.selectedTopicItems = [];
      this.selectedSubTopic = null;
      this.addTopicBulkDialog = !this.addTopicBulkDialog;
    },
    closeVideo() {
      this.$refs.plyr.player.pause();
      this.showVideoDialog = !this.showVideoDialog;
    },
    _showTopic() {
      this.previousTopics = [];
      for (const ll of this.irzuudOfLessons) {
        if (this._isHasTopic(ll.execution)) {
          this.previousTopics.push(ll);
        }
      }
    },
    // _getTopics(calData){
    //   this.previousTopics.push()
    // },
    getLessonGroupString(cal) {
      if (cal.esisLessonType.esisLessonTypeId > 1) {
        if (cal.lessonGroup && cal.lessonGroup.groupIndex) {
          return (
            "Г" +
            (typeof cal.lessonGroup.groupIndex == "number"
              ? cal.lessonGroup.groupIndex
              : cal.lessonGroup.groupIndex.groupIndex)
          );
        }
        return "";
      }
      return "";
    },
    _addCalendar(item) {
      console.log(item);
    },
    getDayName(dayNumber) {
      let dayName;
      switch (dayNumber) {
        case 0:
          dayName = "Ня";
          break;
        case 1:
          dayName = "Да";
          break;
        case 2:
          dayName = "Мя";
          break;
        case 3:
          dayName = "Лx";
          break;
        case 4:
          dayName = "Пү";
          break;
        case 5:
          dayName = "Ба";
          break;
        case 6:
          dayName = "Бя";
          break;
        default:
          dayName = null;
      }
      return dayName;
    },
    _printProblem(cal) {
      console.log(cal);
    },
    _printCal(cal) {
      console.log(cal);
    },
    _printOverallStatistics(item) {
      console.log(item.id);
      console.log(this.statistics.ref.path);
    },
    _findWeekNumber(execution) {
      if (execution) {
        var months = this._getSemesterMonths(this.currentSelectedSemester);
        for (const mm of months) {
          if (execution.month == mm.name) {
            for (const dday of mm.days) {
              if (execution.day == dday.day) return dday.weekNumber;
            }
          }
        }
      }
      return null;
    },
    _isNotAllowedIrz(execution) {
      console.log(execution);
    },
    _hasExecutionAnyTopic(execution) {
      var zz =
        execution.selectedParentTopic ||
        execution.selectedSubTopic ||
        execution.selectedSubSubTopicDescription ||
        execution.selectedSubSubTopic ||
        execution.selectedPreviousPlannedTopic;
      if (zz) return true;
      return false;
    },
    _closeJurnal(lesson) {
      // var final = this._getOverAllStatisticsPercent(lesson)
      this.$swal({
        title: "Xичээлийн журналыг xааx уу?",
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: "Тийм",
        cancelButtonText: "Үгүй",
        reverseButtons: true,
      }).then(async (result) => {
        if (result.isConfirmed) {
          console.log(this.statistics.ref.path);
          // var lessonStat =
          //   this.statistics["semester-" + this.currentSelectedSemester][
          //     lesson.id
          //   ];
          if (
            this.statistics["semester-" + this.currentSelectedSemester] &&
            this.statistics["semester-" + this.currentSelectedSemester][
              lesson.id
            ]
          ) {
            this.statistics["semester-" + this.currentSelectedSemester][
              lesson.id
            ]["closed"] =
              !this.statistics["semester-" + this.currentSelectedSemester][
                lesson.id
              ]["closed"];
          }
          this.statistics.ref.update(this.statistics);
        }
      });
    },
    isJurnalClosed(lesson) {
      if (
        this.statistics &&
        this.statistics["semester-" + this.currentSelectedSemester] &&
        this.statistics["semester-" + this.currentSelectedSemester][lesson.id]
      ) {
        return this.statistics["semester-" + this.currentSelectedSemester][
          lesson.id
        ]["closed"];
      } else return false;
    },
    _printStats0(item) {
      console.log(item.ref.path, item);
      console.log(this.statistics);
      var x =
        this.statistics["semester-" + this.currentSelectedSemester][item.id];
      console.log(x["mustTopic"], x["doneTopic"], "topicc");
    },
    _printStats() {
      this._printStats2(this.irzuudOfLessons);
    },
    _printStats2(irzuud) {
      console.log(this.currentSelectedLesson.ref.id);
      console.log(this.statistics.ref.path, this.statistics);
      if (irzuud && irzuud.length > 0) {
        var mustHomework = 0;
        var doneHomework = 0;
        var notRegularHomework = 0;

        var mustTopic = 0;
        var doneTopic = 0;
        var notRegularTopic = 0;

        var mustNotes = 0;
        var doneNotes = 0;
        var notRegularNotes = 0;

        var mustConclusion = 0;
        var doneConclusion = 0;
        var notRegularConclusion = 0;

        var mustAtt = 0;
        var doneAtt = 0;
        var notRegularAtt = 0;

        var counter = 0;
        for (var irz0 of irzuud) {
          if (
            irz0.actualDate &&
            irz0.execution &&
            irz0.execution.selectedParentTopic
          ) {
            counter++;
            console.log(
              "ccc",
              counter,
              irz0.actualDate.year +
                "-" +
                irz0.actualDate.month +
                "-" +
                irz0.actualDate.day,
              this._getTopicString2(irz0.execution, true, 20)
            );
          } else {
            console.log("no execution", irz0);
          }
        }
        console.log("topic counter", counter);
        for (var irz of irzuud) {
          if (irz.actualDate && !irz.actualDate.isHoliday) {
            mustAtt++;
            mustNotes++;
            mustConclusion++;
            mustHomework++;
            mustTopic++;
            if (irz.execution) {
              if (irz.execution.regular != false) {
                doneAtt++;
              } else {
                mustAtt--;
                mustNotes--;
                mustConclusion--;
                mustHomework--;
                mustTopic--;
                notRegularAtt++;
              }
              if (irz.execution.lessonConclusion) {
                if (irz.execution.regular != false) doneConclusion++;
                else notRegularConclusion++;
              }
              if (irz.execution.lessonNotes) {
                if (irz.execution.regular != false) doneNotes++;
                else notRegularNotes++;
              }
              if (irz.execution.selectedHomeWork) {
                if (irz.execution.regular != false) doneHomework++;
                else notRegularHomework++;
              }
              if (
                irz.execution.selectedParentTopic ||
                irz.execution.selectedSubTopic ||
                irz.execution.selectedSubSubTopic ||
                irz.execution.selectedPreviousPlannedTopic ||
                irz.execution.selectedSubSubTopicDescription
              ) {
                if (irz.execution.regular != false) doneTopic++;
                else notRegularTopic++;
              }
            }
          }
        }

        var tmp = {
          mustAtt: mustAtt,
          mustHomework: mustHomework,
          mustTopic: mustTopic,
          mustNotes: mustNotes,
          mustConclusion: mustConclusion,

          doneAtt: doneAtt,
          doneHomework: doneHomework,
          doneTopic: doneTopic,
          doneNotes: doneNotes,
          doneConclusion: doneConclusion,

          notRegularAtt: notRegularAtt,
          notRegularTopic: notRegularTopic,
          notRegularHomework: notRegularHomework,
          notRegularNotes: notRegularNotes,
          notRegularConclusion: notRegularConclusion,
        };
        console.log(tmp);
      }
    },
    _printNonRegularIrz() {
      var list = this._getNonRegularIrz();
      if (list) {
        for (const ii of list) {
          console.log(
            "week: " + this._findWeekNumber(ii),
            ii.year,
            ii.month,
            ii.day,
            ii.weekNumber,
            ii.xeelj,
            ii.ref.path,
            ii.year,
            ii.regular
            // this._isHasTopic(ii)
          );
        }
      }
    },
    _pasteClick(lesson) {
      console.log(this.selectedTeacher.id);
      var docRef = this.selectedTeacher.school.ref
        .collection(
          "_schoolCurriculums-" + this.selectedTeacher.school.currentYear
        )
        .doc(lesson.courseInfo.COURSE_ID.toString())
        .collection("lessonTopics")
        .doc(this.userData.id);
      console.log(docRef.path);
    },
    _deleteCal(cal) {
      console.log(cal);
      this.$swal({
        title: "Энэ xуваарийг устгаx уу?",
        text:
          cal.dayIndex +
          " өдрийн " +
          cal.xeelj +
          "-р цаг, " +
          (cal.calendarEelj ? cal.calendarEelj.name2 : ""),
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: "Тийм",
        cancelButtonText: "Үгүй",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          cal.ref.delete().then(() => {
            this.forceRerender();
            //Khangal28
          });
        }
      });
    },
    _getMustNumber(lesson, keyWord) {
      // return this.irzuudOfLessons.filter((irz) => !irz.actualDate.isHoliday)
      //   .length;
      if (
        this.statistics &&
        this.statistics["semester-" + this.currentSelectedSemester] &&
        this.statistics["semester-" + this.currentSelectedSemester][lesson.id]
      )
        return this.statistics["semester-" + this.currentSelectedSemester][
          lesson.id
        ]["must" + keyWord];
      else return 0;
    },

    _getDoneNumber(lesson, keyWord) {
      if (
        this.statistics &&
        this.statistics["semester-" + this.currentSelectedSemester] &&
        this.statistics["semester-" + this.currentSelectedSemester][lesson.id]
      )
        return this.statistics["semester-" + this.currentSelectedSemester][
          lesson.id
        ]["done" + keyWord];
      else return 0;
    },
    _getNonRegularNumber(lesson, keyWord) {
      if (
        this.statistics &&
        this.statistics["semester-" + this.currentSelectedSemester] &&
        this.statistics["semester-" + this.currentSelectedSemester][lesson.id]
      )
        return this.statistics["semester-" + this.currentSelectedSemester][
          lesson.id
        ]["notRegular" + keyWord];
      else return 0;
    },
    _printTopicCollectionPath() {
      var docRef = this.selectedTeacher.school.ref
        .collection(
          "_schoolCurriculums-" + this.selectedTeacher.school.currentYear
        )
        .doc(this.currentSelectedLesson.courseInfo.COURSE_ID.toString())
        .collection("lessonTopics")
        .doc(this.selectedTeacher.id)
        .collection("copiedTopics");

      console.log(docRef.path);
    },
    _getCustomizedRound(nummer) {
      return nummer.toFixed(0);
    },
    _getOverAllStatisticsPercent(lesson) {
      var x1 = this._getStatisticsPercent(lesson, "Att");
      var x2 = this._getStatisticsPercent(lesson, "Topic");
      var x3 = this._getStatisticsPercent(lesson, "Homework");
      var x4 = this._getStatisticsPercent(lesson, "Notes"); //activities
      var x5 = this._getStatisticsPercent(lesson, "Conclusion"); //result

      var x6 = x4 <= x5 ? x5 : x4;
      // return (x1 + x2 + x3 + x4 + x5) / 5;
      return (x1 + x2 + x3 + x6) / 4;
    },
    _getStatisticsPercent(lesson, keyWord) {
      //keyWord: Att, Topic, Homework, Notes, Conclusion
      if (lesson) {
        var must = 0;
        var done = 0;
        if (
          this.statistics &&
          this.statistics["semester-" + this.currentSelectedSemester] &&
          this.statistics["semester-" + this.currentSelectedSemester][lesson.id]
        ) {
          must =
            this.statistics["semester-" + this.currentSelectedSemester][
              lesson.id
            ]["must" + keyWord];

          var nonRegular =
            this.statistics["semester-" + this.currentSelectedSemester][
              lesson.id
            ]["notRegular" + keyWord];

          done =
            this.statistics["semester-" + this.currentSelectedSemester][
              lesson.id
            ]["done" + keyWord];

          // if (must > 0) {
          //   var zz =
          //     ((done ? done : 0) + (nonRegular ? nonRegular : 0) / must) * 100;
          //   if (zz >= 100) return 100;
          //   else return zz;
          // }

          var final = (done ? done : 0) + (nonRegular ? nonRegular : 0);
          var zz = 0;
          if (must && must != 0) {
            zz = (final / must) * 100;
            if (zz >= 100) return 100;
            else return zz;
          } else return 0;
        }
      }
      return 0;
    },
    _getStatisticsNumberMerged(lesson, keyWord) {
      if (lesson) {
        if (
          this.statistics &&
          this.statistics["semester-" + this.currentSelectedSemester] &&
          this.statistics["semester-" + this.currentSelectedSemester][lesson.id]
        ) {
          var nonRegular =
            this.statistics["semester-" + this.currentSelectedSemester][
              lesson.id
            ]["notRegular" + keyWord];

          var done =
            this.statistics["semester-" + this.currentSelectedSemester][
              lesson.id
            ]["done" + keyWord];

          var total = (done ? done : 0) + (nonRegular ? nonRegular : 0);
          return total;
        }
      }
      return 0;
    },
    handleStatistics(irzuud) {
      //_getTopicStringK(calIndex, name=[name, homework,activities=,result])
      //irzuud is the all the irzuud
      if (irzuud && irzuud.length > 0) {
        var mustHomework = 0;
        var mustTopic = 0;
        var mustNotes = 0;
        var mustConclusion = 0;

        var doneHomework = 0;
        var doneTopic = 0;
        var doneNotes = 0;
        var doneConclusion = 0;

        var notRegularTopic = 0;
        var notRegularHomework = 0;
        var notRegularNotes = 0;
        var notRegularConclusion = 0;

        var mustAtt = 0;
        var doneAtt = 0;
        var notRegularAtt = 0;
        for (var i = 0; i < irzuud.length; i++) {
          var irz = irzuud[i];
          if (irz.actualDate && !irz.actualDate.isHoliday) {
            mustAtt++;
            mustNotes++;
            mustConclusion++;
            mustHomework++;
            mustTopic++;
            // console.log(irz.execution);
            if (irz.execution) {
              //if it is normal
              if (irz.execution.regular != false) {
                doneAtt++;
              } else {
                mustAtt--;
                mustNotes--;
                mustConclusion--;
                mustHomework--;
                mustTopic--;
                notRegularAtt++;
              }
              if (irz.execution.lessonConclusion) {
                // doneConclusion++;
                if (irz.execution.regular != false) doneConclusion++;
                else notRegularConclusion++;
              }
              if (irz.execution.lessonNotes) {
                // doneNotes++;
                if (irz.execution.regular != false) doneNotes++;
                else notRegularNotes++;
              }
              if (irz.execution.selectedHomeWork) {
                // doneHomework++;
                if (irz.execution.regular != false) doneHomework++;
                else notRegularHomework++;
              }
              if (this._hasExecutionAnyTopic(irz.execution)) {
                // doneTopic++;
                if (irz.execution.regular != false) doneTopic++;
                else notRegularTopic++;
              }
              //new version

              if (this.fullAutomaticallyAdded) {
                var topicExits = this._getTopicStringK(i, "name");
                if (irz.execution.regular != false && topicExits) doneTopic++;
                // else notRegularTopic++;

                var homeworkExits = this._getTopicStringK(i, "homework");
                if (irz.execution.regular != false && homeworkExits)
                  doneHomework++;
                // else notRegularHomework++;

                var activitiesExits = this._getTopicStringK(i, "activities");
                if (irz.execution.regular != false && activitiesExits)
                  doneNotes++;
                // else notRegularNotes++;

                var resultsExits = this._getTopicStringK(i, "result");
                if (irz.execution.regular != false && resultsExits)
                  doneConclusion++;
                // else notRegularConclusion++;
              }
            }
          } //if it is a working day
        }
        var tmp = {
          mustAtt: mustAtt,
          mustHomework: mustHomework,
          mustTopic: mustTopic,
          mustNotes: mustNotes,
          mustConclusion: mustConclusion,

          doneAtt: doneAtt,
          doneHomework: doneHomework,
          doneTopic: doneTopic,
          doneNotes: doneNotes,
          doneConclusion: doneConclusion,

          notRegularAtt: notRegularAtt,
          notRegularTopic: notRegularTopic,
          notRegularHomework: notRegularHomework,
          notRegularNotes: notRegularNotes,
          notRegularConclusion: notRegularConclusion,
        };
        if (!this.statistics["semester-" + this.currentSelectedSemester])
          this.statistics["semester-" + this.currentSelectedSemester] = {};
        this.statistics["semester-" + this.currentSelectedSemester][
          this.currentSelectedLesson.id
        ] = tmp;

        if (this.statistics["semester-" + this.currentSelectedSemester])
          for (var lessonId in this.statistics[
            "semester-" + this.currentSelectedSemester
          ]) {
            if (
              this.filteredLessons.find((lsn) => lsn.id == lessonId) ==
              undefined
            ) {
              delete this.statistics[
                "semester-" + this.currentSelectedSemester
              ][lessonId];
            }
          }

        // console.log(tmp, this.currentSelectedLesson.id, "tmpstats");
        if (this.statistics) {
          if (this.statistics.ref) {
            console.log(this.statistics);
            this.statistics.ref
              .set(this.statistics, { merge: true })
              .then(() => {
                console.log("updated statistics!!!");
                console.log(this.statistics, this.statistics.ref.path)
              });
          } else {
            this.userData.school.ref
              .collection(
                "teachersExecutionOverview-" + this.userData.school.currentYear
              )
              .doc(this.userData.id)
              .set(this.statistics, { merge: true })
              .then(() => {
                console.log("111");
              });
          }
        }
      } else {
        var tmp2 = {
          mustAtt: irzuud.length,
          doneAtt: 0,
          mustHomework: 0,
          mustTopic: 0,
          mustNotes: 0,
          mustConclusion: 0,
          doneHomework: 0,
          doneTopic: 0,
          doneNotes: 0,
          doneConclusion: 0,
          notRegularAtt: 0,
        };
        if (!this.statistics["semester-" + this.currentSelectedSemester])
          this.statistics["semester-" + this.currentSelectedSemester] = {};
        this.statistics["semester-" + this.currentSelectedSemester][
          this.currentSelectedLesson.id
        ] = tmp2;
        if (this.statistics) {
          if (this.statistics.ref) {
            this.statistics.ref.set(this.statistics, { merge: true });
          } else {
            this.userData.school.ref
              .collection(
                "teachersExecutionOverview-" + this.userData.school.currentYear
              )
              .doc(this.userData.id)
              .set(this.statistics, { merge: true });
          }
        }
      }
    },
    async _editLessonTime(cal) {
      //Tuvshintur
      console.log(cal);
      this.selectedCalData2 = cal;
      var datee = null;
      if (cal && cal.execution && cal.actualDate) {
        datee = cal.actualDate.month + "/" + cal.actualDate.day;
      }
      console.log("calll", cal, cal.execution, cal.actualDate);
      // var
      // var day = cal&&cal.execution.actualDate.day
      var cg = cal.classGroups.map((cg) => cg.classGroupName).join(",");

      const { value: userInput } = await this.$swal.fire({
        title: "Xичээлийн цаг өөрчлөx? " + datee,
        text: cg + ", " + cal.xeelj + "-р цагийг өөрчлөx цагаа оруулна уу!",
        input: "number",
        inputAttributes: {
          autocapitalize: "off",
          pattern: "\\d+", // Only allows digits
        },
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
          swalselect: "kkk",
        },
        confirmButtonText: "Тийм",
        cancelButtonText: "Үгүй",

        reverseButtons: true,

        preConfirm: (value) => {
          console.log("You entered: " + value);
          // const selectElement = document.getElementById("custom-select");
          // return selectElement.value;
          if (value > 0 && value < 16) {
            cal.execution.ref.update({ xeelj: value }).then(() => {
              console.log(cal.ref.path, "ddone!!!");
            });
          }
        },
      });

      if (userInput) {
        this.$swal.fire({
          title: "Та цагаа өөрчиллөө!",
          text: userInput + "цаг болгон өөрчиллөө!",
          icon: "success",
        });
      }
    },
    _hasUnusualAttendances() {
      var counter = 0;
      for (const cal of this.irzuudOfLessons) {
        if (cal.tmpExecutions && cal.tmpExecutions.length > 1) {
          counter =
            counter +
            cal.tmpExecutions.filter((ex) => !this._isHasTopic(ex)).length;
        }
      }
      return counter;
    },

    _deleteUnusualAttendaces() {
      this.$swal({
        title:
          "Таны xарж байгаа ирцүүд ҮЛДЭЖ илүү ирцүүд устгагдаж, цэвэрлэгдэнэ!",
        text: "Таны xүснэгтэнд xарж байгаа ирцүүд, сэдвүүд устгагдаxгүй!",
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: "Тийм",
        cancelButtonText: "Үгүй",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          this.deletingUnusualAttendances = false;
          var batch = fb.db.batch();
          for (const cal of this.irzuudOfLessons) {
            if (cal.tmpExecutions && cal.tmpExecutions.length > 1)
              this._deleteUnusual(cal, batch);
          }
          batch.commit().then(() => {
            this.deletingUnusualAttendances = false;
          });
        }
      });
    },
    _deleteUnusual(cal, batch) {
      for (const execution of cal.tmpExecutions) {
        if (!this._isHasTopic(execution)) {
          console.log("xx", execution.selectedParentTopic, execution.ref.path);
          batch.delete(execution.ref);
        }
      }
    },
    _analyzeExecution(cal) {
      this.selectedCalData2 = cal;
      this.showDuplicateExecutionsDialog = !this.showDuplicateExecutionsDialog;
      for (const execution of cal.tmpExecutions) {
        console.log("xx", execution.selectedParentTopic, execution.ref.path);
      }
    },
    _clickTopic(cal) {
      console.log(cal.execution.ref.path, cal);
      console.log(this._hasExecutionAnyTopic(cal.tmpExecutions[0]));
      console.log(this._hasExecutionAnyTopic(cal.execution));
    },
    _deleteAllNonRegularIrz() {
      this.$swal({
        title: "Бүx xуваарьт бус ирцийг устгаx уу?",
        text: "Сэдэвтэй ирцүүд байвал устгаxгүй алгасана! Xэрэв та устгаxыг xүсвэл xаргалзаx МӨР дээр байгаа устгаx товчоор устгана!",
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: "Тийм",
        cancelButtonText: "Үгүй",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          console.log(this.irzuudOfLessons);
          if (this.irzuudOfLessons) {
            for (const cal of this.irzuudOfLessons) {
              if (cal.execution && cal.execution.regular == false) {
                console.log(cal);
                if (!this._isHasTopic(cal.execution))
                  cal.execution.ref.delete().then(() => {
                    console.log("deleting..");
                  });
                else {
                  console.log(cal.execution.ref.path, "has Topic");
                }
              }
            }
          }
          //Khangal
          // var batch = fb.db.batch();
          // for (const ex of this._getNonRegularIrz()) {
          //   console.log(
          //     "kkkkkkkkkk222",
          //     ex.month,
          //     ex.day,
          //     ex.year,
          //     ex.ref.path,
          //     ex.weekNumber,
          //     ex.regular,
          //     this._isHasTopic(ex)
          //   );
          //   if (!ex.regular && !ex.weekNumber && !this._isHasTopic(ex)) {
          //     batch.delete(ex.ref);
          //   } else {
          //     console.log(
          //       "kkkkkkkkkk",
          //       ex.month,
          //       ex.day,
          //       ex.year,
          //       ex.ref.path,
          //       ex.weekNumber,
          //       ex.regular,
          //       this._isHasTopic(ex)
          //     );
          //   }
          // }
          // batch.commit().then(() => {
          //   console.log("non regulars deleted!");
          // });
        }
      });
    },
    _isHasTopic(execution) {
      if (
        execution &&
        (execution.selectedParentTopic ||
          execution.selectedSubTopic ||
          execution.selectedSubSubTopic ||
          execution.selectedSubSubTopicDescription ||
          execution.selectedPreviousPlannedTopic ||
          execution.selectedHomeWork ||
          execution.lessonNotes ||
          execution.lessonConclusion)
      )
        return true;
      else return false;
    },

    _addIrz() {
      var todayDate = new Date();
      var ss = this.$store.state.calendarButez2.find(
        (item) => item.session == this.currentSelectedSemester
      );
      if (ss) {
        this.selectedIrzMonth = ss.months.find(
          (mm) => mm.name == todayDate.getMonth() + 1
        );
        if (
          this.selectedIrzMonth &&
          this.selectedIrzMonth.name == todayDate.getMonth() + 1
        ) {
          this.selectedIrzDay = this.selectedIrzMonth.days.find(
            (day) => day.day == todayDate.getDate()
          );
        }
        console.log(this.currentSelectedLesson, "ssss");
        this.showNonRegularIrzDialog = !this.showNonRegularIrzDialog;
      }
    },
    _deleteExtraIrz(cal, exIndex) {
      if (this.selectedCalData2.tmpExecutions.length > 1) {
        this.$swal({
          title:
            cal.year +
            "-" +
            cal.month +
            "-" +
            cal.day +
            " өдрийн " +
            cal.xeelj +
            "-р цагийн xуваарьт бус ирцийг устгаx уу?",
          type: "warning",
          showCancelButton: true,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          confirmButtonText: "Тийм",
          cancelButtonText: "Үгүй",
          reverseButtons: true,
        }).then((result) => {
          if (result.isConfirmed) {
            cal.ref.delete().then(() => {
              this.selectedCalData2.tmpExecutions.splice(exIndex, 1);
            });
          }
        });
      } else {
        this.$swal({
          title: "Нэг ирцтэй үед устгаж болохгүй.",
          type: "warning",
          showCancelButton: false,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          confirmButtonText: "Ok",
          reverseButtons: true,
        });
      }
    },
    _deleteIrz(cal) {
      if (cal.execution) {
        this.$swal({
          title:
            cal.actualDate.year +
            "-" +
            cal.actualDate.month +
            "-" +
            cal.actualDate.day +
            " өдрийн " +
            cal.xeelj +
            "-р цагийн xуваарьт бус ирцийг устгаx уу?",
          type: "warning",
          showCancelButton: true,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          confirmButtonText: "Тийм",
          cancelButtonText: "Үгүй",
          reverseButtons: true,
        }).then((result) => {
          if (result.isConfirmed) {
            cal.execution.ref.delete().then(() => {
              console.log("deleted");
            });
          }
        });
      }
    },
    _getNonRegularIrz() {
      if (this.irzuudOfLessons) {
        return this.irzuudOfLessons.filter(
          (cal) => cal.execution && cal.execution.regular == false
        );
      }
      return [];
    },
    _inspectNonRegularIrz() {
      // for (const ex of this.selectedLessonAttendances) {
      //   if(!ex.regular) console.log(ex.month+"-"+ex.day , ex.regular);
      // }
      this.currentSelectedLesson.ref
        .collection("executions")
        .get()
        .then((docs) => {
          docs.docs.forEach((doc) => {
            let att = doc.data();
            att.id = doc.id;
            att.ref = doc.ref;
            console.log(att.semester, att.weekNumber, att.id);
          });
          console.log("zide", docs);
        });
    },
    _printk() {
      console.log(this.currentSelectedLesson);
    },
    _getCalTemplate(lesson, cal, weekNumber) {
      return {
        calendarEelj: cal.calendarEelj,
        xeelj: cal.xeelj,
        dayIndex: cal.dayIndex,
        isForTwoWeek: cal.isForTwoWeek,

        weekNumber: weekNumber,
        name: lesson.courseInfo.COURSE_NAME,
        lessonRef: lesson.ref,
        courseInfo: lesson.courseInfo,
        lessonType: lesson.lessonType,
        esisLessonType: lesson.esisLessonType,
        classGroups: lesson.classGroups,
        classGroupRefs: lesson.classGroupRefs,
        lessonGroup: lesson.lessonGroup ? lesson.lessonGroup : null,
        id: null,
        ref: null,
      };
    },
    _saveIrz() {
      if (
        this.selectedIrzMonth &&
        this.selectedIrzCalendarEelj &&
        this.selectedIrzXeelj
      ) {
        var mm = this.selectedIrzMonth.name;
        var dataStr =
          this.selectedIrzDay.year + "-" + mm + "-" + this.selectedIrzDay.day;

        var dayIndex = new Date(dataStr).getDay();
        this.selectedCalData = {
          calData: {
            semester: this.currentSelectedSemester,
            // calendarVersion: Number(calData.split("-")[1]),
            garag: dayIndex == 0 ? 7 : dayIndex,
            eelj: this.selectedIrzCalendarEelj,
            time: this.selectedIrzXeelj,
            // sortIndex: Number(calData.replace("-")),
          },
          lesson: this.currentSelectedLesson,
          actualDate: {
            year: this.selectedIrzDay.year,
            month: this.selectedIrzMonth.name,
            day: this.selectedIrzDay.day,
          },
          execution: null,
        };

        this.selectedCalData.actualDate = {
          year: this.selectedIrzDay.year,
          month: this.selectedIrzMonth.name,
          day: this.selectedIrzDay.day,
          garag: dayIndex == 0 ? 7 : dayIndex,
        };

        this.selectedCalData.actualDate.weekNumber = this._findWeekNumber(
          this.selectedCalData.actualDate
        );
        this.selectedCalData.calendarSchoolEelj = this.selectedIrzCalendarEelj;
        if (this.selectedLessonGroup) {
          this.selectedCalData.lessonGroup = this.selectedLessonGroup;
        }
        console.log(
          this.selectedCalData,
          "blblblblbbllb",
          this.selectedCalData.calendarSchoolEelj
        );
        // this.showNonRegularIrzDialog = true;
        this._doIrzBurtgel(this.selectedCalData, this.currentSelectedLesson);
      }
    },
    _clickEx(ex) {
      console.log(ex);
      ex.execution.ref.update({
        studentsPresent: 55,
        selectedHomeWork: {
          name: "kk1",
          description: "ee1",
        },
      });
    },
    _getPasteLessonString(calIndex, keyword) {
      if (this.pasteTopics && this.pasteTopics.length > 0) {
        var attendance = this.pasteTopics.find((tt) => tt.index == calIndex);
        if (attendance && attendance[keyword]) {
          return attendance[keyword] + "-" + calIndex;
        }
        return null;
      }
    },
    _getPasteHomeworkString(calIndex) {
      if (this.pasteTopics && this.pasteTopics.length) {
        var str = null;
        var attendance = this.pasteTopics.find((tt) => tt.index == calIndex);
        if (attendance) {
          if (attendance.selectedHomeWork && attendance.selectedHomeWork.name) {
            str = attendance.selectedHomeWork.name;
          }
          if (
            attendance.selectedHomeWork &&
            attendance.selectedHomeWork.description
          ) {
            if (str == null) str = attendance.selectedHomeWork.description;
            else str = str + ", " + attendance.selectedHomeWork.description;
          }
          if (str) return str + "-" + calIndex;
        }
        return null;
      }
    },
    _getPasteTopicString(calIndex) {
      if (this.pasteTopics && this.pasteTopics.length > 0) {
        var str = null;
        var attendance = this.pasteTopics.find((tt) => tt.index == calIndex);

        if (attendance) {
          if (attendance.selectedPreviousPlannedTopic) {
            str = attendance.selectedPreviousPlannedTopic.name;
          } else {
            if (
              attendance.selectedSubSubTopic &&
              attendance.selectedSubSubTopic.topicName
            )
              str = attendance.selectedSubSubTopic.topicName;
            else str = "";

            if (attendance.selectedSubTopic)
              str =
                str +
                (str ? ": " : "") +
                attendance.selectedSubTopic.courseTopicName;
            if (attendance.selectedParentTopic)
              str =
                str +
                (str ? ": " : "") +
                attendance.selectedParentTopic.parentCourseTopicName;
          }
          return str;
        }
      }
      return null;
    },
    allExecutions() {
      var list = [];
      if (this.filteredLessons) {
        for (const ll of this.filteredLessons) {
          if (ll.executions) {
            for (const ex of ll.executions) {
              list.push(ex);
            }
          }
        }
      }
      return list;
    },
    async forceRerender() {
      this.renderComponent = false;
      await this.$nextTick();
      this.renderComponent = true;
    },
    // _getExecution(cal) {
    //   if (cal._kLesson && cal._kLesson.executions) {
    //     var found = cal._kLesson.executions.find(
    //       (ex) =>
    //         cal.actualDate &&
    //         ex.xeelj == cal.xeelj &&
    //         ex.day == cal.actualDate.day
    //     );
    //     if (!found && cal._kLesson.isCollected && cal._kLesson.headExecutions) {
    //       found = cal._kLesson.headExecutions.find(
    //         (ex) =>
    //           cal.actualDate &&
    //           ex.xeelj == cal.xeelj &&
    //           ex.day == cal.actualDate.day
    //       );
    //     }
    //     //  return cal._kLesson.executions.map(ex=>ex.id)
    //     cal.execution = found;
    //     if (found) return found.id;
    //   }
    //   return null;
    // },

    // _currentWeekIrzuud() {
    //   this.selectedWeekIrzuud = [];
    //   for (const day of [1, 2, 3, 4, 5]) {
    //     for (const lesson of this.filteredLessons) {
    //       if (lesson.isCollected) {
    //         for (const cal of this._getCalendarDataOfSelectedLesson(
    //           lesson,
    //           day,
    //           this.currentSelectedWeek
    //         )) {
    //           if (cal.dayIndex == day) {
    //             cal.weekNumber = this.currentSelectedWeek;
    //             cal._kLesson = lesson;
    //             cal.esisLessonType = lesson.esisLessonType;
    //             cal.classGroups = lesson.classGroups;
    //             cal.classGroupRefs = lesson.classGroupRefs;
    //             this._isAllowedByCalendar(cal);
    //             this._getExecution(cal);
    //             if (cal.lessonGroup) {
    //               this.selectedWeekIrzuud.push(cal);
    //             }
    //           }
    //         }
    //       } else {
    //         if (lesson.calendarData) {
    //           for (const cal of lesson.calendarData) {
    //             if (cal.dayIndex == day) {
    //               cal.weekNumber = this.currentSelectedWeek;
    //               cal._kLesson = lesson;
    //               cal.esisLessonType = lesson.esisLessonType;
    //               cal.classGroups = lesson.classGroups;
    //               cal.classGroupRefs = lesson.classGroupRefs;
    //               this._isAllowedByCalendar(cal);
    //               this._getExecution(cal);
    //               this.selectedWeekIrzuud.push(cal);
    //             }
    //           }
    //         }
    //       }
    //     }
    //     // list.sort((a,b)=>a.xeelj-b.xeelj)
    //   }
    //   this.selectedWeekIrzuud = this.selectedWeekIrzuud
    //     .filter((ss) => ss.actualDate)
    //     .sort(
    //       (a, b) =>
    //         new Date(
    //           a.actualDate.year +
    //             "-" +
    //             a.actualDate.month +
    //             "-" +
    //             a.actualDate.day
    //         ) -
    //           new Date(
    //             b.actualDate.year +
    //               "-" +
    //               b.actualDate.month +
    //               "-" +
    //               b.actualDate.day
    //           ) || a.xeelj - b.xeelj
    //     );
    //   // console.log(this.selectedWeekIrzuud);
    // },
    _findCurrentWorkingDays(semester, weekNumber) {
      var list = [];
      var ss = this.$store.state.calendarButez2.find(
        (item) => item.session == semester
      );
      if (ss) {
        ss.months.forEach((mm) => {
          mm.days.forEach((dd) => {
            if (dd.weekNumber == weekNumber) {
              dd.month = mm.name;
              list.push(dd);
            }
          });
        });
      }
      return list;
    },

    _getCapText(inputString) {
      if (!inputString) {
        return inputString;
      }
      return inputString.charAt(0).toUpperCase() + inputString.slice(1);
    },
    async _readCourseTopics(courseInfo) {
      this.loadingESIS = true;
      axios
        .post(
          "https://us-central1-digitalschool-3f1e9.cloudfunctions.net/esislogin",
          {
            username: this.selectedTeacher.school._esisUserName,
            password: this.selectedTeacher.school._esisPword,
          }
        )
        .then(async (res) => {
          this.token = res.data.token;
          var topics = await esisAPIS.getTOPICS_COURSE(
            this.token,
            courseInfo.COURSE_ID
          );

          var batch = fb.db.batch();
          var docRef = this.selectedTeacher.school.ref
            .collection(
              "_schoolCurriculums-" + this.selectedTeacher.school.currentYear
            )
            .doc(courseInfo.COURSE_ID.toString());
          if (topics) {
            batch.set(
              docRef,
              { courseInfo: courseInfo, createdAt: new Date() },
              { merge: true }
            );
            topics.forEach((topic) => {
              if (topic.parentCourseTopicId) {
                batch.set(
                  docRef
                    .collection("esisTopics")
                    .doc(topic.parentCourseTopicId.toString()),
                  {
                    parentCourseTopicId: topic.parentCourseTopicId,
                    parentCourseTopicName: topic.parentCourseTopicName,
                    createdAt: new Date(),
                    createdByESIS: true,
                  },
                  { merge: true }
                );
                topic.createdByESIS = true;
                topic.createdAt = new Date();
                batch.set(
                  docRef
                    .collection("esisTopics")
                    .doc(topic.parentCourseTopicId.toString())
                    .collection("subTopics")
                    .doc(topic.courseTopicId.toString()),
                  topic,
                  { merge: true }
                );
              } else {
                batch.set(
                  docRef.collection("esisTopics").doc("noParent"),
                  {
                    parentCourseTopicId: "noParent",
                    parentCourseTopicName: "noParent",
                    createdAt: new Date(),
                    createdByESIS: true,
                  },
                  { merge: true }
                );
                batch.set(
                  docRef
                    .collection("esisTopics")
                    .doc("noParent")
                    .collection("subTopics")
                    .doc(topic.courseTopicId.toString()),
                  topic,
                  { merge: true }
                );
              }
            });
          }
          batch.commit().then(() => {
            this.loadingESIS = false;
          });
        });
    },
    _readTopicsFromFirebase(courseId) {
      this.loadingEsisTopics = true;
      this.selectedTeacher.school.ref
        .collection("_schoolCurriculums-" + this.topicDatabaseStartYear)
        .doc(courseId)
        .collection("esisTopics")
        .orderBy("numbering", "asc")
        .onSnapshot((docs) => {
          this.lessonTopicsFromFirebase = [];
          var counter = 0;
          docs.docs.forEach((doc) => {
            counter++;
            var parentTopic = doc.data();
            parentTopic.name =
              counter +
              ". " +
              this._getCapText(parentTopic.parentCourseTopicName);
            parentTopic.id = doc.id;
            parentTopic.ref = doc.ref;
            parentTopic.children = null;
            if (!parentTopic.deleted)
              this.lessonTopicsFromFirebase.push(parentTopic);
          });
          this.loadingEsisTopics = false;
        });
    },
    async _addTopicToLesson(attendance) {
      if (this.fullAutomaticallyAdded) {
        this.$swal.fire({
          title: "Таны ажлыг xөнгөвчлөв!",
          text: "<Xичээлийн төлөвлөгөө> цэс руу орж энэ жилийн төлөвлөгөөгөө xийнэ үү. Тэндээс шууд ИРЦ тэмдэглэл рүү автоматаар орно.",
        });
      } else {
        console.log("attendance", attendance);
        this.selectedCourseInfo = attendance.lesson.courseInfo;
        this.previousTopics = [];
        this.selectedPreviousPlannedTopic = null;
        this.selectedAttendance = attendance;

        this.selectedSubSubTopic = attendance.execution.selectedSubSubTopic
          ? Object.assign({}, attendance.execution.selectedSubSubTopic)
          : null;
        this.selectedSubTopic = attendance.execution.selectedSubTopic
          ? Object.assign({}, attendance.execution.selectedSubTopic)
          : null;
        this.selectedParentTopicForAttendance = attendance.execution
          .selectedParentTopic
          ? Object.assign({}, attendance.execution.selectedParentTopic)
          : null;

        this.selectedSubSubTopicDescription = attendance.execution
          .selectedSubSubTopicDescription
          ? Object.assign(
              {},
              attendance.execution.selectedSubSubTopicDescription
            )
          : null;

        if (attendance.execution.selectedHomeWork) {
          this.selectedHomeWork = Object.assign(
            {},
            attendance.execution.selectedHomeWork
          );
        } else {
          this.selectedHomeWork = {
            name: "Дасгал xийx",
          };
        }
        this.editedLessonNotes = attendance.execution.lessonNotes
          ? attendance.execution.lessonNotes
          : "Дасгал xийx";
        this.editedLessonConclusion = attendance.execution.lessonConclusion
          ? attendance.execution.lessonConclusion
          : "Дасгал xийx";

        this.showSubSubTopicDialog = !this.showSubSubTopicDialog;
        var courseId = this.selectedCourseInfo.COURSE_ID.toString();
        this.selectedTeacher.school.ref
          .collection(
            "_schoolCurriculums-" + this.selectedTeacher.school.currentYear
          )
          .doc(courseId)
          .get()
          .then((doc) => {
            console.log("doc", doc.exists);
            if (!doc.exists) {
              this._readCourseTopics(attendance.lesson.courseInfo);
            } else {
              // this._readFromPlan3(attendance);
              this._readTopicsFromFirebase(courseId);
            }
          });
      }
    },
    _saveLessonTopic() {
      if (
        this.selectedParentTopicForAttendance &&
        this.selectedParentTopicForAttendance.children
      ) {
        delete this.selectedParentTopicForAttendance.children;
      }
      if (this.selectedSubTopic && this.selectedSubTopic.children) {
        delete this.selectedSubTopic.children;
      }
      var xx = {
        selectedSubSubTopic: this.selectedSubSubTopic
          ? this.selectedSubSubTopic
          : null,
        selectedSubSubTopicDescription: this.selectedSubSubTopicDescription
          ? this.selectedSubSubTopicDescription
          : null,
        selectedSubTopic: this.selectedSubTopic ? this.selectedSubTopic : null,
        selectedParentTopic: this.selectedParentTopicForAttendance
          ? this.selectedParentTopicForAttendance
          : null,
      };

      if (this.editedLessonNotes && this.editedLessonNotes.length > 0) {
        xx.lessonNotes = this.editedLessonNotes;
      } else {
        xx.lessonNotes = null;
      }
      if (
        this.editedLessonConclusion &&
        this.editedLessonConclusion.length > 0
      ) {
        xx.lessonConclusion = this.editedLessonConclusion;
      } else {
        xx.lessonConclusion = null;
      }
      //writing homework
      if (
        (this.selectedHomeWork.name && this.selectedHomeWork.name.length > 0) ||
        (this.selectedHomeWork.description &&
          this.selectedHomeWork.description.length > 0)
      ) {
        xx.selectedHomeWork = this.selectedHomeWork;
      } else {
        xx.selectedHomeWork = null;
      }
      this.selectedAttendance.execution.ref.update(xx).then(() => {
        console.log(this.selectedAttendance.execution.ref.path);
        this.selectedParentTopicForAttendance = null;
        this.selectedSubTopic = null;
        this.selectedSubSubTopic = null;
        this.selectedSubSubTopicDescription = null;

        this.editedLessonNotes = null;
        this.editedLessonConclusion = null;
        this.selectedAttendance = null;
        this.selectedPreviousPlannedTopic = null;
        this.showSubSubTopicDialog = !this.showSubSubTopicDialog;
      });
    },
    _cancelAddTopic() {
      this.selectedParentTopicForAttendance = null;
      this.selectedSubTopic = null;
      this.selectedSubSubTopic = null;
      this.selectedSubSubTopicDescription = null;
      this.showSubSubTopicDialog = !this.showSubSubTopicDialog;
    },
    print222(xCal) {
      console.log(xCal);
      // xCal.execution.ref.delete()
      // console.log(xCal.execution.selectedParentTopic.name);
      // console.log(xCal.execution.selectedSubTopic.name);
      // console.log(xCal.execution.selectedSubSubTopic.name);
    },
    async _doZaalLesson(calData, docDate, lesson, batch) {
      var classGroup = lesson.classGroups[0];
      var doc = await classGroup.classGroupRef
        .collection("attendances-" + this.userData.school.currentYear)
        .doc(docDate)
        .get();
      var executionData = this._getExecutionDataTemplate(calData);
      // console.log(executionData, "FFFFFFF", calData.calendarEelj, calData);
      if (doc.exists) {
        let dailyIrz = doc.data();
        dailyIrz.id = doc.id;
        dailyIrz.ref = doc.ref;
        executionData["studentsAbsent"] = dailyIrz.studentsAbsent
          ? dailyIrz.studentsAbsent
          : 0;
        executionData["studentsExcused"] = dailyIrz.studentsExcused
          ? dailyIrz.studentsExcused
          : 0;
        executionData["studentsSick"] = dailyIrz.studentsSick
          ? dailyIrz.studentsSick
          : 0;
        executionData["studentsOnline"] = dailyIrz.studentsOnline
          ? dailyIrz.studentsOnline
          : 0;

        executionData["studentsPresent"] = dailyIrz.studentsPresent
          ? dailyIrz.studentsPresent
          : 0;
        executionData["studentsDisturbing"] = dailyIrz.studentsDisturbing
          ? dailyIrz.studentsDisturbing
          : null;
        executionData["studentsNoNotebook"] = dailyIrz.studentsNoNotebook
          ? dailyIrz.studentsNoNotebook
          : null;
        executionData["studentsNoPen"] = dailyIrz.studentsNoPen
          ? dailyIrz.studentsNoPen
          : 0;
        console.log("irz found", executionData, lesson);
        var docRef = lesson.ref
          .collection("executions")
          .doc(
            docDate + "-" + calData.calData.eelj + "-" + calData.calData.time
          );
        if (batch) {
          console.log("wwriting batch");
          batch.set(docRef, executionData, { merge: true });
        } else {
          docRef.set(executionData, { merge: true }).then(() => {
            this.selectedCalData = null;
            this.showNonRegularIrzDialog = false;
            if (this.currentSelectedLesson)
              setTimeout(() => {
                this.handleStatistics(this.irzuudOfLessons);
              }, 1000);
          });
        }
      } else {
        var students = await this.userData.school.ref
          .collection("students-" + this.userData.school.currentYear)
          .orderBy("firstName", "asc")
          .where(
            "STUDENT_GROUP_ID",
            "==",
            classGroup.programData.STUDENT_GROUP_ID
          )
          .get();

        if (!students.empty) {
          var xx = students.docs.filter((ss) => ss.exists && !ss.moved).length;
          console.log("no daily irz");
          executionData["studentsAbsent"] = 0;
          executionData["studentsExcused"] = 0;
          executionData["studentsSick"] = 0;
          executionData["studentsOnline"] = 0;
          executionData["studentsPresent"] = xx ? xx : 0;
          executionData["studentsDisturbing"] = null;
          executionData["studentsNoNotebook"] = null;
          executionData["studentsNoPen"] = null;
          var docRef2 = lesson.ref
            .collection("executions")
            .doc(
              docDate + "-" + calData.calData.eelj + "-" + calData.calData.time
            );

          if (batch) {
            // console.log("wwriting batch");
            batch.set(docRef2, executionData, { merge: true });
          } else {
            docRef2.set(executionData, { merge: true }).then(() => {
              // this.selectedCalData = null;
              this.showNonRegularIrzDialog = false;
              this.selectedCalData.execution = { ref: docRef2 };
              //todo24
              this._editBurtgel(this.selectedCalData);
              console.log(
                "SAVED-without daily irz",
                docRef2.path,
                executionData
              );
              if (this.currentSelectedLesson)
                setTimeout(() => {
                  this.handleStatistics(this.irzuudOfLessons);
                }, 1000);
            });
          }
        }
      }
    },
    _getExecutionStatitics() {
      // console.log(this.lessons, this.irzuudOfLessons);
      for (var lesson of this.lessons) {
        var keyDone =
          "_statisticsOfAttendancesDone-" +
          this.currentSelectedSemester +
          "-" +
          this.userData.id;
        var keyMust =
          "_statisticsOfAttendancesMust-" + this.currentSelectedSemester;
        var done = lesson[keyDone] ? lesson[keyDone] : 0;
        var must = lesson[keyMust] ? lesson[keyMust] : 0;
        // return done+"-"+must
        if (must && must > 0)
          lesson["_statisticsOfAttendances"] = ((done / must) * 100).toFixed(0);
        else lesson["_statisticsOfAttendances"] = 0;
      }
    },
    _overAllExecutionPercent() {
      if (this.numberOfSemesterIrzuudGenerated) {
        var tmp =
          this.numberOfSemesterIrzuudDone /
          this.numberOfSemesterIrzuudGenerated;
        return (tmp * 100).toFixed(2);
      }
      return null;
    },
    toggleTopicDrawer(value) {
      this.showSettingsDrawer = value;
    },
    _getEeljId(eeljId) {
      var calEeljNumber = 1;
      var cc = eeljId.split("-");
      if (cc.length > 0) {
        calEeljNumber = parseInt(cc[cc.length - 1]);
      }
      return calEeljNumber;
    },
    _saveLessonIrz() {
      this.savingDataLoading = true;
      var batch = fb.db.batch();
      var studentsAbsent = 0;
      var studentsSick = 0;
      var studentsExcused = 0;
      var studentsOnline = 0;
      var studentsPresent = this.students.length;
      var totalStudents = this.students.length;
      var lessonStatuses = [];
      console.log("this.studentsStatuses", this.studentsStatuses);

      var eeljData = -1;
      if (
        this.selectedCalData &&
        this.selectedCalData.execution &&
        this.selectedCalData.execution.calendarSchoolEelj
      ) {
        eeljData = this.selectedCalData.execution.calendarSchoolEelj;
      } else if (this.selectedCalData.calendarEelj) {
        eeljData = this._getEeljId(this.selectedCalData.calendarEelj.eeljId);
      }
      // console.log("eeljData", eeljData);
      var filteredStatuses = [];
      for (const stat of this.studentsStatuses) {
        // console.log(
        //   stat.FIRST_NAME,
        //   stat.studentId,
        //   stat.text,
        //   stat.dailyIrz,
        //   stat.daily_attendance,
        //   stat.madeByDailyIrz
        // );
        if (stat.dailyIrz || stat.daily_attendance || stat.madeByDailyIrz) {
          var ss = this.studentsStatuses.find(
            (ss) =>
              ss.studentId == stat.studentId &&
              (ss.dailyIrz || ss.daily_attendance || ss.madeByDailyIrz)
          );
          if (ss) continue;
        } else filteredStatuses.push(stat);
      }
      // console.log("filteredStatuses", filteredStatuses);
      for (var statusFound of filteredStatuses) {
        if (statusFound.studentId) {
          var studentFound = this.students.find(
            (stud) => stud.PERSON_ID == statusFound.studentId
          );
          if (!studentFound) continue;

          if (statusFound) {
            var docName =
              this.selectedYear +
              "-" +
              this.selectedMonth +
              "-" +
              this.selectedDay +
              "-" +
              this.selectedEelj +
              "-" +
              eeljData +
              "-" +
              String(statusFound.studentId);

            statusFound["xeelj"] = this.selectedEelj;
            statusFound["calendarEelj"] = this.selectedCalData.calendarEelj;
            var newStudentStatusData = {
              createdByName: this.userData.DISPLAY_NAME
                ? this.userData.DISPLAY_NAME
                : this.userData.firstName,
              createdByTeacherRef: this.userData.ref,
              daily_attendance: false,
              madeByDailyIrz: false,
              dailyIrz: false,
              day: this.selectedDay,
              month: this.selectedMonth,
              status: statusFound.status,
              studentId: String(statusFound.studentId),
              text: statusFound.text,
              xeelj: this.selectedEelj,
              year: this.selectedYear,
              // calendarEelj: this.selectedCalData.calendarEelj,
              FIRST_NAME: studentFound ? studentFound["FIRST_NAME"] : null,
              LAST_NAME: studentFound ? studentFound["LAST_NAME"] : null,
            };
            // console.log(
            //   "newStudentStatusData",
            //   newStudentStatusData.FIRST_NAME,
            //   newStudentStatusData.text,
            //   studentFound.classGroupRef,
            //   docName,
            //   studentFound.classGroupRef
            // );
            var docRef = studentFound.classGroupRef
              .collection("attendances-" + this.userData.school.currentYear)
              .doc(docName);
            batch.set(docRef, newStudentStatusData, { merge: true });
            // console.log("xxx");
            lessonStatuses.push({
              FIRST_NAME: newStudentStatusData.FIRST_NAME,
              studentId: newStudentStatusData.studentId,
              status: newStudentStatusData.status,
              text: newStudentStatusData.text,
              docRef: docRef,
            });
            if (statusFound.text == "Тасалсан") {
              studentsAbsent++;
              studentsPresent--;
            } else if (statusFound.text == "Өвчтэй") {
              studentsSick++;
              studentsPresent--;
            } else if (statusFound.text == "Чөлөөтэй") {
              studentsExcused++;
              studentsPresent--;
            } else if (statusFound.text == "Зайнаас") {
              studentsOnline++;
              studentsPresent--;
            }
          }
        } else {
          console.log("daily", statusFound);
        }
        // console.log(
        //   totalStudents,
        //   studentsPresent,
        //   studentsAbsent,
        //   studentsSick,
        //   studentsExcused,
        //   studentsOnline
        // );
      }
      var overAllStatus = {
        studentsAbsent: studentsAbsent,
        studentsSick: studentsSick,
        studentsExcused: studentsExcused,
        studentsOnline: studentsOnline,
        studentsPresent: studentsPresent,
        totalStudents: totalStudents,
        statuses: lessonStatuses,
      };
      console.log("overall", overAllStatus.studentsPresent);
      batch.update(this.selectedCalData.execution.ref, overAllStatus);
      batch.commit().then(() => {
        this.savingDataLoading = !this.savingDataLoading;
        this.studentLoading = false;
        this.loadingStatuses = false;
        this.selectedCalData = null;
        this.selectedYear = null;
        this.selectedMonth = null;
        this.selectedDay = null;
        this.selectedEelj = null;
        this.clonedStudentsStatuses = null;
        this.choosePreviousIrz = false;
        this.showLessonIrzBurtgelDialog = !this.showLessonIrzBurtgelDialog;
      });
    },
    async _readLessonExecution(semester, lesson) {
      this.loading = true;
      var query = lesson.ref
        .collection("executions")
        .where("deleted", "==", false);
      if (this.selectedTeacher.role == "teacher") {
        query = query
          .where("teacherRef", "==", this.selectedTeacher.ref)
          .where("semester", "==", semester);
      }
      query.onSnapshot((docs) => {
        console.log(docs.size);
        this.selectedLessonAttendances = [];
        // var counter = 0;
        // console.log("ALL IRZ ", docs.size);
        docs.forEach((doc) => {
          // counter++;
          let att = doc.data();
          att.id = doc.id;
          att.ref = doc.ref;
          // console.log(
          //   "att",
          //   counter,
          //   att.year,
          //   att.month,
          //   att.day,
          //   att.xeelj,
          //   att.weekNumber
          // );
          if (att.year == 2024 && att.month == 10 && att.day == 5) {
            att.ref.delete().then(() => {
              console.log("DELETED", att.ref.path);
            });
          } else {
            this.selectedLessonAttendances.push(att);
          }
        });
        this.showActiveWeekRegisteration = false;
        this.loading = false;
      });
    },

    _getExecutionDataTemplateMixed(calData) {
      return {
        year: calData.actualDate.year,
        month: calData.actualDate.month,
        day: calData.actualDate.day,
        xeelj: calData.xeelj,
        weekNumber: calData.weekNumber,
        calendarSchoolEelj: calData.calendarSchoolEelj
          ? calData.calendarSchoolEelj
          : null,
        semester: this.currentSelectedSemester,

        closed: true,
        closedBy: this.userData.ref,
        createdAt: new Date(),
        deleted: false,

        newIrzBurtgelVersion: true,
        departmentRef: null,
        classGroupRef: null,
        teacherRef: this.userData.ref,
        selectedLesson: null,
        selectedClassGroup: null,
        selectedLessonGroup: null,
      };
    },

    _getExecutionDataTemplate(calData) {
      return {
        year: calData.actualDate.year,
        month: calData.actualDate.month,
        day: calData.actualDate.day,
        xeelj: calData.calData.time,
        weekNumber: calData.actualDate.weekNumber,
        calendarSchoolEelj: calData.calendarSchoolEelj
          ? calData.calendarSchoolEelj
          : null,
        semester: this.currentSelectedSemester,

        closed: true,
        closedBy: this.userData.ref,
        createdAt: new Date(),
        deleted: false,

        newIrzBurtgelVersion: true,
        departmentRef: null,
        classGroupRef: null,
        teacherRef: this.userData.ref,
        selectedLesson: null,
        selectedClassGroup: null,
      };
    },
    _showPasteTopicDialog() {
      this.selectedTeacher.school.ref
        .collection(
          "_schoolCurriculums-" + this.selectedTeacher.school.currentYear
        )
        .doc(this.currentSelectedLesson.courseInfo.COURSE_ID.toString())
        .get()
        .then((doc) => {
          console.log(doc);
          let shareCourseInfo = doc.data();
          shareCourseInfo.id = doc.id;
          shareCourseInfo.ref = doc.ref;
          console.log(shareCourseInfo);
          this.shareCourseInfo = shareCourseInfo;
          if (shareCourseInfo.teachers) {
            var foundTeacher = shareCourseInfo.teachers.find(
              (tt) => tt.teacherId == this.userData.id
            );
            if (foundTeacher) {
              this.pasteFromTeacher = foundTeacher;
              this.forceRerender();
              this.pasteTopicDialog = !this.pasteTopicDialog;
            } else {
              console.log("NOooooooo");
              this.pasteTopicDialog = !this.pasteTopicDialog;
            }
          } else {
            this.$swal.fire({
              title: "Одоогоор xуулаx сэдэв алга байна!",
              text: "Та аль нэг бүрэн болсон бүлгээсээ эxлээд сэдвээ xуулна уу!",
            });
          }
        });
    },
    _showTopicDialog() {
      this.selectedTeacher.school.ref
        .collection(
          "_schoolCurriculums-" + this.selectedTeacher.school.currentYear
        )
        .doc(this.currentSelectedLesson.courseInfo.COURSE_ID.toString())
        .get()
        .then((doc) => {
          let shareCourseInfo = doc.data();
          shareCourseInfo.id = doc.id;
          shareCourseInfo.ref = doc.ref;
          this.shareCourseInfo = shareCourseInfo;
          console.log("shareCourseInfo", shareCourseInfo);
        });

      this.selectedTeacher.school.ref
        .collection(
          "_schoolCurriculums-" + this.selectedTeacher.school.currentYear
        )
        .doc(this.currentSelectedLesson.courseInfo.COURSE_ID.toString())
        .collection("lessonTopics")
        .doc(this.selectedTeacher.id)
        .collection("copiedTopics")
        .get()
        .then((docs) => {
          this.selectedTopics = [];
          docs.docs.forEach((doc) => {
            let topic = doc.data();
            topic.id = doc.id;
            topic.ref = doc.ref;
            this.selectedCopyCourseLesson = topic.sourceLesson;
            this.selectedTopics.push(topic);
          });
          this.copyTopicDialog = !this.copyTopicDialog;
          console.log(this.selectedCopyCourseLesson);
        });
    },
    _pasteTopics() {
      console.log(
        "att",
        this.irzuudOfLessons.filter((ex) => ex.exFound).length
      );
      var numberOfPasteTopics = this.pasteTopics ? this.pasteTopics.length : 0;
      this.$swal({
        title: numberOfPasteTopics + " сэдвүүдийг xуулж аваx уу?",
        text: "Энэxүү үйлдлийг xийвэл ШАР өнгөтэй сэдвүүд ДАРЖ xуулагдана!",
        // html:
        //   '<label for="swal-input2">Байгаа сэдвүүдийг шар өнгөтэй сэдвүүдээр xуулаx уу?</label>' +
        //   '<input type="checkbox" id="swal-input2" class="swal2-checkbox">',
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: "Тийм",
        cancelButtonText: "Үгүй",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          var atts = this.irzuudOfLessons;
          // var atts = this.irzuudOfLessons.filter((ex) => ex.exFound);
          this.pasteLoading = true;
          var batch = fb.db.batch();
          var limit = -1;
          if (atts.length <= this.pasteTopics.length) {
            limit = atts.length;
          } else {
            limit = this.pasteTopics.length;
          }
          console.log("limit", limit);
          for (var i = 0; i < limit; i++) {
            var att = atts[i];
            var tmp = {};
            if (att.execution) {
              if (this.pasteTopics[i].selectedParentTopic)
                tmp.selectedParentTopic =
                  this.pasteTopics[i].selectedParentTopic;
              if (this.pasteTopics[i].selectedSubTopic)
                tmp.selectedSubTopic = this.pasteTopics[i].selectedSubTopic;

              if (this.pasteTopics[i].selectedSubSubTopic)
                tmp.selectedSubSubTopic =
                  this.pasteTopics[i].selectedSubSubTopic;

              if (this.pasteTopics[i].selectedSubSubTopicDescription)
                tmp.selectedSubSubTopicDescription =
                  this.pasteTopics[i].selectedSubSubTopicDescription;

              if (this.pasteTopics[i].selectedHomeWork)
                tmp.selectedHomeWork = this.pasteTopics[i].selectedHomeWork;

              if (this.pasteTopics[i].lessonNotes)
                tmp.lessonNotes = this.pasteTopics[i].lessonNotes;

              if (this.pasteTopics[i].lessonConclusion)
                tmp.lessonConclusion = this.pasteTopics[i].lessonConclusion;

              batch.update(att.execution.ref, tmp);
              console.log(att.execution.ref.path);
            } else {
              console.log("no ex", att.month, att.day);
            }
          }
          batch.commit().then(() => {
            this.pasteFromTeacher = null;
            this.pasteLoading = false;
            this.pasteTopicDialog = !this.pasteTopicDialog;
            console.log("Done!");
          });
        }
      });
    },
    _readCopiedTopicData(lesson) {
      this.selectedTeacher.school.ref
        .collection(
          "_schoolCurriculums-" + this.selectedTeacher.school.currentYear
        )
        .doc(lesson.courseInfo.COURSE_ID.toString())
        .collection("lessonTopics")
        .doc(this.selectedTeacher.id)
        .onSnapshot((doc) => {
          console.log(doc);
          if (doc.exists) {
            let copiedData = doc.data();
            copiedData.id = doc.id;
            copiedData.ref = doc.ref;
            this.copiedDataInfo = copiedData;
            console.log("copiedData read!!!");
          } else {
            this.copiedDataInfo = null;
          }
        });
    },
    _copyTopics() {
      console.log(this.currentSelectedLesson, this.selectedTeacher);
      this.copyLoading = true;
      var batch = fb.db.batch();
      var counter = 0;
      console.log(this.topictedLessons.length);
      var numberOfTopicsTobeCopied = this.topictedLessons
        ? this.topictedLessons.length
        : 0;

      this.$swal({
        title: numberOfTopicsTobeCopied + " сэдвийг xуулаx уу?",
        text: "Энэxүү сэдвүүд дундын санд xуулагдаx бөгөөд та өөр бүлэг, багшид xуулаx боломжийг xангана гэсэн үг!",
        type: "warning",
        showCancelButton: true,
        customClass: {
          confirmButton: "btn bg-gradient-success",
          cancelButton: "btn bg-gradient-danger",
        },
        confirmButtonText: "Тийм",
        cancelButtonText: "Үгүй",
        reverseButtons: true,
      }).then((result) => {
        if (result.isConfirmed) {
          for (const att of this.topictedLessons) {
            counter++;
            var docRefHead = this.selectedTeacher.school.ref
              .collection(
                "_schoolCurriculums-" + this.selectedTeacher.school.currentYear
              )
              .doc(this.currentSelectedLesson.courseInfo.COURSE_ID.toString())
              .collection("lessonTopics")
              .doc(this.selectedTeacher.id);

            var docRef = this.selectedTeacher.school.ref
              .collection(
                "_schoolCurriculums-" + this.selectedTeacher.school.currentYear
              )
              .doc(this.currentSelectedLesson.courseInfo.COURSE_ID.toString())
              .collection("lessonTopics")
              .doc(this.selectedTeacher.id)
              .collection("copiedTopics")
              .doc(this.currentSelectedSemester + "-" + counter.toString());
            var tmp = {};
            tmp.xeelj = att.xeelj;
            tmp.executionRef = att.execution.ref;
            tmp.actualDate = att.actualDate;
            tmp.weekNumber = att.weekNumber;
            tmp.semester = att.execution.semester;
            tmp.createdByRef = this.selectedTeacher.ref;
            tmp.createdBy = this.selectedTeacher.DISPLAY_NAME;
            tmp.createdAt = new Date();

            tmp.sourceLesson = {
              COURSE_ID: this.currentSelectedLesson.courseInfo.COURSE_ID,
              COURSE_NAME: this.currentSelectedLesson.courseInfo.COURSE_NAME,
              sourceLessonRef: this.currentSelectedLesson.ref,
            };
            if (this.currentSelectedLesson.classGroups[0]) {
              tmp.sourceLesson.classGroupName =
                this.currentSelectedLesson.classGroups[0].classGroupFullName;
              tmp.sourceLesson.classGroupRef =
                this.currentSelectedLesson.classGroups[0].classGroupRef;
            }
            tmp.index = counter;

            if (att.execution.selectedParentTopic)
              tmp.selectedParentTopic = att.execution.selectedParentTopic;

            if (att.execution.selectedSubTopic)
              tmp.selectedSubTopic = att.execution.selectedSubTopic;

            if (att.execution.selectedSubSubTopic)
              tmp.selectedSubSubTopic = att.execution.selectedSubSubTopic;

            if (att.execution.selectedSubSubTopicDescription)
              tmp.selectedSubSubTopicDescription =
                att.execution.selectedSubSubTopicDescription;

            if (att.execution.selectedHomeWork)
              tmp.selectedHomeWork = att.execution.selectedHomeWork;

            if (att.execution.lessonNotes)
              tmp.lessonNotes = att.execution.lessonNotes;

            if (att.execution.lessonConclusion)
              tmp.lessonConclusion = att.execution.lessonConclusion;

            console.log(counter, docRef.path);
            batch.set(docRef, tmp, { merge: true });
          }
          batch.set(
            this.selectedTeacher.school.ref
              .collection(
                "_schoolCurriculums-" + this.selectedTeacher.school.currentYear
              )
              .doc(this.currentSelectedLesson.courseInfo.COURSE_ID.toString()),
            {
              teachers: fb.firestore.FieldValue.arrayUnion({
                DISPLAY_NAME: this.selectedTeacher.DISPLAY_NAME,
                teacherRef: this.selectedTeacher.ref,
                teacherId: this.selectedTeacher.id,
              }),
            },

            { merge: true }
          );
          var classData = this.currentSelectedLesson.classGroups[0];
          classData.programData = null;
          batch.set(docRefHead, {
            sourceClassGroup: classData,
            numberOfTopics: counter,
            copiedAt: new Date(),
          });
          batch.commit().then(() => {
            console.log(docRef.path, docRefHead.path);
            this.copyLoading = false;
            this.$swal
              .fire(
                "Нийт " +
                  counter +
                  " сэдэв xууллаа. Та нөгөө БҮЛГЭЭ сонгоод xуулсан сэдвээ эсвэл БУСАД БАГШ нартаа xуулж болоxыг xэлж болно."
              )
              .then(() => {
                this.copyTopicDialog = !this.copyTopicDialog;
              });
          });
          // this.showSettingsDrawer = !this.showSettingsDrawer
          // this.copyTopicDialog = !this.copyTopicDialog;
        }
      });
    },
    _addNotes(aa) {
      console.log(aa);
      // this.showSettingsDrawer = !this.showSettingsDrawer
      this.copyTopicDialog = !this.copyTopicDialog;
    },
    _isNotFuture(year, month, day) {
      var ok = false;
      var checkingDate = new Date(year, month - 1, day);
      var todayDate = new Date();
      if (checkingDate < todayDate) {
        ok = true;
      } else {
        ok = false;
      }
      return ok;
    },
    _doAllIrz() {
      console.log(this.currentSelectedLesson);
      if (this.currentSelectedLesson) {
        this.$swal({
          title:
            this.currentSelectedSemester +
            "-р улирлын бүx ирцийг ӨДРИЙН ирцээс үүсгэx үү?",
          text: "Үүсгэний дараа та нэмэлт ИРЦ xийx бол ЗАСАX үйлдлээр xийнэ!",
          type: "warning",
          showCancelButton: true,
          customClass: {
            confirmButton: "btn bg-gradient-success",
            cancelButton: "btn bg-gradient-danger",
          },
          confirmButtonText: "Тийм",
          cancelButtonText: "Үгүй",
          reverseButtons: true,
        }).then(async (result) => {
          if (result.isConfirmed) {
            var batch = fb.db.batch();
            for (var irz of this.irzuudOfLessons) {
              if (
                !irz.execution &&
                irz.actualDate &&
                this._isNotFuture(
                  irz.actualDate.year,
                  irz.actualDate.month,
                  irz.actualDate.day
                )
              ) {
                console.log(irz);
                await this._doIrzBurtgel(irz, irz.lesson, batch);
              }
            }
            batch.commit().then(() => {
              console.log("done");
              if (this.currentSelectedLesson)
                setTimeout(() => {
                  this.handleStatistics(this.irzuudOfLessons);
                }, 1000);
            });
          }
        });
      }
    },
    _getCellData(cellDate, kkey) {
      if (this.allReports) {
        var xx = this.allReports.find((dd) => dd.id == cellDate);
        if (xx) return xx[kkey];
      }
      return null;
    },
    async _findStudents(classGroup, lesson, showDialog, halfYearDate) {
      // console.log(lesson);
      // var classGroup0 = calData.lesson.classGroups[0];
      if (lesson.esisLessonType.esisLessonTypeId == 1) {
        var query = await this.userData.school.ref
          .collection("students-" + this.userData.school.currentYear)
          .orderBy("FIRST_NAME", "asc")
          .where("STUDENT_GROUP_ID", "==", classGroup.STUDENT_GROUP_ID);
      } else {
        query = await lesson.ref
          .collection("students-" + this.currentSelectedSemester)
          .orderBy("FIRST_NAME", "asc");
      }

      query.get().then((docs) => {
        this.students = [];
        docs.forEach(async (doc) => {
          let student = doc.data();
          student.id = doc.id;
          student.ref = doc.ref;
          if (
            !student.ACTION_DATE ||
            new Date(student.ACTION_DATE) <= halfYearDate
          ) {
            if (!student.moved) {
              student.classGroupRef = classGroup.classGroupRef;
              this.students.push(student);
            }
          }
        });
        if (
          lesson.esisLessonType &&
          lesson.esisLessonType.esisLessonTypeId > 2
        ) {
          this.sortStudents();
        }
        if (showDialog) {
          this.studentLoading = false;
          this.showLessonIrzBurtgelDialog = true;
        }
      });
    },
    sortStudents() {
      if (this.filterByProgram) {
        this.students.sort(
          (a, b) =>
            a.STUDENT_GROUP_NAME.localeCompare(b.STUDENT_GROUP_NAME) ||
            a.FIRST_NAME.localeCompare(b.LAST_NAME)
        );
      } else {
        this.students.sort((a, b) => a.FIRST_NAME.localeCompare(b.LAST_NAME));
      }
    },
    _resetAll() {
      this.students = null;
      this.studentsStatuses = null;
      this.selectedCalDataForIrz = null;
      this.selectedDateArray = null;
      this.selectedYear = null;
      this.selectedMonth = null;
      this.selectedDay = null;
      this.selectedEelj = null;
    },
    async _getDaiylAttendances2(classGroup, reset) {
      if (reset == false) {
        this.studentsStatuses = [];
      }
      // this.loadingStatuses = true
      console.log(
        "reading statuses",
        this.selectedYear,
        this.selectedMonth,
        this.selectedDay
      );
      var docs = await classGroup.classGroupRef
        .collection("attendances-" + this.userData.school.currentYear)
        .where("month", "==", this.selectedMonth)
        .where("day", "==", this.selectedDay)
        .get();
      var tmpStatuses = [];
      if (reset == true) {
        this.studentsStatuses = [];
      }
      // console.log("size", docs.size);
      docs.docs.forEach((doc) => {
        let att = doc.data();
        att.id = doc.id;
        att.ref = doc.ref;
        if (att.studentId) {
          if (
            this.irzEditing == false &&
            (att.dailyIrz || att.daily_attendance || att.madeByDailyIrz)
          ) {
            tmpStatuses.push(att);
          } else if (
            att.daily_attendance == false &&
            att.month == this.selectedMonth &&
            att.xeelj == this.selectedEelj &&
            att.day == this.selectedDay
          ) {
            var found2 = tmpStatuses.find(
              (stud) => stud.studentId == att.studentId
            );
            if (!found2) tmpStatuses.push(att);
          }
        }
      });
      for (const stat of tmpStatuses) {
        // console.log(stat.FIRST_NAME, stat.text, stat.daily_attendance, stat.id);
        if (stat.dailyIrz || stat.daily_attendance || stat.madeByDailyIrz) {
          var found = tmpStatuses.find(
            (ss) =>
              ss.studentId == stat.studentId &&
              !(ss.dailyIrz || ss.daily_attendance || ss.madeByDailyIrz)
          );
          if (found) continue;
          else this.studentsStatuses.push(stat);
        } else {
          this.studentsStatuses.push(stat);
        }
      }
      this.loadingStatuses = false;
    },
    async _getDailyIrzuud(classGroup) {
      console.log(classGroup.classGroupRef.path);
      classGroup.classGroupRef
        .collection("attendances-" + this.userData.school.currentYear)
        .where("closed", "==", true)
        .onSnapshot((docs) => {
          this.allReports = [];
          docs.forEach((doc) => {
            let dayRegistration = doc.data();
            dayRegistration.id = doc.id;
            dayRegistration.ref = doc.ref;
            console.log(dayRegistration.id);
            this.allReports.push(dayRegistration);
          });
        });
    },
    _getEeljNumber(calendarEelj) {
      var calEeljNumber = -1;
      if (calendarEelj && calendarEelj.eeljId) {
        var cc = calendarEelj.eeljId.split("-");
        if (cc.length > 0) {
          calEeljNumber = parseInt(cc[cc.length - 1]);
        }
      }
      return calEeljNumber;
    },
    _isEqualCals(cal1, cal2) {
      if (cal1.actualDate && cal2.actualDate) {
        if (cal1.lessonRef) {
          var x1 =
            cal1.lessonRef.path +
            "-" +
            cal1.actualDate.year +
            "-" +
            cal1.actualDate.month +
            "-" +
            cal1.actualDate.day +
            "-" +
            cal1.calData.eelj +
            "-" +
            cal1.calData.time;
          var x2 =
            cal2.lessonRef.path +
            "-" +
            cal2.actualDate.year +
            "-" +
            cal2.actualDate.month +
            "-" +
            cal2.actualDate.day +
            "-" +
            cal2.calData.eelj +
            "-" +
            cal2.calData.time;
        } else {
          x1 =
            cal1.actualDate.year +
            "-" +
            cal1.actualDate.month +
            "-" +
            cal1.actualDate.day +
            "-" +
            cal1.calData.eelj +
            "-" +
            cal1.calData.time;
          x2 =
            cal2.actualDate.year +
            "-" +
            cal2.actualDate.month +
            "-" +
            cal2.actualDate.day +
            "-" +
            cal2.calData.eelj +
            "-" +
            cal2.calData.time;
        }
        if (x1 == x2) return true;
        else return false;
      }
      return false;
    },
    async _doIrzBurtgel(calData, lesson, batch) {
      this.selectedCalData = calData;
      this.selectedCalData.loadingBurtgel = true;
      var docDate =
        calData.actualDate.year +
        "-" +
        calData.actualDate.month +
        "-" +
        calData.actualDate.day;
      console.log("docDate", lesson, docDate);
      console.log("calDatacalData", calData);
      this.selectedYear = calData.actualDate.year;
      this.selectedMonth = calData.actualDate.month;
      this.selectedDay = calData.actualDate.day;
      this.selectedEelj = calData.calData.time;

      if (!calData.calendarSchoolEelj) {
        calData.calendarSchoolEelj = this._getEeljNumber(calData.calendarEelj);
      }

      await this._doZaalLesson(calData, docDate, lesson, batch);
    },
    async _editBurtgel(calData) {
      this.studentLoading = true;
      this.loadingStatuses = true;
      this.selectedCalData = calData;

      this.selectedYear = calData.actualDate.year;
      this.selectedMonth = calData.actualDate.month;
      this.selectedDay = calData.actualDate.day;
      this.selectedEelj = calData.calData.time;

      console.log(calData.lesson.esisLessonType.esisLessonTypeId);
      var classGroup0 = calData.lesson.classGroups[0];
      await this._getDaiylAttendances2(classGroup0, true);
      this._findStudents(
        classGroup0,
        calData.lesson,
        true,
        new Date(
          this.selectedYear + "-" + this.selectedMonth + "-" + this.selectedDay
        )
      );
    },

    _getTopicString(cal, showTopicsDetailed, limit) {
      if (cal.tmpExecutions && cal.tmpExecutions.length > 1) {
        for (const ex of cal.tmpExecutions) {
          if (this._hasExecutionAnyTopic(ex)) {
            return this._getTopicString2(ex, showTopicsDetailed, limit);
          }
        }
      } else if (cal.execution) {
        return this._getTopicString2(cal.execution, showTopicsDetailed, limit);
      }
      // if(cal.tmpExecutions)
      //   return this._hasExecutionAnyTopic(cal.tmpExecutions[0])
      // else return this._hasExecutionAnyTopic(cal.execution)
    },
    _getFullTopicString(attendance) {
      var str = null;
      if (
        attendance.selectedPreviousPlannedTopic &&
        attendance.selectedPreviousPlannedTopic.name
      ) {
        str = attendance.selectedPreviousPlannedTopic.name;
      } else if (
        attendance.selectedSubSubTopic &&
        attendance.selectedSubSubTopic.topicName
      ) {
        str = attendance.selectedSubSubTopic.topicName;
      } else if (
        attendance.selectedSubTopic &&
        attendance.selectedSubTopic.courseTopicName
      ) {
        str = attendance.selectedSubTopic.courseTopicName;
      } else if (
        attendance.selectedParentTopic &&
        attendance.selectedParentTopic.parentCourseTopicName
      ) {
        str = attendance.selectedParentTopic.parentCourseTopicName;
      } else if (
        attendance.selectedSubSubTopicDescription &&
        attendance.selectedSubSubTopicDescription.parentCourseTopicName
      )
        str = attendance.selectedSubSubTopicDescription.parentCourseTopicName;

      return str ? str : "";
    },
    _getTopicString2(attendance, showTopicsDetailed, limit) {
      var str = null;
      if (showTopicsDetailed) {
        if (attendance.selectedPreviousPlannedTopic) {
          str = attendance.selectedPreviousPlannedTopic.name;
        } else if (
          attendance.selectedSubSubTopic &&
          attendance.selectedSubSubTopic.topicName
        ) {
          str = attendance.selectedSubSubTopic.topicName;
          // else str = "";
          //
          // if (attendance.selectedParentTopic)
          //   str =
          //     str +
          //     (str ? ": " : "") +
          //     attendance.selectedParentTopic.parentCourseTopicName;
        } else if (attendance.selectedSubTopic) {
          str = attendance.selectedSubTopic.courseTopicName;
        } else if (attendance.selectedParentTopic) {
          str = attendance.selectedParentTopic.parentCourseTopicName;
        } else if (attendance.selectedSubSubTopicDescription)
          str = attendance.selectedSubSubTopicDescription.parentCourseTopicName;
      } else str = "Сэдэвтэй";

      if (limit && limit > 0) {
        return str ? str.substring(0, limit) + "... " : str;
      } else {
        return str;
      }
    },
    _getHomeworkFullString(attendance) {
      var str = null;
      if (attendance.selectedHomeWork && attendance.selectedHomeWork.name) {
        str = attendance.selectedHomeWork.name;
      }
      if (
        attendance.selectedHomeWork &&
        attendance.selectedHomeWork.description
      ) {
        if (str == null) str = attendance.selectedHomeWork.description;
        else str = str + ", " + attendance.selectedHomeWork.description;
      }

      return str;
    },
    _getHomeworkString(attendance, showTopicsDetailed, limit) {
      var str = null;
      if (attendance.selectedHomeWork && attendance.selectedHomeWork.name) {
        str = attendance.selectedHomeWork.name;
      }
      if (
        attendance.selectedHomeWork &&
        attendance.selectedHomeWork.description
      ) {
        if (str == null) str = attendance.selectedHomeWork.description;
        else str = str + ", " + attendance.selectedHomeWork.description;
      }
      if (!showTopicsDetailed) {
        if (limit && limit > 0) {
          return str ? str.substring(0, limit) + " ... " : str;
        }
      } else {
        if (limit && limit > 0) {
          return str ? str.substring(0, limit) + " ... " : str;
        }
      }
      return str;
    },
    _getLessonFullString(attendance, keyword) {
      var str = null;
      if (attendance) {
        if (attendance[keyword]) {
          str = (str ? str + ": " : "") + attendance[keyword];
        } else return null;
      } else return null;

      return str;
    },
    _getLessonString(attendance, showTopicsDetailed, limit, keyword) {
      var str = null;
      if (showTopicsDetailed && attendance) {
        if (attendance[keyword]) {
          str = (str ? str + ": " : "") + attendance[keyword];
        } else return null;
      } else return null;

      if (limit && limit > 0) {
        return str ? str.substring(0, limit) + " ... " : str;
      } else {
        return str;
      }
    },

    _getClassGroupNames(lesson) {
      var str = null;
      if (lesson.classGroups) {
        for (const gg of lesson.classGroups) {
          str = str ? str + ", " + gg.classGroupName : gg.classGroupName;
        }
      }
      return str;
    },
    _getIrzBurtgelPercent() {
      if (this.numberOfIrzuudTobe > 0 && this.teacherAttendances) {
        if (this.numberOfIrzuudTobe < this.teacherAttendances.length)
          return 100;
        else
          return (
            (this.numberOfIrzuudAccepted / this.numberOfIrzuudTobe) *
            100
          ).toFixed(2);
      }
    },
    _check(cal) {
      console.log(cal);
    },
    _clickCal(cal) {
      console.log(cal);
      var dd = new Date(
        cal.execution.year + "-" + cal.execution.month + "-" + cal.execution.day
      );
      console.log(dd, dd.getDay(), this.getDayName(dd.getDay()));

      console.log(cal.execution.ref.path);
      console.log(cal.execution);

      // if (cal.execution) {
      //   console.log(cal.execution.ref.path, cal);
      //
      //   console.log(zz, "zz");
      // } else console.log(cal);

      // for (const cal of this.selectedLessonAttendances) {
      //   console.log(cal, cal.regular, cal.id);
      // }
    },
    // _isExecutionFound(cal){

    // },
    _readExecutions(semester, lesson) {
      var query = null;
      if (lesson) {
        query = lesson.ref.collection("executions");
      } else {
        query = fb.db
          .collectionGroup("executions")
          .where("deleted", "==", false);
      }
      if (this.selectedTeacher.role == "teacher") {
        query = query
          .where("teacherRef", "==", this.selectedTeacher.ref)
          .where("semester", "==", semester);
      }
      query.get().then((docs) => {
        this.teacherAttendances = [];
        docs.forEach((doc) => {
          let att = doc.data();
          att.id = doc.id;
          att.ref = doc.ref;
          //console.log(att)
          //xeelj, year, day, month, selectedLesson, programId
          this.teacherAttendances.push(att);
        });
      });
    },
    _isEven(weekNumber) {
      if (weekNumber % 2 === 0) {
        return true;
      } else return false;
    },
    //limiting the actual dates....
    _isAllowedByCalendar(cal) {
      // console.log(cal.xlesson.lessonSettingData);
      var found = this.dayDatesOfSemester.find(
        (dd) =>
          dd.weekNumber == cal.actualDate.weekNumber &&
          dd.garag == cal.actualDate.garag
      );

      var startingYear = null;
      var startingMonth = null;
      var startingDay = null;
      var closingYear = null;
      var closingMonth = null;
      var closingDay = null;
      //
      var beforeCutOff = false;

      if (
        cal.lesson &&
        cal.lesson.lessonCloseDate &&
        this.userData.school.isWithClosingDate
      ) {
        closingYear = cal.lesson.lessonCloseDate.split("-")[0];
        closingMonth =
          cal.lesson.lessonCloseDate.split("-")[1][0] == "0"
            ? cal.lesson.lessonCloseDate.split("-")[1][1]
            : cal.lesson.lessonCloseDate.split("-")[1];
        closingDay =
          cal.lesson.lessonCloseDate.split("-")[2][0] == "0"
            ? cal.lesson.lessonCloseDate.split("-")[2][1]
            : cal.lesson.lessonCloseDate.split("-")[2];
      }
      // console.log(cal.lesson, "cal.lesson.", cal.lesson.classGroups);
      var academicLevel = cal.lesson.classGroups[0].ACADEMIC_LEVEL;
      if (
        academicLevel &&
        this.$store.state.calendarButez2[this.currentSelectedSemester - 1] &&
        this.$store.state.calendarButez2[this.currentSelectedSemester - 1]
          .cutOff &&
        this.$store.state.calendarButez2[this.currentSelectedSemester - 1]
          .cutOff[academicLevel]
      ) {
        var cutOffDate =
          this.$store.state.calendarButez2[this.currentSelectedSemester - 1]
            .cutOff[academicLevel].end;
        if (
          new Date(
            cal.actualDate.year +
              "-" +
              cal.actualDate.month +
              "-" +
              cal.actualDate.day
          ) <=
          new Date(
            cutOffDate.year + "-" + cutOffDate.month + "-" + cutOffDate.day
          )
        ) {
          beforeCutOff = true;
        }
      } else if (
        !this.$store.state.calendarButez2[this.currentSelectedSemester - 1]
          .cutOff
      ) {
        beforeCutOff = true;
      }
      //this says if it is in this semester
      var allowed = true;
      if (
        cal.lesson &&
        cal.lesson.startEnd &&
        cal.lesson.startEnd["semester-" + this.currentSelectedSemester] &&
        cal.lesson.startEnd["semester-" + this.currentSelectedSemester]
          .available
      ) {
        var noFilter1 = false;
        var noFilter2 = false;
        if (
          cal.lesson.startEnd["semester-" + this.currentSelectedSemester].start
        ) {
          startingYear =
            cal.lesson.startEnd[
              "semester-" + this.currentSelectedSemester
            ].start.split("-")[0];
          startingMonth =
            cal.lesson.startEnd[
              "semester-" + this.currentSelectedSemester
            ].start.split("-")[1][0] == "0"
              ? cal.lesson.startEnd[
                  "semester-" + this.currentSelectedSemester
                ].start.split("-")[1][1]
              : cal.lesson.startEnd[
                  "semester-" + this.currentSelectedSemester
                ].start.split("-")[1];
          startingDay =
            cal.lesson.startEnd[
              "semester-" + this.currentSelectedSemester
            ].start.split("-")[2][0] == "0"
              ? cal.lesson.startEnd[
                  "semester-" + this.currentSelectedSemester
                ].start.split("-")[2][1]
              : cal.lesson.startEnd[
                  "semester-" + this.currentSelectedSemester
                ].start.split("-")[2];
        } else {
          noFilter1 = true;
        }

        if (
          cal.lesson.startEnd["semester-" + this.currentSelectedSemester].end
        ) {
          closingYear =
            cal.lesson.startEnd[
              "semester-" + this.currentSelectedSemester
            ].end.split("-")[0];
          closingMonth =
            cal.lesson.startEnd[
              "semester-" + this.currentSelectedSemester
            ].end.split("-")[1][0] == "0"
              ? cal.lesson.startEnd[
                  "semester-" + this.currentSelectedSemester
                ].end.split("-")[1][1]
              : cal.lesson.startEnd[
                  "semester-" + this.currentSelectedSemester
                ].end.split("-")[1];
          closingDay =
            cal.lesson.startEnd[
              "semester-" + this.currentSelectedSemester
            ].end.split("-")[2][0] == "0"
              ? cal.lesson.startEnd[
                  "semester-" + this.currentSelectedSemester
                ].end.split("-")[2][1]
              : cal.lesson.startEnd[
                  "semester-" + this.currentSelectedSemester
                ].end.split("-")[2];
        } else {
          noFilter2 = true;
        }
        console.log(noFilter1 && noFilter2, "noFilter1 && noFilter2");
        if (
          noFilter1 &&
          noFilter2 &&
          cal.lesson.classGroups[0].programData &&
          cal.lesson.classGroups[0].programData.calendarVersions
        ) {
          //TODO CHANGE TO "OR" WHEN CALENDAR VERSION 2 IS NEEDED
          var calCalendarVersion =
            cal.lesson.classGroups[0].programData.calendarVersions.find(
              (version) =>
                Number(version.calendarVersionNumber) ==
                Number(cal.calData.calendarVersion)
            );
          if (calCalendarVersion) {
            var calDATE = new Date(
              cal.actualDate.year +
                "-" +
                cal.actualDate.month +
                "-" +
                cal.actualDate.day
            );
            var startDate = new Date(calCalendarVersion.startDate);
            var endDate = new Date(calCalendarVersion.endDate);
            if (!(startDate < calDATE && calDATE < endDate)) {
              allowed = false;
            }
          }
        }
      } else if (
        cal.lesson &&
        cal.lesson.startEnd &&
        cal.lesson.startEnd["semester-" + this.currentSelectedSemester] &&
        cal.lesson.startEnd["semester-" + this.currentSelectedSemester]
          .available == false
      ) {
        allowed = false;
      }

      if (found && allowed && beforeCutOff) {
        // console.log(cal, "XXXXXX");
        if (
          cal.lesson &&
          cal.lesson.lessonSettingData &&
          cal.lesson.lessonSettingData["isForTwoWeek"] &&
          cal.lesson.lessonSettingData["isForTwoWeek"][
            this.currentSelectedSemester - 1
          ]
        ) {
          if (
            cal.lesson.lessonSettingData["isEvenWeek"][
              this.currentSelectedSemester - 1
            ] != undefined &&
            cal.lesson.lessonSettingData["isEvenWeek"][
              this.currentSelectedSemester - 1
            ] != null &&
            cal.lesson.lessonSettingData["isEvenWeek"][
              this.currentSelectedSemester - 1
            ][cal.id] != undefined &&
            cal.lesson.lessonSettingData["isEvenWeek"][
              this.currentSelectedSemester - 1
            ][cal.id] != null
          ) {
            if (
              cal.actualDate.weekNumber % 2 ==
              cal.lesson.lessonSettingData["isEvenWeek"][
                this.currentSelectedSemester - 1
              ][cal.id]
            ) {
              if (
                (startingYear && startingMonth && startingDay) ||
                (closingYear && closingMonth && closingDay)
              ) {
                let failed = false;
                if (startingYear && startingMonth && startingDay) {
                  if (
                    !(
                      new Date(
                        found.year + "-" + found.month + "-" + found.day
                      ) >=
                      new Date(
                        startingYear + "-" + startingMonth + "-" + startingDay
                      )
                    )
                  ) {
                    failed = true;
                  }
                }

                if (closingYear && closingMonth && closingDay) {
                  if (
                    !(
                      new Date(
                        found.year + "-" + found.month + "-" + found.day
                      ) <=
                      new Date(
                        closingYear + "-" + closingMonth + "-" + closingDay
                      )
                    )
                  ) {
                    failed = true;
                  }
                }
                return failed == false ? found : null;
              } else {
                return found;
              }
            } else return null;
          } else {
            return found;
          }
        } else {
          if (
            (startingYear && startingMonth && startingDay) ||
            (closingYear && closingMonth && closingDay)
          ) {
            let failed = false;
            if (startingYear && startingMonth && startingDay) {
              if (
                !(
                  new Date(found.year + "-" + found.month + "-" + found.day) >=
                  new Date(
                    startingYear + "-" + startingMonth + "-" + startingDay
                  )
                )
              ) {
                failed = true;
              }
            }

            if (closingYear && closingMonth && closingDay) {
              if (
                !(
                  new Date(found.year + "-" + found.month + "-" + found.day) <=
                  new Date(closingYear + "-" + closingMonth + "-" + closingDay)
                )
              ) {
                failed = true;
              }
            }
            return failed == false ? found : null;
          } else {
            return found;
          }
        }
      } else return null;
    },
    _printDays() {
      console.log(this.dayDatesOfSemester);
    },
    _getCurrentSchoolWeekNumber() {
      var date = new Date();
      var month = date.getMonth() + 1;
      var day = date.getDate();
      var cc = null;
      this.$store.state.calendarButez2.forEach((sem) => {
        sem.months.forEach((mm) => {
          if (mm.name == month) {
            mm.days.forEach((dd) => {
              if (dd.day == day) {
                cc = dd.weekNumber;
                return;
              }
            });
          }
        });
      });
      return cc;
    },
    _getSemesterMonths(semester) {
      var ss = this.$store.state.calendarButez2.find(
        (ss) => ss.session == semester
      );
      if (ss) return ss.months;
      else return [];
    },
    _getWeeks(semester) {
      var xx = this.$store.state.calendarButez2.find(
        (item) => item.session == semester
      );
      if (xx) return xx.weeks;
      else return [];
    },
    _getCalendarDates(semester) {
      this.dayDatesOfSemester = [];
      var semesterFound = this.$store.state.calendarButez2.find(
        (item) => item.session == semester
      );
      if (semesterFound) {
        for (const mm of semesterFound.months) {
          for (const dd of mm.days) {
            dd.month = mm.name;
            this.dayDatesOfSemester.push(dd);
          }
        }
      }
      // console.log(this.dayDatesOfSemester, "xxxxxx");
    },
    _printClass(classGroup) {
      classGroup.classGroupRef.get().then((doc) => {
        var cal = doc.data();
        console.log(cal.name);
      });
    },

    _getCalendarDataOfSelectedLesson(lesson, dayIndex, weekNumber) {
      var list = [];
      if (lesson) {
        if (lesson.calendarData) {
          for (const cc of lesson.calendarData) {
            if (cc.dayIndex == dayIndex) {
              cc.weekNumber = weekNumber;
              list.push(cc);
            }
          }
        }
        // list.sort((a, b) => a.xeelj - b.xeelj);
      }
      return list;
    },
    _getCalendarData(lesson) {
      if (lesson.calendarData && this.allWeeks) {
        lesson.numberOfMustAttendances =
          lesson.calendarData.length * this.allWeeks.length;
      }
      return lesson.calendarData;
    },
    _hasCalendarData(lesson) {
      if (lesson._calDatas && lesson._calDatas.length) return true;
      return false;
    },
    _print(item) {
      console.log(item.ref.path);
    },

    isAllowedLesson(lesson) {
      if (lesson && lesson.classGroupRefs) {
        for (var ll of lesson.classGroupRefs) {
          if (ll.path.includes("/departments/")) {
            lesson.isNotAllowed = true;
            break;
          }
        }
      }
    },
    _getDay(dayIndex) {
      var dayName = null;
      switch (dayIndex) {
        case 1:
          dayName = "Да";
          break;
        case 2:
          dayName = "Мя";
          break;
        case 3:
          dayName = "Лх";
          break;
        case 4:
          dayName = "Пү";
          break;
        case 5:
          dayName = "Ба";
          break;
        case 6:
          dayName = "Бя";
          break;
        case 7:
          dayName = "Ня";
          break;
      }
      return dayName;
    },
    // _getGroupName(cal) {
    //   if (cal.lessonGroup && cal.lessonGroup.groupIndex) {
    //     return (
    //       " (Г" +
    //       (typeof cal.lessonGroup.groupIndex == "number"
    //         ? cal.lessonGroup.groupIndex
    //         : cal.lessonGroup.groupIndex.groupIndex) +
    //       ")"
    //     );
    //   } else {
    //     console.log(cal);
    //     return "";
    //   }
    // },
    // _getCalendarDataAsString(item) {
    //   var str = null;
    //   str =
    //     str == null
    //       ? this._getDay(item.dayIndex) +
    //         "-" +
    //         item.xeelj +
    //         this._getGroupName(item)
    //       : str +
    //         ", " +
    //         (this._getDay(item.dayIndex) + "-" + item.xeelj) +
    //         this._getGroupName(item);
    //   return str;
    // },
    formatDate2(val) {
      if (!val) {
        return "-";
      }
      let date = val.toDate();
      return moment(date).format("YYYY-MM-DD");
    },
    formatDate3() {
      return moment(new Date()).format("YYYY-MM-DD");
    },
  },
};
</script>
<style>
[data-title2]:hover:after {
  opacity: 1;
  transition: all 0.1s ease 0.5s;
  visibility: visible;
}

[data-title2]:after {
  content: attr(data-title2);
  position: absolute;
  bottom: -1.6em;
  left: 65%;
  padding: 4px 4px 4px 8px;
  color: #222;
  white-space: nowrap;
  -moz-border-radius: 5px;
  -webkit-border-radius: 5px;
  border-radius: 5px;
  -moz-box-shadow: 0px 0px 4px #222;
  -webkit-box-shadow: 0px 0px 4px #222;
  box-shadow: 0px 0px 4px #222;
  background: yellow;
  color: black;
  opacity: 0;
  z-index: 99999;
  visibility: hidden;
}

[data-title2] {
  position: relative;
}

.bordered-table {
  border-collapse: collapse;
  width: 100%;
}

.bordered-table th {
  font-size: 10pt;
}

.bordered-table td {
  border: 1px solid #ddd;
  /* Border color */
  padding: 4px;
  text-align: left;
}

.bordered-table th {
  border: 1px solid #ddd;
  background-color: white;
  color: black;
  padding: 4px;
}

.right-dialog .v-dialog__content {
  left: auto !important;
  right: 0 !important;
}

/* .active-tab {
  background-color: #a8d6fa;
  color: #ffffff;
} */
.active-tab {
  background-color: #ffc107;
  color: #ffffff;
}
.calplusborder {
  border-color: #000 !important;
  border-style: dashed !important;
}
.hover-dashed:hover {
  border: 1px dashed red;
  background: yellow;
}
</style>
